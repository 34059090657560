import { sortByTitle } from "../utils/sorting";
import { transformPath } from "../utils/transformField";
import httpService from "./http.service";

const endpoints = {
  main: "routes",
  age: "age-categories",
  seasons: "seasons",
  audience: "target-audiences",
  types: "types",
  origin: "origin",
  naturals: "natural-environments",
  longestDistance: "longest-distance",
  longestDuration: "longest",
  "tag-project": "tag-project",
};

export const filterService = {
  async getAges() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.age)
    );
    return data.sort(sortByTitle);
  },
  async getSeasons() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.seasons)
    );
    return data;
  },
  async getAudience() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.audience)
    );
    return data;
  },
  async getTypes() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.types)
    );
    return data;
  },
  async getNaturals() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.naturals)
    );
    return data;
  },

  async getOrigin() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.origin)
    );
    return data;
  },
  async getTags() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints["tag-project"])
    );
    return data;
  },
  async getLongestDistance() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.longestDistance)
    );
    return data?.length;
  },
  async getLongestDuration() {
    const { data } = await httpService.get(
      transformPath(endpoints.main, endpoints.longestDuration)
    );
    return data?.duration;
  },
};
