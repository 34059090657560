import React from "react";
import { Badge } from "../ui";
import { useSelector } from "react-redux";
import { getWayById } from "../../store/way";
import image from "../../images/popularBg.png";
import {
  tarnsformTypeWay,
  transformDistance,
  transformLocation,
  transformOrganization,
} from "../../utils/transformField";
import { transformDuration } from "../../utils/transformDuration";
import { NavLink } from "react-router-dom";
import { getFieldByTypeAndId } from "../../store/filter";
import {
  useGetRoutesRegionsByIdQuery,
  useGetRoutesTypesByIdQuery,
  useGetRoutesOriginByIdQuery,
} from "../../store/RTKApi/routes";
import { org } from "../../images";
import { Tooltip } from "@mui/material";

export const WaysItemBlock = ({ id, way }) => {
  const { data: region } = useGetRoutesRegionsByIdQuery(way?.region);
  const { data: type } = useGetRoutesTypesByIdQuery(way?.type);
  const { data: origin } = useGetRoutesOriginByIdQuery(way?.origin);

  return (
    <NavLink
      to={`/ways/${id}`}
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.8)), url(${way.poster})`,
      }}
      className=" block min-w-[300px] md:w-1/3 bg-no-repeat bg-cover  backdrop-brightness-50 h-[300px] md:h-[300px] lg:h-[300px]  border-t-2 p-5 "
    >
      <div className="flex flex-col gap-7  justify-between h-full">
        <div className="flex gap-2   flex-wrap">
          <Badge
            styles={"  border-0 h-[30px]  bg-[#EBEBEB]"}
            textstyle={" text-black text-[12px]"}
            label={origin?.title}
            img={<img className="w-full h-full" src={org} alt="logos" />}
            tooltip={true}
          />

          <Badge
            styles={" h-[30px] border-[#C4C4C4] bg-white"}
            textstyle={" text-text-secondary-green"}
            label={region?.title}
          />
        </div>
        <div className="flex items-end flex-grow">
          {" "}
          <h3 className=" leading-6   text-[18px] font-medium  text-white font-rubik">
            {way.title}
          </h3>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-2  flex-wrap">
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDuration(way.duration)}
              textstyle={" text-white"}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDistance(way.length)}
              textstyle={" text-white"}
            />
            <Badge
              styles={` h-[30px] border-0`}
              label={type?.title}
              type={type?.id}
              textstyle={" text-white"}
            />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
