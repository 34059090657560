import { createSelector } from "@reduxjs/toolkit";

export const getAllNotes = () =>
  createSelector(
    (state) => state.notes.entities,
    (state) => state
  );

export const isLoadingNotes = () => (state) => state.notes.isLoading;

export const getNoteById = (id) => (state) =>
  state.notes.entities.find((note) => note.id === +id);
