import React, { useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { MultimediaSlider } from "./multimediaSlider";
import {
  useLazyGetContentQuery,
  useLazyGetVideoQuery,
} from "../../store/RTKApi/medias";
import { Container } from "../container";

export function MultimediaPanel({ title, type }) {
  const [imageTrigger, { data: imageData, isSuccess: imageSuccess }] =
    useLazyGetContentQuery({});
  const [videoTrigger, { data: videoData, isSuccess: videoSuccess }] =
    useLazyGetVideoQuery({});

  useEffect(() => {
    if (type === "img") {
      imageTrigger({});
    } else {
      videoTrigger({});
    }
  }, []);

  const data = type === "img" ? imageData : videoData;
  const isSuccess = type === "img" ? imageSuccess : videoSuccess;

  return (
    <>
      {isSuccess && data.length > 0 && (
        <section className="notes  ">
          <Container>
            <div className=" ">
              <div className=" flex whitespace-nowrap items-center">
                <span className="text-text-primary text-2xl  font-medium font-rubik ">
                  {title}
                </span>
                <div className="hidden  md:flex items-center ml-2 pt-1">
                  <button className="swiper-multimedia-button-prev">
                    <ChevronLeftIcon className=" h-6 w-6" />
                  </button>
                  <button className="swiper-multimedia-button-next rotate-180">
                    <ChevronLeftIcon className=" h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </Container>
          {<MultimediaSlider data={data} type={type} />}
        </section>
      )}
    </>
  );
}
