import React, { useState } from "react";
import { transformColor } from "../../utils/transformField";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tooltip } from "@mui/material";

export const Badge = ({
  label,
  styles,
  textstyle,
  color,
  type,
  onHover,
  isTitle,
  img,
  tooltip,
  onClick = () => {},
}) => {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //     setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //     setIsHovered(false);
  // };

  return (
    <SkeletonTheme baseColor="#2fac662e" highlightColor="#fffffff14">
      <Tooltip title={tooltip && label}>
        <div
          style={{
            backgroundColor: transformColor(type),

            transition: "max-width 0.3s ease-in-out",
          }}
          className={`rounded-[30px] border py-[2px] px-[10px] flex justify-center items-center max-w-[80%] md:max-w-[150px] xl:max-w-[350px] ${styles}`}
          onClick={onClick}
        >
          <span
            className={`text-center leading-[20px] font-normal  text-[16px] ${
              isTitle ? "truncate-none" : "truncate"
            } h-5 text-text-primary font-rubik first-letter:uppercase ${textstyle}`}
            style={{
              whiteSpace: isTitle ? "normal" : "nowrap",
              overflow: isTitle ? "visible" : "hidden",
            }}
          >
            {(img ? img : label) || <Skeleton width={50} />}
          </span>
        </div>
      </Tooltip>
    </SkeletonTheme>
  );
};
