const optionsYears = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export function numberToText(n) {
  if (n < 1 || n > 100) {
    return "Число вне диапазона 1-100";
  }

  if (n === 100) {
    return "сотый день";
  }

  const firstTwenty = [
    "",
    "первый",
    "второй",
    "третий",
    "четвёртый",
    "пятый",
    "шестой",
    "седьмой",
    "восьмой",
    "девятый",
    "десятый",
    "одиннадцатый",
    "двенадцатый",
    "тринадцатый",
    "четырнадцатый",
    "пятнадцатый",
    "шестнадцатый",
    "семнадцатый",
    "восемнадцатый",
    "девятнадцатый",
  ];

  const tens = [
    "",
    "",
    "двадцать",
    "тридцать",
    "сорок",
    "пятьдесят",
    "шестьдесят",
    "семьдесят",
    "восемьдесят",
    "девяносто",
  ];

  if (n <= 19) {
    return firstTwenty[n] + " день";
  }

  const tensPart = Math.floor(n / 10);
  const unitsPart = n % 10;

  let result = tens[tensPart];

  if (unitsPart > 0) {
    result += " " + firstTwenty[unitsPart];
  }

  return result + " день";
}

export const getDayLabel = (days) => {
  if (days === 1) {
    return " день";
  } else if (days >= 2 && days <= 4) {
    return " дня";
  } else if (days >= 5 && days <= 20) {
    return " дней";
  } else {
    const lastDigit = days % 10;
    if (lastDigit === 1) {
      return " день";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return " дня";
    } else {
      return " дней";
    }
  }
};

export const transformDate = (date) => {
  const dateObj = { day: 0, hour: 0, minute: 0 };

  if (date >= 1440) {
    dateObj.day =
      Math.floor(date / 1440) + getDayLabel(Math.floor(date / 1440));
    date -= dateObj.day * 1440;
  }

  if (date >= 60) {
    dateObj.hour = Math.floor(date / 60);
    date -= dateObj.hour * 60;
  }

  dateObj.minute = date;

  return { day: getDayLabel(dateObj.day) };
};

export function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return date.toLocaleDateString("ru-RU", options);
}

export function formatDateDifferent(date, isFirst) {
  let options = { day: "numeric", month: "long" };

  // Добавляем год, если isFirst не true
  if (!isFirst) {
    options = { ...options, year: "numeric" };
  }
  return new Date(date).toLocaleDateString("ru-RU", options);
}

export function getDate(arg) {
  const date = new Date(arg);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}
