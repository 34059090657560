// import api from "../../fakeApi";
import { localStorageService } from "../../service/localstorage.service";

import {
  favoritesAdded,
  favoritesRemoved,
  wayRequested,
  wayReсieved,
} from "./way.slice";

export const fetchWays = () => async (dispatch) => {
  dispatch(wayRequested());
  try {
    // const data = await api.ways.fetchAll();
    // dispatch(wayReсieved(data));
  } catch (error) {}
};

export const addFavorite = (id) => (dispatch) => {
  localStorageService.addFavorite(id);
  dispatch(favoritesAdded(id));
};

export const removeFavorite = (id) => (dispatch) => {
  localStorageService.removeFavorite(id);
  dispatch(favoritesRemoved(id));
};
