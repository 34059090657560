import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  getWayById,
  isFavorite,
  removeFavorite,
} from "../../store/way";
import {
  pdfIcon,
  routeLogo,
  BookmarkOn,
  BookmarkOff,
  BookmarkWhite,
} from "../../images";
import { Badge, CustomButton, Tags } from "../ui";
import * as transform from "../../utils/transformField";
import { Bookmark } from "../bookmark";
import { transformDuration } from "../../utils/transformDuration";
import {
  useGetRoutesRegionsByIdQuery,
  useGetRoutesTypesByIdQuery,
  useGetRoutesOriginByIdQuery,
} from "../../store/RTKApi/routes";
import { useResize } from "../../hooks/useResize";

import {
  getFilterRegionAction,
  testFilterRequest,
} from "../../store/filter/actions";

export const WaysListItem = ({ way }) => {
  const { isScreenMd } = useResize();
  const navigate = useNavigate();
  const { data: region } = useGetRoutesRegionsByIdQuery(way?.region);
  const { data: type } = useGetRoutesTypesByIdQuery(way?.type);
  const { data: origin } = useGetRoutesOriginByIdQuery(way?.origin);

  const isAuthorized = useSelector((state) => state.auth.auth);

  const dispatch = useDispatch();

  const favorite = useSelector(isFavorite(way.id));
  const handleClick = (id) => {
    !favorite ? dispatch(addFavorite(id)) : dispatch(removeFavorite(id));
  };
  const handleNavigate = (filter) => {
    if (filter?.name === "region") {
      dispatch(getFilterRegionAction(filter?.value));
    } else {
      dispatch(testFilterRequest(filter));
    }
    navigate("/ways");
  };
  return (
    <>
      {isScreenMd ? (
        <div className=" flex flex-col  md:flex-row  justify-center border-t-2 py-4  border-dashed border-[#C4C4C4] md:justify-between  md:items-start  group w-full gap-4 md:gap-10 ">
          <NavLink to={`/ways/${way.id}`}>
            <div className=" flex h-full flex-col gap-[5px]   md:max-w-[150px] md:h-[150px]">
              <img
                src={way.poster}
                alt="Постер"
                className=" min-w-[150px] h-full object-cover"
              />
            </div>
          </NavLink>
          <div className="  flex flex-col justify-between   w-full h-full px-4  gap-2">
            <div className="flex flex-col md:flex-row  md:justify-between items-start gap-2 justify-center">
              <div className=" w-full flex justify-center md:justify-normal">
                <NavLink to={`/ways/${way.id}`}>
                  <h3 className=" leading-[28px] font-normal text-[20px]  text-[#151515] font-rubik group-hover:underline group-hover:text-[#2FAC66]">
                    {way?.title}
                  </h3>
                </NavLink>
              </div>
              <div className="flex gap-2 items-center justify-between md:justify-end w-full">
                {isAuthorized ? (
                  <CustomButton
                    styles={
                      "bg-text-primary-green hover:bg-text-secondary-green px-4  py-1 h-auto h-auto w-auto whitespace-nowrap"
                    }
                    onClick={() =>
                      navigate(`/account/events/create`, {
                        state: { id: way?.id },
                      })
                    }
                  >
                    Выбрать маршрут
                  </CustomButton>
                ) : null}
                <Badge
                  styles={" h-[30px] border-0 bg-[#EBEBEB]"}
                  textstyle={" text-black text-[12px]"}
                  label={origin?.title}
                  tooltip={true}
                />
                <Bookmark
                  icon={!favorite ? BookmarkOff : BookmarkOn}
                  onClick={() => {
                    handleClick(way.id);
                  }}
                />
              </div>
            </div>
            <div className=" hidden   w-full min-h-[80px] md:flex md:flex-col justify-between gap-2">
              <span className=" text-[14px] text-text-primary-green text-right flex justify-end flex-wrap gap-2">
                {way.tags.map((tagId, index) => (
                  <Tags id={tagId} key={index} />
                ))}
              </span>
              <span
                dangerouslySetInnerHTML={{ __html: way.short_description }}
                className=" text-[14px] text-text-primary  indent-2"
              ></span>
            </div>
            <div className="flex flex-row md:flex-row md:justify-between md:items-end gap-2">
              <div className="flex gap-2   flex-wrap  md:justify-start lg:flex-nowrap w-full ">
                <Badge
                  styles={
                    " h-[30px] border-[#C4C4C4] bg-white cursor-pointer hover:shadow-md duration-300  "
                  }
                  textstyle={" text-text-secondary-green"}
                  label={region?.title}
                  onClick={() =>
                    handleNavigate({ name: "region", value: way?.region })
                  }
                />
                <Badge
                  styles={" h-[30px]  border-[#55BFFA]"}
                  label={transformDuration(way?.duration)}
                  textstyle={" text-[#55BFFA]"}
                />
                <Badge
                  styles={" h-[30px]  border-[#55BFFA]"}
                  label={transform.transformDistance(way?.length)}
                  textstyle={" text-[#55BFFA]"}
                />
                <Badge
                  styles={
                    " h-[30px] bg-[#6CAB4E] border-0 cursor-pointer hover:shadow-md duration-300 "
                  }
                  label={type?.title}
                  type={type?.id}
                  textstyle={" text-white"}
                  onClick={() =>
                    handleNavigate({ name: "type", value: type?.id.toString() })
                  }
                />
              </div>
              <div className="  flex justify-end items-end">
                <div className="flex gap-2 items-center justify-center">
                  <img src={pdfIcon} alt="Скачать PDF" />
                  <Link
                    to={way?.file}
                    target="_blank"
                    rel="noreferrer"
                    className=" leading-[21px] font-normal text-[14px]  text-text-primary font-rubik hover:underline"
                  >
                    Скачать
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" flex flex-col  md:flex-row  justify-center border-t-2 py-4  border-dashed border-[#C4C4C4] md:justify-between  md:items-start  group w-full gap-4 md:gap-10 ">
          <div className="flex flex-col">
            <NavLink
              to={`/ways/${way.id}`}
              className=" leading-[24px] font-normal text-[18px]  text-[#151515] font-rubik group-hover:underline group-hover:text-[#2FAC66]"
            >
              {way?.title}
            </NavLink>
            <div className="flex gap-2 mt-3 flex-wrap  items-center justify-start md:justify-end w-full">
              <>
                <Bookmark
                  icon={!favorite ? BookmarkOff : BookmarkOn}
                  onClick={() => {
                    handleClick(way.id);
                  }}
                />
                <Badge
                  styles={" h-[24px] border-0 bg-[#EBEBEB]"}
                  textstyle={" text-black text-[10px]"}
                  label={origin?.title}
                />
              </>

              <div className="flex gap-2 items-center justify-center">
                <img src={pdfIcon} alt="Скачать PDF" />
                <Link
                  to={way?.file}
                  target="_blank"
                  rel="noreferrer"
                  className=" leading-[21px] font-normal text-[14px]  text-text-primary font-rubik hover:underline"
                >
                  Скачать
                </Link>
              </div>
            </div>
            <div className="flex mt-5 gap-4">
              <Link to={`/ways/${way.id}`}>
                <div className=" inline-block w-28 h-20 ">
                  <img
                    src={way.poster}
                    className=" w-full h-full object-cover "
                    alt="Постер"
                  />
                </div>
              </Link>

              <div className="flex gap-2   flex-wrap  md:justify-start lg:flex-nowrap w-full">
                <Badge
                  styles={" h-[30px] border-[#C4C4C4] bg-white"}
                  textstyle={" text-text-secondary-green"}
                  label={region?.title}
                  onClick={() =>
                    handleNavigate({ name: "region", value: way?.region })
                  }
                />
                <Badge
                  styles={" h-[30px]  border-[#55BFFA]"}
                  label={transformDuration(way?.duration)}
                  textstyle={" text-[#55BFFA]"}
                />
                <Badge
                  styles={" h-[30px]  border-[#55BFFA]"}
                  label={transform.transformDistance(way?.length)}
                  textstyle={" text-[#55BFFA]"}
                />
                <Badge
                  styles={" h-[30px] bg-[#6CAB4E] border-0"}
                  label={type?.title}
                  type={type?.id}
                  textstyle={" text-white"}
                  onClick={() =>
                    handleNavigate({ name: "type", value: type?.id.toString() })
                  }
                />
              </div>
            </div>
          </div>
          {isAuthorized ? (
            <CustomButton
              styles={
                " bg-text-primary-green hover:bg-text-secondary-green px-4 py-2 h-auto w-auto whitespace-nowrap "
              }
              onClick={() =>
                navigate(`/account/events/create`, { state: { id: way?.id } })
              }
            >
              Выбрать маршрут
            </CustomButton>
          ) : null}
        </div>
      )}
    </>
  );
};

export default WaysListItem;
