import React from "react";
import "swiper/css/effect-fade";
import "swiper/scss/pagination";
import { useOutletContext } from "react-router-dom";

export const Source = () => {
  const { sources } = useOutletContext();
  return (
    <section className="source min-h-[40vh] mb-5 px-5">
      <ol className="list-decimal">
        {sources.map((s) => (
          <li key={s}>
            {s.includes("http") ? (
              <a target="_blank" className="text-green-600" href={s}>
                {s}
              </a>
            ) : (
              <span>{s}</span>
            )}
          </li>
        ))}
      </ol>
    </section>
  );
};
