export const filterTypes = {
  season: "Сезоны",
  target_audience: "Целевая аудитория",
  //06.12 Правки
  // tags: "Городские проекты",
  origin: "Организация",
  //06.12 Правки
  // age_category: "Возраст",
  duration: "Продолжительность",
  type: "Вид маршрута",
  length: "Протяженность",
  natural_environment: "Природная среда",
  city_environment: "Городская среда",
};

export const notesFilterTypes = {
  season: "Сезоны",
  age_category: "Возраст",
  type: "Вид маршрута",
};
