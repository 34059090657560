import React from "react";
import {useGetRoutesTagByIdQuery} from "../../store/RTKApi/routes";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {testFilterRequest} from "../../store/filter";
import {useDispatch} from "react-redux";

export const Tags = ({id}) => {
    const dispatch = useDispatch();
    const {data, isSuccess} = useGetRoutesTagByIdQuery(id);

    const handleChange = () => {
        if (data?.project) {
            dispatch(testFilterRequest({name: 'tags', value: data.project}));
        }
    };
    return (
        <>
            {isSuccess ? (
                <span onClick={handleChange}
                      className=" mx-1">{data?.title}</span>
            ) : (
                <Skeleton width={50}/>
            )}
        </>
    );
};
