import { createSelector } from "@reduxjs/toolkit";
import { notesFilterTypes } from "./types";

export const getAllWaysFilters = () => (state) => ({
  ...state.filter.filterData,
  natural_environment: state.filter.filterData?.natural_environment?.filter(
    (el) => el.is_natural_environment === true
  ),
  city_environment: state.filter.filterData?.natural_environment?.filter(
    (el) => el.is_natural_environment === false
  ),
});

export const getAllNotesFilters = () =>
  createSelector(
    (state) => state.filter.filterData,
    (state) =>
      Object.keys(notesFilterTypes).reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {})
  );

export const getFilterIsLoading = () => (state) => state.filter.isLoading;

export const getCurrentWaysFilter = (name) => (state) =>
  state.filter.changedWaysFilter[name];

export const getWaysFilter = () => (state) => state.filter.currentWaysFilter;

export const getFieldByTypeAndId = (data) => (state) =>
  state.filter.filterData[data.type].find((f) => f.id === data.id);

export const getFilterValuesByNameAndArrayIds = (name, array) => (state) =>
  state.filter.filterData[name]
    ?.filter((f) => (Array.isArray(array) ? array?.includes(f.id) : null))
    .map((item) => item.title)
    .join(", ");

export const getRangeValueByName = (name) => (state) =>
  state.filter.filterData[name].value;

export const getQueryStringParams = (name) => (state) =>
  state.filter.queryStringParams[name];
