import {createSlice} from "@reduxjs/toolkit";
import {longestDistanceFilterTransform} from "../../utils/longestDistanceFilterTransform";
import {transformDuration} from "../../utils/transformDuration";
import {transformDistance} from "../../utils/transformField";

const initialState = {
    changedWaysFilter: {},
    queryStringParams: {},
    queryOutDoorParams: {},
    regionsData: [
        {
            id: "",
            title: "Все города",
            distance_to_moscow: 0,
        },
    ],
    regionPick: [],
    categories: [],
    searchString: "",
    filterData: {
        duration: {
            label: "Продолжительность",
            slider: true,
            options: [
                {value: 1, label: "Менее часа"},
                {value: 1 * 60 * 60, label: "1 час"},
                {value: 2 * 60 * 60, label: "2 часа"},
                {value: 3 * 60 * 60, label: "3 часа"},
                {value: 5 * 60 * 60, label: "5 часов"},
                {value: 12 * 60 * 60, label: "12 часов"},
                {value: 24 * 60 * 60, label: "1 день"},
                {value: 2 * 24 * 60 * 60, label: "2 дня"},
                {value: 5 * 24 * 60 * 60, label: "5 дней"},
                {value: 7 * 24 * 60 * 60, label: "1 неделя"},
                {value: 2 * 7 * 24 * 60 * 60, label: "2 недели"},
                {value: 3 * 7 * 24 * 60 * 60, label: "3 недели"},
                {value: 31 * 24 * 60 * 60, label: "1 месяц"},
                {value: 2 * 31 * 24 * 60 * 60, label: "2 месяца"},
                {value: 6 * 31 * 24 * 60 * 60, label: "6 месяцев"},
                {value: 365 * 24 * 60 * 60, label: "1 год"},
            ],
            value: [1, 365 * 24 * 60 * 60],
        },
        length: {
            label: "Длина маршрута",
            slider: true,
            options: [
                {value: 1, label: "1 км"},
                {value: 2, label: "2 км"},
                {value: 3, label: "3 км"},
                {value: 5, label: "5 км"},
                {value: 10, label: "10 км"},
                {value: 50, label: "50 км"},
                {value: 100, label: "100 км"},
                {value: 200, label: "200 км"},
                {value: 500, label: "500 км"},
                {value: 1000, label: "1000 км"},
            ],
            value: [1, 1000],
        },
    },
    isLoading: true,
};

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        filterReqested: (state) => {
            state.isLoading = true;
        },
        filterRecieved: (state, action) => {
            const length = longestDistanceFilterTransform(
                initialState.filterData.length,
                action.payload.longest_distance,
                transformDistance
            );
            const duration = longestDistanceFilterTransform(
                initialState.filterData.duration,
                action.payload.longest_duration,
                transformDuration
            );
            state.filterData = {length, duration, ...action.payload};
            state.isLoading = false;
        },
        // тут
        filterRequest: (state, action) => {
            const {name, value} = action.payload;
            if (name === "length") {
                state.queryStringParams["length_min"] = [value[0]];
                state.queryStringParams["length_max"] = [value[1]];
            } else if (name === "duration") {
                state.queryStringParams["duration_min"] = [value[0]];
                state.queryStringParams["duration_max"] = [value[1]];
            } else {
                if (!state.queryStringParams[name]) {
                    state.queryStringParams[name] = [value];
                    return;
                } else {
                    if (state.queryStringParams[name].includes(value)) {
                        state.queryStringParams[name] = state.queryStringParams[
                            name
                            ].filter((el) => el !== value);
                        // if (state.queryStringParams[name].length === 0) state.queryStringParams = {}
                    } else {
                        state.queryStringParams[name].push(value);
                        return;
                    }
                }
            }
        },
        filterOutDoor: (state, action) => {
            const {name, value} = action.payload;
            if (!state.queryOutDoorParams[name]) {
                state.queryOutDoorParams[name] = [value];
                return;
            } else {
                if (state.queryOutDoorParams[name].includes(value)) {
                    state.queryOutDoorParams[name] = state.queryOutDoorParams[
                        name
                        ].filter((el) => el !== value);
                    // if (state.queryStringParams[name].length === 0) state.queryStringParams = {}
                } else {
                    state.queryOutDoorParams[name].push(value);
                    return;
                }
            }
        },
        rangeFilterChange: (state, action) => {
            state.filterData[action.payload.name].value = action.payload.value;
        },
        filterRegionAction: (state, action) => {
            state.regionPick = action.payload;
        },
        filterSearchAction: (state, action) => {
            state.searchString = action.payload;
        },
        filterCategories: (state, action) => {
            state.categories = action.payload;
        },

        getRegionsData: (state, action) => {
            if (action.payload)
                state.regionsData = [...state.regionsData, ...action.payload];
        },
        changedWaysFilter: (state, action) => {
            let {name, value} = action.payload;

            if (name === "duration") {
                state.changedWaysFilter[name] = value;
                return;
            }
            value = Number(value);
            if (!state.changedWaysFilter[name]) {
                state.changedWaysFilter[name] = [value];
                return;
            }
            if (state.changedWaysFilter[name].length > 0) {
                if (state.changedWaysFilter[name].includes(value)) {
                    state.changedWaysFilter[name] = state.changedWaysFilter[name].filter(
                        (el) => el !== value
                    );
                    if (state.changedWaysFilter[name].length === 0) {
                        delete state.changedWaysFilter[name];
                    }
                    return;
                }
                state.changedWaysFilter[name].push(value);
            }
        },
        clearedWaysFilter: (state) => {
            state.queryOutDoorParams = {};
            state.queryStringParams = {};
            state.filterData["length"].value = [
                initialState.filterData["length"].value[0],
                state.filterData["longest_distance"],
            ];
            state.filterData["duration"].value = [
                initialState.filterData["duration"].value[0],
                state.filterData["longest_duration"],
            ];
        },
    },
});

const {actions, reducer: filterReducer} = filterSlice;

export const {
    changedWaysFilter,
    clearedWaysFilter,
    filterRecieved,
    filterReqested,
    filterRequest,
    getRegionsData,
    filterRegionAction,
    filterSearchAction,
    rangeFilterChange,
    filterCategories,
    filterOutDoor,
} = actions;

export default filterReducer;
