import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "./navigation";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import {
  mcvpWhite,
  moscowEd,
  moscowStudyLogo,
  mvcp,
  travelSchoolLogo,
  travelSchoolLogoWhite,
} from "../images";
import { routes } from "../routes";
import { Burger } from "./ui";
import Login from "./auth/login";
import { useClickAway } from "@uidotdev/usehooks";
import { useResize } from "../hooks/useResize";
import { useSelector } from "react-redux";
import { useLogin } from "../hooks/useLogin";

export const Header = React.memo(() => {
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);
  const { isOpen: isLoginForm, setIsOpen: setLoginForm } = useLogin();
  const auth = useSelector((state) => state.auth.auth);
  const { pathname } = useLocation();

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(handleScroll, 300));
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!open) setLoginForm(false);
  }, [open]);

  const { isScreenLg } = useResize();

  const classes = " rounded-[5px] md:translate-y-4 md:container ";

  return (
    <header
      style={{ boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)" }}
      className={
        (scroll < 50 && pathname === "/" && isScreenLg ? classes : "") +
        "   fixed flex justify-center  z-20 w-screen bg-[#168743] min-h-[80px]  md: md:left-1/2 md:-translate-x-1/2"
      }
      onMouseLeave={() => setLoginForm(false)}
    >
      <div
        className=" relative flex justify-between md:justify-center lg:justify-between items-center  flex-nowrap
container
 w-full  py-3 "
      >
        <div
          className={
            "flex justify-start flex-grow items-center pl-4 md:hidden lg:flex"
          }
        >
          <Link
            className="w-24 md:w-1/2 lg:max-w-[105px] xl:max-w-[175px]"
            to="/"
          >
            <img
              src={travelSchoolLogoWhite}
              alt="Школа Москва"
              height={40}
            ></img>
          </Link>
          <a
            href={"https://shkolamoskva.ru/"}
            target={"_blank"}
            className="w-20 md:w-1/2 lg:max-w-[50px] xl:max-w-[75px]"
          >
            <img src={moscowEd} alt="Московское образование"></img>
          </a>
          <a
            href={"https://mcvp.ru/"}
            target={"_blank"}
            className="w-24 md:w-1/2 lg:max-w-[105px] xl:max-w-[175px]"
          >
            <img src={mcvpWhite} alt="МЦВП" />
          </a>
        </div>
        {!open && <Burger open={open} setOpen={setOpen} />}
        <Navigation
          routes={routes}
          onLogin={setLoginForm}
          open={open}
          setOpen={setOpen}
          styles={" hidden"}
          setLoginForm={setLoginForm}
          header={true}
        />
        <Login />
      </div>
    </header>
  );
});
