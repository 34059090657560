// Данный класс используется в RTKApi folder
class Rtk_utils {
    // Проверка строки на пустые параметры и проверка value на массив
    static ClearQueryString(args) {
        let queryString = '?'
        for (const [key, value] of Object.entries(args)) {
            if (args[key] !== '' && args[key] !== undefined) {
                if (Array.isArray(value)) {
                    value.map(v => {
                        return (
                            queryString += `${key}=${v}&`
                        )
                    })
                } else {
                    queryString += `${key}=${value}&`
                }
            }
        }
        return queryString.slice(0, -1)
    }

    static DeleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

}

export default Rtk_utils