import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { getQueryStringParams, testFilterRequest } from "../../store/filter";
import { RangeFilter, CustomCheckbox } from "../ui";
import {} from "../../store/filter";

export const WaysFilterItem = ({
  name,
  options,
  label,
  isMaps,
  isOpen,
  handleOpen,
  index,
}) => {
  const currentFilter = useSelector(
    getQueryStringParams(
      name === "city_environment" ? "natural_environment" : name
    )
  );

  const dispatch = useDispatch();
  const handleChange = (target) => {
    dispatch(testFilterRequest(target));
  };
  return (
    <>
      <div className="filter font-rubik relative ">
        <div
          className=" flex uppercase  items-center gap-2 cursor-pointer pb-4"
          onClick={() => handleOpen(index)}
        >
          <h2 className=" text=[14px] font-semibold leading-6  text-text-secondary-green whitespace-nowrap">
            {label}
          </h2>
          <ChevronDownIcon
            className={
              " w-6 h-6 min-w-[24px] transition-transform " +
              (isOpen ? "rotate-180" : "")
            }
          />
        </div>
        {isOpen && (
          <ul
            className={
              "  mb-7 list-none flex flex-col justify-start gap-2 z-10 w-full " +
              (isMaps
                ? " absolute bg-white p-4 rounded-b-md  shadow-md w-max min-w-full"
                : "")
            }
          >
            {name === "duration" || name === "length" ? (
              <RangeFilter
                name={name}
                minim={0}
                maxim={1000}
                onChange={handleChange}
                options={options?.options}
              />
            ) : name !== "natural_environment" &&
              name !== "city_environment" ? (
              options &&
              options.map((option) => {
                return (
                  <CustomCheckbox
                    key={option.id}
                    name={name}
                    value={option.id}
                    checked={
                      currentFilter?.includes(option.id.toString()) || false
                    }
                    onChange={handleChange}
                    label={option.title}
                  />
                );
              })
            ) : (
              options &&
              options.map((option) => {
                return (
                  <CustomCheckbox
                    key={option.id}
                    name={
                      name === "city_environment" ? "natural_environment" : name
                    }
                    value={option.id}
                    checked={
                      currentFilter?.includes(option.id.toString()) || false
                    }
                    onChange={handleChange}
                    label={option.difficulty}
                  />
                );
              })
            )}
          </ul>
        )}
      </div>
    </>
  );
};
