import { createSlice } from "@reduxjs/toolkit";

const notesSlice = createSlice({
  name: "notes",
  initialState: {
    isLoading: true,
    entities: [],
  },

  reducers: {
    notesRequested: (state) => {
      state.isLoading = true;
    },
    notesReсieved: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
  },
});

const { actions, reducer: notesReducer } = notesSlice;

export const { notesRequested, notesReсieved } =
  actions;

export default notesReducer;
