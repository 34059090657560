import React, { useState } from "react";
import { Field } from "./Field";
import styles from "./LoginForm.module.scss";

// import {
//   handleAuthError,
//   setAccessToken,
//   setRefreshToken,
//   useGetTokenMutation,
// } from "@entities/auth";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton, InputField } from "@components/ui";
import {
  useLoginUserMutation,
  useResetPasswordMutation,
} from "store/RTKApi/auth";
import {
  handleAuthError,
  setAccessToken,
  setRefreshToken,
} from "@entities/auth";
import { authCheck } from "store/auth/auth.slice";
import { useDispatch } from "react-redux";

export const LoginForm = ({ isReset }) => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // const [getTokenTrigger] = useGetTokenMutation({});
  const [triggerLogin] = useLoginUserMutation({});
  const [triggerReset] = useResetPasswordMutation({});

  const handleChange = (target) => {
    setFormData((prev) => ({ ...prev, [target.name]: target.value.trim() }));
  };

  const dispatch = useDispatch();

  const uidb = params.get("uidb64"),
    token = params.get("token");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isReset && (!uidb || !token)) {
      toast.error("Неверный uibd64 или token", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    isReset
      ? triggerReset({
          uidb,
          token,
          password: formData.password,
        })
          .unwrap()
          .then((res) => {
            toast.success("Вход выполнен", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });

            navigate("/login");
          })
          .catch((e) => {
            toast.error("Ошибка восстановления пароля", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          })
      : triggerLogin(formData)
          .unwrap()
          .then((res) => {
            setAccessToken(res.access);
            setRefreshToken(res.refresh);
            dispatch(authCheck(res.access));
            toast.success("Вход выполнен", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            debugger;
            navigate("/account");
          })
          .catch((err) => {
            handleAuthError(err);
          });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h1 className={styles.title}>
        {isReset ? "Установить пароль" : "Добро пожаловать"}
      </h1>
      {!isReset && (
        <InputField
          name={"email"}
          value={formData.email}
          onChange={handleChange}
          placeholder={"Введите почту"}
          styles={" w-full"}
        />
      )}
      <InputField
        name={"password"}
        value={formData.password}
        onChange={handleChange}
        placeholder={"Введите пароль"}
        styles={" w-full"}
        type={"password"}
      />
      <CustomButton
        styles={"bg-[#2fac66]"}
        type="submit"
        color="success"
        label={isReset ? "Сохранить" : "Войти"}
      />

      {!isReset && (
        <span>
          Забыли пароль?{" "}
          <Link className="text-green-700" to={"/restore"}>
            Восстановить
          </Link>
        </span>
      )}
    </form>
  );
};
