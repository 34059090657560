import Cookies from "js-cookie";

export const setAccessToken = (token) => {
  Cookies.set("accessToken", token, { expires: 1 / 24 / 12 }); // 5 минут
};

export const getAccessToken = () => {
  return Cookies.get("accessToken");
};

export const setRefreshToken = (token) => {
  Cookies.set("refreshToken", token, { expires: 30 }); // Хранение на 30 дней
};

export const getRefreshToken = () => {
  return Cookies.get("refreshToken");
};

export const deleteTokens = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
};
