import { Link } from "react-router-dom";
import {
  eventBig,
  eventSmall,
  calendarIcon,
  eyeIcon,
  arrowRightIcon,
} from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllRecordsQuery } from "../../store/RTKApi/events";
import { getDate } from "../../utils/transformDate";
import React, { useMemo } from "react";
import { EventBlockItem } from "./eventBlockItem";

export const EventsPanel = () => {
  const {
    data: recordsData,

    isSuccess: recordsSuccess,
  } = useGetAllRecordsQuery({ types: "" });
  // to_date: getDate(new Date());

  const transformed = useMemo(() => {
    if (!recordsSuccess) return [];

    return [...recordsData].sort(
      (a, b) => new Date(b.to_date) - new Date(a.to_date)
    );
  }, [recordsData, recordsSuccess]);

  return (
    <section className="events">
      <div className=" flex flex-col md:flex-row whitespace-nowrap md:items-center">
        <span className="text-text-primary  text-2xl  font-medium font-rubik ">
          События
        </span>
        <Link
          to="/events"
          className="text-text-gray text-xs  font-normsl font-rubik leading-[18px] md:ml-4 flex items-center pt-1"
        >
          ВСЕ СОБЫТИЯ{" "}
          <img src={arrowRightIcon} alt="ВСЕ СОБЫТИЯ" className=" ml-2 " />
        </Link>
      </div>
      <div className="flex flex-col overflow-x-scroll md:flex-row items-stretch my-6 gap-4 pb-2 px-1 h-full ">
        {recordsSuccess &&
          transformed.slice(0, 3).map((note) => {
            return (
              <Link
                to={`/events/${note.id}`}
                className="min-w-[300px] w-full md:w-1/4 md:first:w-1/2 group "
                key={note.id}
              >
                <EventBlockItem note={note} />
              </Link>
            );
          })}
      </div>
    </section>
  );
};
