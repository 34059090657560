import React from "react";
import { useSelector } from "react-redux";
import { getFavorites } from "../store/way";

export const Bookmark = ({ nav, onClick, icon }) => {
  const count = useSelector((state) => state.ways.favorites).length;

  return (
    <div className="relative flex items-center min-w-[24px] h-6">
      <button onClick={onClick}>
        {<img src={icon} alt="icon" className=" text-white" />}
      </button>
      {nav && count > 0 && (
        <div className="absolute text-white text-center -top-3 -right-[10px] rounded-full bg-text-secondary-green w-[22px]">
          {count}
        </div>
      )}
    </div>
  );
};
