export const validateForm = (formData) => {
  let newErrors = {};

  if (!formData.title) {
    newErrors.title = "Выберите название маршрута";
  }

  if (!formData.destination_address) {
    newErrors.destination_address = "Это поле обязательно";
  }

  if (formData.educational_program.length === 0) {
    newErrors.educational_program = "Выберите хотя бы одну программу";
  }

  if (!formData.leader) {
    newErrors.leader = "Это поле обязательно";
  }

  if (formData.accompany.length === 0) {
    newErrors.accompany = "Выберите хотя бы одного сопровождающего";
  }

  if (Number(formData.students_amount) === 0) {
    newErrors.students_amount = "Значение поля должно быть больше 0";
  }
  if (isNaN(formData.students_amount)) {
    newErrors.students_amount = "Значение должно быть числом";
  }

  if (
    new Date(formData.date_leave_moscow) > new Date(formData.date_return_moscow)
  ) {
    newErrors.date_leave_moscow =
      "Дата выезда не может быть позже даты прибытия";
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Обнуляем время текущей даты

  const leaveDate = new Date(formData.date_leave_moscow);
  leaveDate.setHours(0, 0, 0, 0); // Обнуляем время даты выезда

  if (leaveDate < today) {
    debugger;
    newErrors.date_leave_moscow =
      "Дата выезда не может быть раньше текущей даты";
  }

  return [newErrors, Object.keys(newErrors).length === 0];
};
