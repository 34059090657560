import { useParams } from "react-router-dom";

import { useGetRecordsByIdQuery } from "../../store/RTKApi/events";

export const EventBreadcrumb = () => {
  const { id } = useParams();

  const { data: formData, isSuccess: successData } = useGetRecordsByIdQuery(id);

  return <>{successData && "Событие " + formData.title}</>;
};
