import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";
import { getAccessToken } from "@entities/auth";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/cms",
    prepareHeaders: (headers) => {
      const accessToken = getAccessToken();
      headers.set(
        "Authorization",
        `Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=`
      );
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getContent: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: "/main-page-media" + encodeURI(queryString),
          method: "GET",
        };
      },
    }),
    getVideo: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: "/main-page-video/" + encodeURI(queryString),
          method: "GET",
        };
      },
    }),
    getSlider: build.query({
      query: () => {
        return {
          url: "/main-page-slider",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetContentQuery,
  useLazyGetContentQuery,
  useGetSliderQuery,
  useGetVideoQuery,
  useLazyGetVideoQuery,
} = contentApi;
