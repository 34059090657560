import axios from "axios";
import config from "../config.json";

const http = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? config.baseUrl
      : config.baseUrlProd,
  headers: {
    Authorization: "Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=",
  },
});

const httpService = {
  get: http.get,
};

export default httpService;
