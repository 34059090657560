import React, { useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  changeWaysFilter,
  getCurrentWaysFilter,
  getOutDoorFilter,
} from "../../store/filter";
import { CustomCheckbox, RangeFilter } from "../ui";
import { useClickAway } from "@uidotdev/usehooks";

export const NotesFilterItem = ({
  name,
  options,
  label,
  slider,
  isOpen,
  handleOpen,
  index,
}) => {
  const currentFilter = useSelector(getCurrentWaysFilter(name));
  const dispatch = useDispatch();

  const handleChange = (target) => {
    dispatch(getOutDoorFilter(target));
  };
  return (
    <>
      <div className="filter font-rubik w-full relative ">
        <div
          className=" flex  items-center gap-2 cursor-pointer"
          onClick={() => handleOpen(index)}
          // ref={ref}
        >
          <h2 className=" text=[14px] font-normal leading-[21px]  text-text-primary whitespace-nowrap ">
            {label}
          </h2>

          <ChevronDownIcon
            className={
              " w-6 h-6 transition duration-300" +
              (isOpen ? " rotate-180" : " ")
            }
          />
        </div>
        {isOpen &&
          (slider ? (
            <RangeFilter
              options={options}
              onChange={handleChange}
              value={currentFilter}
              name={name}
            />
          ) : (
            <ul className=" md:mb-7 list-none flex flex-col justify-start gap-2 md:absolute z-10 top-full bg-white md:border p-4 rounded-md whitespace-nowrap">
              {options.map((option, index) => (
                <CustomCheckbox
                  key={option.id}
                  name={name}
                  value={option.id}
                  checked={currentFilter?.includes(option.id)}
                  onChange={handleChange}
                  label={option.title}
                />
              ))}
            </ul>
          ))}
      </div>
    </>
  );
};
