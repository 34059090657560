import React, { useEffect, useState } from "react";

import { searchPlus, play } from "../../images";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export function MultimediaCrop({ slide, type, custom }) {
  const [hoveredIndex, setHovered] = useState(null);

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Возможные опции
    });

    // Очистка при размонтировании компонента
    // return () => Fancybox.destroy();
  }, []);

  const handleEnter = (index) => {
    setHovered(index);
  };
  const handleLeave = () => {
    setHovered(null);
  };

  return (
    <div
      className={
        "grid  gap-1 2xl:grid-cols-8   md:grid-cols-6  grid-cols-2 cursor-grab" +
        (custom ? " !grid-cols-1" : "")
      }
    >
      {type === "img"
        ? slide.map((t, key, arr) => (
            <div key={key} className=" relative ">
              <img
                src={t.media_item}
                className="w-full h-full object-cover !aspect-square block"
                alt=""
              />

              {/* t.media_item, { key, arr } */}
              <a
                data-fancybox
                data-src={t.media_item}
                // className=" !aspect-square block w-full h-full"
                onMouseEnter={() => handleEnter(key)}
                onMouseLeave={handleLeave}
                // onClick={() => openModal(data, page * size + key, type)}
                className="absolute inset-0 duration-300 
                flex items-center justify-center  "
              >
                {/* {key === hoveredIndex && (
                  <img src={searchPlus} alt="key" className="w-20 invert" />
                )} */}
              </a>
            </div>
          ))
        : slide.map((t, key, arr) => {
            const mediaUrl =
              t.hosting === 1
                ? t?.url?.split("-")[1].split("_")
                : t?.url?.split("video/").pop().split("/")[0];

            const url =
              t.hosting === 1
                ? `https://vk.com/video_ext.php?oid=-${mediaUrl[0]}&id=${mediaUrl[1]}&hash=d7c57c64c989f7ab`
                : `https://rutube.ru/play/embed/${mediaUrl}`;
            return (
              <div key={key} className=" relative ">
                <img
                  src={
                    "https://cdn.vseinstrumenti.ru/imgtmb/560x504/res/content/good/noPhoto.png"
                  }
                  loading="lazy"
                  alt="img"
                  className=" aspect-square w-full h-full object-cover "
                />
                {/* t.media_item, { key, arr } */}
                <a
                  data-src={url}
                  data-fancybox="video"
                  data-type="iframe"
                  onMouseEnter={() => handleEnter(key)}
                  onMouseLeave={handleLeave}
                  // onClick={() => openModal(data, page * size + key, type)}
                  className="absolute inset-0 duration-300 hover:backdrop-blur-sm flex items-center justify-center"
                >
                  {key === hoveredIndex && (
                    <img src={play} alt="key" className="w-20 " />
                  )}
                </a>
              </div>
            );
          })}
    </div>
  );
}
