import { createSelector } from "@reduxjs/toolkit";

import { useGetAllRoutesQuery } from "../RTKApi/routes";

export const getSearchedWays = (searchText) =>
  createSelector(
    (state) => state.ways.ways,
    (state) =>
      state.filter((way) =>
        way.title.toLowerCase().includes(searchText.trim().toLowerCase())
      )
  );

export const getPupolarWays = () =>
  createSelector(
    (state) => state.ways.ways,
    (state) => state.filter((way) => way.popular).slice(0, 3)
  );

export const getAllWaysCount = () =>
  createSelector(
    (state) => state.ways.ways,
    (state) => state.length
  );

export const getFavorites = () => (state) => state.ways.favorites;

export const isFavorite = (id) =>
  createSelector(
    (state) => state.ways,
    (state) => state.favorites.some((idS) => idS === id)
  );

// export const getFilterWays = () =>
//     createSelector(
//         (state) => state.ways.ways
//     )

export const getFiltredWays = (filtredData = {}) =>
  createSelector(
    (state) => state.ways.ways,
    (state) =>
      state.filter((way) =>
        Object.keys(filtredData).every((key) => {
          return key === "duration"
            ? way[key] >= filtredData[key][0] && way[key] <= filtredData[key][1]
            : Array.isArray(way[key])
            ? filtredData[key].some((wayProp) =>
                way[key].find((key) => key === wayProp)
              )
            : filtredData[key].includes(way[key]);
        })
      )
  );

export const isLoading = () => (state) => state.ways.isLoading;
