import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGetRoutesNodesQuery } from "../store/RTKApi/routes";
import { useGetGeocodeQuery } from "../store/RTKApi/geocoder";

export const YaMap = ({ center, items, points, type }) => {
  const { data: geocode } = useGetGeocodeQuery({
    geocode: center || "Москва",
    //Апикей с лимитом запросов
    apikey: "e5f1f9d2-c26e-40c8-8dc3-cee9dc741a43",
    kind: "locality",
    format: "json",
  });
  const { data: nodesList, isSuccess: nodeListIsSuccess } =
    useGetRoutesNodesQuery({});

  const pointsList =
    nodeListIsSuccess && items
      ? items.results.map((item) => ({
          ...item,
          point: nodesList.find((node) => node.id === item.points[0]),
        }))
      : [];

  const pointsRouteList =
    nodeListIsSuccess && points
      ? points
          .map((point) => {
            return nodesList.find((node) => node.id === point);
          })
          .filter((el) => el)
          .map((p) => [p.latitude, p.longitude])
      : [];
  const pointsRouteListText =
    nodeListIsSuccess && points
      ? points
          .map((point) => {
            return nodesList.find((node) => node.id === point);
          })
          .filter((el) => el)

          .map((p) => p.name)
      : [];
  const navigate = useNavigate();
  const handleNavigate = (i) => {
    navigate(`/ways/${i}`);
  };

  const ymaps = useRef();
  const myMap = useRef(null);
  //Создание карты
  useEffect(() => {
    if (myMap.current) myMap.current.destroy();
    ymaps.current = global.ymaps;
    ymaps.current.ready(
      (myMap.current = new ymaps.current.Map(
        "map",
        {
          center: [55.73, 37.75],
          zoom: 8,
          controls: ["zoomControl"],
          behaviors: ["drag"],
        },
        {
          searchControlProvider: "yandex#search",
        }
      ))
    );
    return () => myMap.current.destroy();
  }, []);

  //Построение точек
  useEffect(() => {
    if (!points && items) {
      const MyIconContentLayout =
        ymaps.current.templateLayoutFactory.createClass(
          '<div style="color: #FFFFFF; font-weight: bold;" class=" flex justify-center items-center ">$[properties.iconContent]</div>'
        );

      myMap.current.geoObjects.removeAll();
      // const myCollection = new ymaps.current.GeoObjectCollection(null);

      let myCoords = pointsList;
      const myGeoObjects = [];
      for (let i = 0, l = myCoords.length; i < l; i++) {
        myGeoObjects[i] = new ymaps.current.Placemark(
          [myCoords[i].point.latitude, myCoords[i].point.longitude],
          {
            // balloonContent: "А эта — новогодняя",
            // iconContent: i + 1,
            // Зададим содержимое заголовка балуна.
            // balloonContentHeader:
            //   '<a href = "#" class=" text-red-500">Рога и копыта</a><br>' +
            //   '<span class="description">Сеть кинотеатров</span>',
            // Зададим содержимое основной части балуна.
            balloonContentBody:
              '<div class=" flex justify-center items-start gap-4 ">' +
              `<img src="${myCoords[i].poster}"  width="150">` +
              `<button  id="wayLink" class=' max-w-[250px] hover:text-text-primary-green transition duration-300 ease-out w-2/3 font-rubik font-normal text-xs leading-4'>${myCoords[i].short_description}</button>` +
              "</div>",
            // Зададим содержимое нижней части балуна.
            // balloonContentFooter:
            //   'Информация предоставлена:<br/>OOO "Рога и копыта"',
            // Зададим содержимое всплывающей подсказки.
            hintContent: `${myCoords[i].title}`,
            balloonOffset: [30, 0],
          },
          {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: "default#imageWithContent",
            // Своё изображение иконки метки.
            iconImageHref: "/images/mapPoint.png",
            // Размеры метки.
            iconImageSize: [48, 48],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-24, -24],
            // Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [19, 16],
            // Макет содержимого.
            iconContentLayout: MyIconContentLayout,
          }
        );

        myGeoObjects[i].events
          .add("mouseenter", function (e) {
            e.get("target").options.set({
              iconImageHref: "/images/mapPointActive.png",
            });
          })
          .add("mouseleave", function (e) {
            e.get("target").options.set({
              iconImageHref: "/images/mapPoint.png",
            });
          })
          .add("click", function (e) {
            e.get("target").options.set({
              iconImageHref: "/images/mapPoint.png",
            });
          })
          .add("balloonopen", () =>
            document
              .getElementById(`wayLink`)
              .addEventListener("click", () => handleNavigate(myCoords[i].id))
          );
      }
      const cluster = new ymaps.current.Clusterer({
        clusterIcons: [
          {
            href: "/images/mapPoint.png",
            size: [48, 48],
            offset: [-24, -24],
          },
        ],
        clusterIconContentLayout: MyIconContentLayout,
      });
      cluster.events
        // Можно слушать сразу несколько событий, указывая их имена в массиве.
        .add(["mouseenter", "mouseleave"], function (e) {
          var target = e.get("target"),
            type = e.get("type");
          if (typeof target.getGeoObjects != "undefined") {
            // Событие произошло на кластере.
            if (type === "mouseenter") {
              target.options.set({
                clusterIcons: [
                  {
                    href: "/images/mapPointActive.png",
                    size: [48, 48],
                    offset: [-24, -24],
                  },
                ],
                clusterIconContentLayout: MyIconContentLayout,
              });
            } else {
              target.options.set({
                clusterIcons: [
                  {
                    href: "/images/mapPoint.png",
                    size: [48, 48],
                    offset: [-24, -24],
                  },
                ],
                clusterIconContentLayout: MyIconContentLayout,
              });
            }
          }
        });
      cluster.add(myGeoObjects);
      myMap.current.geoObjects.add(cluster);
    }
  }, [pointsList]);
  //Построение маршрута
  useEffect(() => {
    if (points && !items) {
      const MyIconContentLayout =
        ymaps.current.templateLayoutFactory.createClass(
          `<div class=" relative flex justify-center items-center bg-black text-white  w-4 h-4 p-[2px] rounded-sm border border-white text-[10px] font-semibold">$[properties.ind]<div class=" absolute top-[120%]  text-[#151515]"></div></div>`,
          {
            build: function () {
              MyIconContentLayout.superclass.build.call(this);
              var props = this.getData().properties;

              props.set("ind", props.get("index") + 1);
              props.set("text", pointsRouteListText[props.get("index")]);
            },
          }
        );
      myMap.current.geoObjects.removeAll();
      const multiRoute = new ymaps.current.multiRouter.MultiRoute(
        {
          referencePoints: pointsRouteList,

          params: {
            //Тип маршрутизации - пешеходная маршрутизация.
            routingMode: type === 2 ? "" : "pedestrian",
            //Адреса к точкам
            reverseGeocoding: false,
            // viaIndexess: [],
          },
        },
        {
          // viaPointVisible: false,
          //Отключаем расстояния до конца
          routeActiveMarkerVisible: false,

          routeOpenBalloonOnClick: false,
          //
          wayPointIconLayout: "default#imageWithContent",
          // wayPointIconLayout: "islands#icon",
          wayPointIconImageHref: "/images/mapPoint.png",
          wayPointIconImageSize: [0, 0],
          wayPointIconImageOffset: [-8, -8],
          wayPointIconContentLayout: MyIconContentLayout,
          // Внешний вид путевых точек.
          // wayPointIconColor: "#fff",
          // wayPointIconFillColor: "#000000",
          // Задаем собственную картинку для последней путевой точки.
          // wayPointFinishIconColor: "#fff",
          // wayPointFinishIconFillColor: "#000000",
          // wayPointIconColor: "#fff",
          // wayPointIconFillColor: "#000000",
          // wayPointFinishIconContentLayout: MyIconContentLayout,

          // Позволяет скрыть иконки путевых точек маршрута.
          // wayPointVisible:false,

          // Внешний вид транзитных точек.
          // viaPointIconRadius: 1,
          // viaPointIconFillColor: "#fff",
          // viaPointActiveIconFillColor: "#000000",
          // Транзитные точки можно перетаскивать, при этом
          // маршрут будет перестраиваться.
          // viaPointDraggable: true,
          // Позволяет скрыть иконки транзитных точек маршрута.
          // viaPointVisible: false,
          // Внешний вид точечных маркеров под путевыми точками.
          // pinIconFillColor: "#fff",
          // pinActiveIconFillColor: "#000000",
          // Позволяет скрыть точечные маркеры путевых точек.
          // pinVisible: false,

          // Внешний вид линии маршрута.
          // routeStrokeWidth: 2,
          // routeStrokeColor: "#000088",
          // routeActiveStrokeWidth: 1,
          // routeActiveStrokeColor: "#E63E92",

          // Внешний вид линии пешеходного маршрута.
          // routeActivePedestrianSegmentStrokeStyle: "solid",
          // routeActivePedestrianSegmentStrokeColor: "#00CDCD",

          // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
          boundsAutoApply: true,
        }
      );
      myMap.current.geoObjects.add(multiRoute);
    }
  }, [pointsRouteList]);

  useEffect(() => {
    if (geocode) myMap.current.setCenter(geocode);
  }, [geocode]);

  return (
    <>
      <div className=" flex flex-col w-full h-[60vh]" id="map"></div>
    </>
  );
};
