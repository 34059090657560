import { PencilIcon } from "@heroicons/react/20/solid";
import ReactStars from "react-rating-stars-component";
import {
  calendarIcon,
  routeLogo,
  arrowRightIcon,
  mainBg,
  eventBig,
} from "../../images";
import { Badge } from "../ui";
import { getNoteById } from "../../store/notes";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getWayById } from "../../store/way";
import {
  tarnsformTypeWay,
  transformDistance,
  transformLocation,
  transformOrganization,
} from "../../utils/transformField";
// import { transformDate } from "../../utils/transformDate";
import {
  useGetRouteByIdQuery,
  useGetRoutesOriginByIdQuery,
  useGetRoutesRegionsByIdQuery,
  useGetRoutesTypesByIdQuery,
  useLazyGetRoutesOriginByIdQuery,
} from "../../store/RTKApi/routes";
import { transformDuration } from "../../utils/transformDuration";
import { getDate } from "../../utils/transformDate";

export const NoteItem = ({ item }) => {
  const filter = useSelector((state) => state.filter.filterData);
  const region = useSelector((state) => state.filter.regionsData);
  const { data: routeData, isSuccess: routeSuccess } = useGetRouteByIdQuery(
    item?.to_route
  );
  const { data: originData } = useGetRoutesOriginByIdQuery(routeData?.origin);

  let transformedNote = {};

  if (routeSuccess) {
    transformedNote = {
      route: { ...routeData },
      note: { ...item },
      type: filter.type.find((el) => el.id === routeData.type),
      region: region.find((el) => el.id === routeData.region),
    };
  }
  const raiting =
    transformedNote?.note?.data?.rating > 5
      ? 5
      : transformedNote?.note?.data?.rating;

  return (
    <div className=" group p-4 w-full border-2 border-dashed border-text-primary-green hover:border-solid font-rubik transition duration-1000 flex flex-col gap-4 py-5 px-7">
      <div className="flex flex-col md:flex-row  justify-between items-center gap-4">
        <Link
          to={`/notes/${item.to_route}`}
          className=" group-hover:text-text-primary-green text-xl transition duration-200"
        >
          {transformedNote?.route?.title}
        </Link>
        <Link
          to={"/notes"}
          className=" hidden text-text-gray text-xs  font-normsl font-rubik leading-[18px] ml-4 md:flex items-center pt-1"
        >
          ВСЕ ЗАМЕТКИ{" "}
          <img src={arrowRightIcon} alt="ВСЕ СОБЫТИЯ" className=" ml-2 " />
        </Link>
      </div>
      <div className="flex flex-col md:flex-row  justify-between items-start gap-4 ">
        <div className="">
          <span className="text-text-gray text-[14px] leading-[21px] inline-block">
            {transformedNote?.note?.data?.data_day_one_11}
          </span>
        </div>
        <div className=" flex justify-between w-full md:justify-end items-center gap-2 flex-wrap ">
          <div className="flex gap-[5px]">
            {transformedNote?.note?.media.map((i) => {
              return (
                <img
                  key={i?.media_item}
                  src={i?.media_item}
                  alt="Название маршрута"
                  className="   object-cover aspect-square  max-h-[80px] max-w-[80px]"
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row  justify-between md:items-center gap-4 ">
        <div className=" flex flex-col w-full lg:flex-row  justify-center lg:items-center gap-4">
          <div className=" text-[14px] leading-[21px] font-semibold font-rubik md:my-4">
            <div className=" flex justify-between text-text-gray  ">
              <div className="flex justify-start items-center gap-2">
                <img src={calendarIcon} alt="Новость" className=" " />
                <span className=" whitespace-nowrap">
                  {getDate(new Date(Date.now()))}
                </span>
              </div>
            </div>
          </div>
          <div className="flex   lg:justify-center gap-2 text-black text-[16px] items-center w-full">
            <span>
              {transformedNote?.note?.data?.rating > 5
                ? 5
                : transformedNote?.note?.data?.rating}
              /5
            </span>{" "}
            <div className=" min-w-[160px]">
              <ReactStars
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#E99B26"
                color={"#B6B4C3"}
                value={5}
                edit={false}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center flex-wrap w-full    my-4">
            <Badge
              styles={" h-[30px] border-0 bg-[#EBEBEB]"}
              textstyle={" text-black text-[12px]"}
              label={originData?.title}
            />
            <Badge
              styles={" h-[30px] border-[#C4C4C4] bg-white"}
              textstyle={" text-text-secondary-green"}
              label={transformedNote?.region?.title}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDuration(transformedNote?.route?.duration)}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDistance(transformedNote?.route?.length)}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={" h-[30px] bg-[#6CAB4E] border-0"}
              // type={type?.id}
              label={transformedNote?.type?.title}
              textstyle={" text-white"}
            />
          </div>
        </div>
        <div className=" flex w-full lg:w-fit justify-start items-start lg:justify-end gap-2 text-text-gray  text-[14px] leading-[21px] font-semibold font-rubik mt-1 md:items-start">
          <PencilIcon className=" h-4 w-4 fill-text-gray opacity-80" />
          <span>Автор:</span>
          <span className="">{transformedNote?.route?.author}</span>
        </div>
      </div>
    </div>
  );
};
