import React from "react";
import { useGetRoutesAttractionsByIdQuery } from "../../store/RTKApi/routes";
import Skeleton from "react-loading-skeleton";

export const WayObject = ({ id, lastId }) => {
  const { data: attr, isFetching } = useGetRoutesAttractionsByIdQuery(id);

  return isFetching ? (
    <Skeleton className="inline-block w-40 mr-1" />
  ) : (
    <span className="inline break-words mr-1">
      {attr.title}
      {id !== lastId ? ", " : ""}
    </span>
  );
};
