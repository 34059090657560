import React from "react";

export const CustomButton = ({
  label,
  onClick,
  styles,
  children,
  disabled,
  ...rest
}) => {
  const cls = `block text-white rounded-md shadow-md h-[50px] duration-300 my-1  `;
  return (
    <button
      onClick={onClick}
      className={cls + styles + (disabled ? " !bg-gray-700" : "")}
      disabled={disabled}
      {...rest}
    >
      {label ? label : children}
    </button>
  );
};
