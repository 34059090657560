import { TextField } from "@mui/material";
import React from "react";
import { CustomButton } from "../../../components/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setOneTeacher, updateTeacher } from "../../../store/schools";
import {
  useAddTeacherMutation,
  useUpdateTeacherMutation,
} from "../../../store/RTKApi/schools";
import { getTeacherById } from "../../../store/schools/selectors";
import { parseName } from "../../../utils/parseName";

export const CreateStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const teacher = useSelector(getTeacherById(location.state?.id));

  const [last_name, first_name, patronymic] =
    parseName(teacher?.full_name) || Array(3).fill("");

  const isEdit = location.pathname.endsWith("edit");

  const defaultValues = !isEdit
    ? {
        email: "",
        contact_number: "",
      }
    : {
        email: teacher.email,
        contact_number: teacher.contact_number,
        position: teacher.position,
        last_name,
        first_name,
        patronymic,
      };

  const [addTeacher, { data, error }] = useAddTeacherMutation();
  const [FetchUpdateTeacher] = useUpdateTeacherMutation();

  const fieldSchema = yup.object().shape({
    email: yup
      .string()
      .email("Почта должна соответствовать шаблону user@example.ru"),
    position: yup.string().required("Должность обязательна для заполнения"),
    patronymic: yup
      .string()
      .matches(/^[A-ZА-Я]+/, "Отчество должно начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Отчество не должно содержать цифры"),
    first_name: yup
      .string()
      .required("Имя обязательно для заполнения")
      .matches(/^[A-ZА-Я]+/, "Имя должно начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Имя не должно содержать цифры"),
    last_name: yup
      .string()
      .required("Фамилия обязательна для заполнения")
      .matches(/^\S+$/, "Фамилия не должна содержать пробелы")
      .matches(/^[A-ZА-Я]+/, "Фамилия должна начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Фамилия не должна содержать цифры"),
  });

  const {
    control,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(fieldSchema),
    defaultValues: defaultValues,
  });

  const onAddSubmit = async (formData) => {
    try {
      const { data } = await addTeacher(formData);
      if (data.error) {
        throw new Error();
      }
      dispatch(setOneTeacher(data));

      toast.success("Сотрудник добавлен");
      navigate(-1);
    } catch (e) {
      toast.error("Сервер недоступен, попробуйте немного позже");
    }
  };

  const onUpdateSubmit = async (formData) => {
    try {
      const { data } = await FetchUpdateTeacher({
        id: teacher.id,
        ...formData,
      });
      if (data.error) {
        throw new Error();
      }

      dispatch(updateTeacher(teacher.id, data));

      toast.success("Информация о сотруднике обновлена");
      navigate(-1);
    } catch (e) {
      toast.error("Сервер недоступен, попробуйте немного позже");
    }
  };

  return (
    <section>
      <h3 className="text-xl">
        Форма {isEdit ? "редактирования" : "добавления"} сотрудника
      </h3>
      <form
        className="flex flex-col md:flex-row justify-between mt-5"
        onSubmit={
          isEdit ? handleSubmit(onUpdateSubmit) : handleSubmit(onAddSubmit)
        }
      >
        <div className=" w-full md:w-8/12 flex flex-col gap-3 md:gap-5 ">
          <div className="flex gap-3 flex-col md:flex-row">
            <Controller
              control={control}
              name="last_name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name="last_name"
                  required
                  label="Фамилия"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
            <Controller
              control={control}
              name="first_name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  name="first_name"
                  label="Имя"
                  error={!!error}
                  defaultValue={value}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />

            <Controller
              control={control}
              name="patronymic"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name="patronymic"
                  label="Отчество"
                  error={!!error}
                  defaultValue={value}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
          </div>
          <div className="flex gap-3 flex-col md:flex-row">
            <Controller
              control={control}
              name="position"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Должность"
                  name="position"
                  required
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />

            <Controller
              control={control}
              name="contact_number"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PatternFormat
                  format="+# (###) ###-####"
                  defaultValue={value}
                  onChange={onChange}
                  placeholder="+7 (999) 777-5555"
                  name="contact_number"
                  error={!!error}
                  helperText={error?.message}
                  label="Телефон"
                  valueIsNumericString={false}
                  customInput={TextField}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                name="email"
                label="Почта"
                error={!!error}
                defaultValue={value}
                helperText={error?.message}
                className="w-full"
                onChange={onChange}
                InputProps={{ autoComplete: "off" }}
              />
            )}
          />
        </div>
        <div className="w-full mt-5 flex flex-col gap-2 md:w-2/12 md:mt-0">
          <CustomButton
            styles={
              "  bg-text-primary-green hover:bg-text-secondary-green px-4  w-full"
            }
            disabled={isSubmitting || !isValid}
            type="submit"
            label={isEdit ? "Редактировать сотрудника" : "Добавить сотрудника"}
          />
          <CustomButton
            styles={"  bg-gray-400 hover:bg-gray-500 px-4  w-full"}
            label={"Назад"}
            type="button"
            onClick={() => navigate(-1)}
          />
        </div>
      </form>
    </section>
  );
};
