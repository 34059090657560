import React from "react";
import { useOutletContext } from "react-router-dom";
import { addBlankTarget } from "../../utils/addAttribute";

export const WayDescription = () => {
  const { description } = useOutletContext();

  return (
    <section className="min-h-[40vh]">
      <div
        dangerouslySetInnerHTML={{
          __html: addBlankTarget(description),
        }}
        className=" indent-8 text-text-primary mb-5 ck-content"
      ></div>
    </section>
  );
};
