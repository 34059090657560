import React from "react";

import "./burger.sass";

export const Burger = ({ open, setOpen }) => {
  return (
    <div
      onClick={() => setOpen((prev) => !prev)}
      className="flex w-11 h-11 items-center justify-center p-3 rounded-[5px] bg-text-primary-green md:hidden mr-4 "
    >
      <div className={"burger " + (open ? "open" : "")}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
