import React, { useContext, createContext, useState } from "react";

export const LoginContext = createContext();

export function useLogin() {
  const context = useContext(LoginContext);
  return context;
}

export const LoginProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openLoginForm = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <LoginContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </LoginContext.Provider>
  );
};
