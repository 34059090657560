import React, { useState } from "react";
import { CustomButton } from "../ui";
import { paginate } from "../../utils/paginate";
import { NoteItem } from "./noteItem";
import { useSelector } from "react-redux";
import { isLoadingNotes } from "../../store/notes";
import { useGetAllOutdoorsNotesQuery } from "../../store/RTKApi/outdoorEvents";
import { useGetRouteByIdQuery } from "../../store/RTKApi/routes";

export const NotesList = ({ items }) => {
  const isLoading = useSelector(isLoadingNotes());
  const [page, setPage] = useState(1);
  const onClick = () => {
    setPage((prev) => prev + 1);
  };
  const transformedItems = [];
  const notesCrop = paginate(items, page, 8);

  if (isLoading) return "...loading";
  return (
    <>
      {notesCrop.map((note, index) => (
        <NoteItem id={note.id} key={index} item={note} />
      ))}
      {notesCrop.length !== items.length && (
        <CustomButton
          onClick={onClick}
          label={"Показать еще"}
          styles={
            " w-full shadow-none border !text-text-gray !border-[#D9D9D9]"
          }
          textstyle={""}
        />
      )}
    </>
  );
};
