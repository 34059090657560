import { createSlice } from "@reduxjs/toolkit";

const eventsSlice = createSlice({
  name: "event",
  initialState: {
    records: [],
    types: [],
    medias: [],
    calendar: "",
  },
  reducers: {
    getAllRecords: (state, action) => {
      state.records = action.payload;
    },
    getAllTypes: (state, action) => {
      state.types = action.payload;
    },
    getAllMedias: (state, action) => {
      state.medias = action.payload;
    },
    getCalendarState: (state, action) => {
      state.calendar = action.payload;
    },
  },
});

const { actions, reducer: eventsReducer } = eventsSlice;

export const { getAllRecords, getAllTypes, getAllMedias, getCalendarState } =
  actions;

export default eventsReducer;
