import { Link } from "react-router-dom";
import { downloadIconType } from "../../utils/downloadIcon";
import {
  useGetClubDocumentsSectionsQuery,
  useGetClubDocumentsMethodicalDocumentsQuery,
} from "../../store/RTKApi/club";

export const MethodicalDocumentsList = ({ page }) => {
  const { data: sections, isSuccess: isSucceessSections } =
    useGetClubDocumentsSectionsQuery();
  const filtredSections = sections?.filter(
    (sec) => sec.page_of_content === page
  );

  const { data: methodicalDocs, isSuccess: isSuccessMethodicalDocs } =
    useGetClubDocumentsMethodicalDocumentsQuery({
      page_of_content: page,
    });

  return (
    isSucceessSections &&
    filtredSections.map((section) => (
      <div className="support-block mt-10" key={section.id}>
        <div className="text-xl font-semibold">{section.title}</div>
        <div className="flex justify-between gap-5 flex-wrap mt-4">
          {isSuccessMethodicalDocs &&
            methodicalDocs
              .filter((doc) => doc.section === section.id)
              .map((doc) => (
                <div
                  className="flex w-full md:w-[45%] xl:w-[49%] px-4 gap-4 py-3 bg-[#EBEBEB]"
                  key={doc.id}
                >
                  <div className="w-6 h-6 min-w-[24px]">
                    <img
                      src={downloadIconType(doc.file)}
                      alt="Документы"
                      className=" w-full h-full"
                    />
                  </div>
                  <Link
                    to={doc.file}
                    className="leading-7  text-[16px] text-text-primary hover:text-text-primary-green trasition duration-300"
                    target="_blank"
                  >
                    {doc.title}
                  </Link>
                </div>
              ))}
        </div>
      </div>
    ))
  );
};
