import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookieMiddleware from "../middleware/cookie";
import Cookies from "js-cookie";
import config from "../../config.json";
import { getAccessToken } from "@entities/auth";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/auth/",
    prepareHeaders: (headers) => {
      const accessToken = getAccessToken();
      headers.set(
        "Authorization",
        `Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=`
      );
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    loginUser: build.mutation({
      query: (body) => ({
        url: "token/",
        method: "POST",
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: `activate/${body.uidb}/${body.token}/`,
        method: "PUT",
        body: { password: body.password },
      }),
    }),
    restorePassword: build.mutation({
      query: (body) => ({
        url: `password/reset/`,
        method: "post",
        body: { email: body.email },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useResetPasswordMutation,
  useRestorePasswordMutation,
} = authApi;
