export function sortByTitle(a, b) {
  const titleA = a.title.toLowerCase();
  const titleB = b.title.toLowerCase();

  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }
  return 0;
}

//13,1,7,6, 14, 1, 11, 2

export const sortByOrder = (data, order, key) => {
  if (!data || !order || !key) return data;
  return order.map((idx) => data.find((el) => el[key] === idx));
};
