import { TextField } from "@mui/material";
import { CustomButton } from "../../../components/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setOneStudent } from "../../../store/schools";
import {
  useAddStudentMutation,
  useUpdateStudentsMutation,
} from "../../../store/RTKApi/schools";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from "@mui/x-date-pickers/locales";
import { getStudentsInfo } from "../../../store/schools/selectors";
import { parseName } from "../../../utils/parseName";
import dayjs from "dayjs";
import { useEffect } from "react";
import { formatDate } from "../../../utils/formatDate";

export const CreateSudent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const student = useSelector(getStudentsInfo({ id: location.state?.id }));
  const [last_name, first_name, patronymic] = parseName(student.full_name) ?? [
    "",
    "",
    "",
  ];
  const isEdit = location.pathname.endsWith("edit");

  const defaultValues = !isEdit
    ? {
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        grade: "",
        birth_date: "",
      }
    : {
        email: student.email,
        phone_number: student.phone_number,
        position: student.position,
        grade: student.grade,
        birth_date: student.birth_date,
        last_name,
        first_name,
        patronymic,
      };

  const fieldSchema = yup.object().shape({
    grade: yup
      .string()
      .matches(
        /^\w+\s[\wА-Яа-я]+$/,
        "Строка должна состоять из двух слов. Пример: '9 А', '62 группа'"
      ),
    birth_date: yup
      .string()
      .required("Дата рождения обязательна для заполнения"),
    patronymic: yup
      .string()
      .required("Отчество обязательно для заполнения")
      .matches(/^[A-ZА-Я]+/, "Отчество должно начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Отчество не должно содержать цифры"),
    first_name: yup
      .string()
      .required("Имя обязательно для заполнения")
      .matches(/^[A-ZА-Я]+/, "Имя должно начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Имя не должно содержать цифры"),
    last_name: yup
      .string()
      .required("Фамилия обязательна для заполнения")
      .matches(/^\S+$/, "Фамилия не должна содержать пробелы")
      .matches(/^[A-ZА-Я]+/, "Фамилия должна начинать с заглавной буквы")
      .matches(/^[^\d]+$/, "Фамилия не должна содержать цифры"),
    email: yup
      .string("dwad")
      .nullable()
      .optional()
      .email("Почта должна соответствовать шаблону user@example.ru"),
  });

  const [addStudent] = useAddStudentMutation();
  const [updateStudent] = useUpdateStudentsMutation();

  const onUpdateSubmit = async (formData) => {
    try {
      const { data } = await updateStudent({
        id: student.id,
        ...formData,
        birth_date: formatDate(formData.birth_date),
      });

      if (data.error) {
        throw new Error();
      }

      toast.success("Ученик обновлен", { onClose: () => navigate(-1) });
    } catch (e) {
      toast.error("Сервер недоступен, попробуйте немного позже");
    }
  };

  const {
    control,
    formState: { isValid, isSubmitting, errors },

    handleSubmit,
  } = useForm({
    mode: "all",
    resolver: yupResolver(fieldSchema),
    defaultValues,
  });

  const errorsValid = Object.keys(errors).length === 0;

  const onSubmit = async (formData) => {
    try {
      const { data } = await addStudent({
        ...formData,
        birth_date: formatDate(formData.birth_date),
      });
      if (data.error) {
        throw new Error();
      }

      dispatch(setOneStudent(data));
      toast.success("Ученик добавлен", { onClose: () => navigate(-1) });
    } catch (e) {
      toast.error("Сервер недоступен, попробуйте немного позже");
    }
  };

  return (
    <section>
      <h3 className="text-xl">
        Форма {isEdit ? "редактирования" : "добавления"} ученика
      </h3>
      <form
        className="flex flex-col md:flex-row justify-between mt-5"
        onSubmit={
          isEdit ? handleSubmit(onUpdateSubmit) : handleSubmit(onSubmit)
        }
      >
        <div className=" w-full md:w-8/12 flex flex-col gap-3 md:gap-5 ">
          <div className="flex gap-3 flex-col md:flex-row">
            <Controller
              control={control}
              name="last_name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name="last_name"
                  required
                  defaultValue={value}
                  label="Фамилия"
                  error={!!error}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
            <Controller
              control={control}
              name="first_name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  name="first_name"
                  label="Имя"
                  error={!!error}
                  defaultValue={value}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />

            <Controller
              control={control}
              name="patronymic"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name="patronymic"
                  required
                  label="Отчество"
                  error={!!error}
                  defaultValue={value}
                  helperText={error?.message}
                  className="w-full"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
          </div>

          <div className="flex gap-3 flex-col md:flex-row">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={
                ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <Controller
                control={control}
                name="birth_date"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    closeOnSelect
                    className="w-full md:w-1/3"
                    disableFuture
                    defaultValue={dayjs(value)}
                    onChange={onChange}
                    error={!!error}
                    slotProps={{
                      textField: {
                        helperText: error?.message,
                        required: true,
                      },
                    }}
                    name="birth_date"
                    format="DD-MM-YYYY"
                    label="Дата рождения"
                  />
                )}
              />
            </LocalizationProvider>
            <Controller
              control={control}
              name="phone_number"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PatternFormat
                  format="+7 (###) ###-####"
                  onChange={onChange}
                  name="phone_number"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  label="Телефон"
                  valueIsNumericString={false}
                  customInput={TextField}
                  className="grow"
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
            <Controller
              control={control}
              name="grade"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name="grade"
                  label="Класс/Группа"
                  error={!!error}
                  defaultValue={value}
                  helperText={error?.message}
                  className="duration-300"
                  onChange={onChange}
                  InputProps={{ autoComplete: "off" }}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                name="email"
                label="Электронная почта"
                error={!!error}
                helperText={error?.message}
                defaultValue={value}
                className="w-full"
                onChange={onChange}
                InputProps={{ autoComplete: "off" }}
              />
            )}
          />
        </div>
        <div className="w-full mt-5 flex flex-col gap-2 md:w-2/12 md:mt-0">
          <CustomButton
            styles={
              "  bg-text-primary-green hover:bg-text-secondary-green px-4  w-full"
            }
            disabled={!errorsValid && (isSubmitting || !isValid)}
            type="submit"
            label={isEdit ? "Редактировать ученика" : "Добавить ученика"}
          />
          <CustomButton
            styles={"  bg-gray-400 hover:bg-gray-500 px-4  w-full"}
            label={"Назад"}
            type={"button"}
            onClick={() => navigate(-1)}
          />
        </div>
      </form>
    </section>
  );
};
