import React from "react";
import {
  nameGreenIcon,
  postGreenIcon,
  phoneGreenIcon,
  emailGreenIcon,
  edit,
  close,
  calendarGreenIcon,
  classGreenIcon,
  placeGreenIcon,
  parentsGreenIcon,
} from "../../images";
import { useNavigate } from "react-router-dom";

const topFields = [
  { title: "ФИО:", path: "full_name", icon: nameGreenIcon },
  { title: "Дата рождения:", path: "birth_date", icon: calendarGreenIcon },
  { title: "Класс:", path: "grade", icon: classGreenIcon },

  { title: "Телефон:", path: "phone_number", icon: phoneGreenIcon },
  { title: "Почта:", path: "email", icon: emailGreenIcon },

  // { title: "Законные представители:", icon: parentsGreenIcon },
  // { title: "Мама:", name: "mother", icon: null },
  // { title: "Папа:", name: "father", icon: null },
];

export const StudentItem = ({ props }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" flex flex-col lg:flex-row justify-center items-center gap-4 p-4 border border-[#D9D9D9]">
        <div className="  w-full lg:w-1/2 flex justify-between flex-col h-full ">
          <div className="flex gap-4 items-start mt-2 w-full md:w-3/5">
            <div className="icon flex justify-center items-center rounded-full bg-[#EBEBEB]">
              <img
                className="min-w-[24px] w-6 h-6"
                src={topFields[0].icon}
                alt="icon"
              />
            </div>
            <div className="top-info__content flex flex-row">
              <span className="font-bold">{props.full_name}</span>
            </div>
          </div>
          <div className="flex gap-2 flex-col md:flex-row ">
            <button
              onClick={() =>
                navigate("edit", {
                  state: { id: props.id },
                })
              }
              className="flex gap-1"
            >
              <img src={edit} alt="edit" />
              <span className="text-xs">Редактировать</span>
            </button>
            <button className="flex gap-1">
              <img src={close} alt="remove" />
              <span className="text-xs">Удалить</span>
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="top-info flex flex-col  justify-stretch flex-wrap pb-4">
            {topFields.slice(1).map(({ title, icon, path }) => (
              <div key={title} className="flex gap-4 items-center mt-2 w-full">
                <div className="icon flex justify-center items-center rounded-full">
                  <img
                    className={"w-6 h-6 " + (!icon && "invisible")}
                    src={icon}
                    alt="icon"
                  />
                </div>
                <div className="top-info__content flex flex-row">
                  <span className="text-gray-400">{title}</span>
                  <span className=" ml-2 text-[16px] font-normal text-text-primary">
                    {props[path]}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
