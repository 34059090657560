import React from "react";

export const Container = ({ className, children }) => {
  return (
    <div
      className={
        "container mx-auto h-full px-4 " + (className ? className : "")
      }
    >
      {children}
    </div>
  );
};
