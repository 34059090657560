import axios from "axios";
import config from "../../config.json";

import {
  deleteTokens,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "@entities/auth";

const cookieMiddleware = (storeAPI) => (next) => async (action) => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  if (
    (!accessToken || action?.payload?.data?.code === "token_not_valid") &&
    refreshToken
  ) {
    try {
      const res = await axios.post(`${config.baseUrl}/auth/token/refresh/`, {
        refresh: refreshToken,
      });

      setAccessToken(res?.data?.access);
    } catch (err) {
      deleteTokens();

      window.location.href = "/";

      // Может потребоваться дополнительная логика для обработки ошибки, например, перенаправление пользователя на страницу входа
    }
  }
  return next(action);
};

export default cookieMiddleware;
