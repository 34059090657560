import { CustomButton } from "@components/ui";
import styles from "./LoginPage.module.scss";
import Logo from "./assets/logo.svg";
import { LoginForm } from "./ui/LoginForm";
import { Link, useLocation } from "react-router-dom";
import { RestoreForm } from "./ui/LoginForm/RestoreForm";

export const LoginPage = () => {
  const { pathname } = useLocation();

  const isResetPage = pathname.includes("reset");
  const isRestore = pathname.includes("restore");
  return (
    <div className={styles.loginPage}>
      <div className={styles.left}>
        <Link to={"/"}>
          <CustomButton
            label={"На главную"}
            styles="bg-[#2fac66] px-4 !h-8 absolute top-5 left-5"
          />
        </Link>
        <div className={styles.container}>
          {isRestore ? <RestoreForm /> : <LoginForm isReset={isResetPage} />}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.back}></div>
        <img src={Logo} alt="" />
      </div>
    </div>
  );
};
