import React from "react";
import { WaysItemBlock } from "./waysItemBlock";
import { WaysListItem } from "./waysListItem";

import { CustomButton } from "../ui";
// import { paginate } from "../../utils/paginate";

export const WaysList = ({ popular, handleClickPage, allWays }) => {
  const onClick = () => {
    handleClickPage("page_size");
  };
  // const waysCrop = paginate(allWays?.results, (page = 1), count);
  // ТУТ НУЖНО ОТЛАВЛИВАТЬ ОШИБКИ!!!!!

  return (
    <>
      {popular ? (
        allWays?.results
          .slice(0, 3)
          .map((way) => <WaysItemBlock key={way.id} id={way.id} way={way} />)
      ) : (
        <>
          {allWays?.results?.map((way) => {
            return <WaysListItem way={way} key={way.id} />;
          })}
          {allWays?.results.length !== allWays?.count && (
            <CustomButton
              onClick={() => onClick()}
              label={"Показать еще"}
              styles={
                " w-full shadow-none border !text-text-gray !border-[#D9D9D9]"
              }
              textstyle={""}
            />
          )}
        </>
      )}
    </>
  );
};
