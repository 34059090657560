import React from "react";
import { ManualItem, MethodicalDocumentsList } from "../../../components";
import {
  useGetClubInstructionsPortalInstructionsQuery,
  useGetClubInstructionsVideoQuery,
} from "../../../store/RTKApi/club";
import { Link } from "react-router-dom";
import { downloadIconType } from "../../../utils/downloadIcon";

export const Manual = () => {
  //Удалить когда перенесут файлы на сервере
  // const { data: instructions, isSuccess } =
  //   useGetClubInstructionsPortalInstructionsQuery();
  // const { data: videoInstructions, isSuccess: videoIsSucceess } =
  //   useGetClubInstructionsVideoQuery();
  return (
    <section className="support-manual ">
      <div className="support-block mt-10">
        <div className="text-xl font-semibold">
          Городская система организационно-методической поддержки
        </div>
        <div className="flex flex-col gap-2 flex-wrap mt-4 w-full">
          {/* {isSuccess &&
            instructions.map((doc) => (
              <div
                className="flex w-full md:w-[45%] xl:w-[49%] px-4 gap-4 py-3 bg-[#EBEBEB]"
                key={doc.id}
              >
                <div className="w-6 h-6 min-w-[24px]">
                  <img
                    src={downloadIconType(doc.file)}
                    alt="Документы"
                    className=" w-full h-full"
                  />
                </div>

                <Link
                  target="_blanck"
                  to={doc.file}
                  className="leading-7  text-[16px] text-text-primary hover:text-text-primary-green trasition duration-300"
                >
                  {doc.title}
                </Link>
              </div>
            ))} */}
          <Link
            target="_blanck"
            to={"https://projects.goulp.ru"}
            className="leading-7  text-[16px] text-text-primary-green trasition duration-300"
          >
            Лаборатория путешествий
          </Link>
          <Link
            target="_blanck"
            to={"https://mducekt.mskobr.ru/articles/598"}
            className="leading-7  text-[16px] text-text-primary-green trasition duration-300"
          >
            Маршрутно-квалификационная комиссия
          </Link>
          <Link
            target="_blanck"
            to={
              "https://mducekt.mskobr.ru/pedagogam/poleznaya-informaciya?ysclid=m1986pt0xx848512832"
            }
            className="leading-7  text-[16px] text-text-primary-green trasition duration-300"
          >
            Центр Экологии, Краеведения и Туризма
          </Link>
        </div>
      </div>
      {/* <MethodicalDocumentsList page={1} />
      {videoIsSucceess && videoInstructions?.length > 0 && (
        <div className="support-block mt-10">
          <div className="text-xl font-semibold">
            Видеоуроки по подготовке выездного мероприятия
          </div>
          <div className="flex justify-between gap-5 flex-wrap mt-4">
            {videoInstructions?.map((video) => (
              <ManualItem video={video} key={video.id} />
            ))}
          </div>
        </div>
      )} */}
    </section>
  );
};
