class LocalStorageService {
  constructor() {
    this.favorite = "favorite";
  }

  addFavorite(item) {
    const prevState = this.getFavorites() ?? [];
    localStorage.setItem(this.favorite, JSON.stringify([...prevState, item]));
  }

  removeFavorite(item) {
    const prevState = this.getFavorites();
    localStorage.setItem(
      this.favorite,
      JSON.stringify(prevState.filter((el) => el !== item))
    );
  }

  getFavorites() {
    return JSON.parse(localStorage.getItem(this.favorite));
  }
}

export const localStorageService = new LocalStorageService();
