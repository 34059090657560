import React, { useState, useLayoutEffect, useEffect } from "react";
import ColCalendar from "color-calendar";
import "./calendar.css";
import { getDate, transformDate } from "../../utils/transformDate";
import { useDispatch } from "react-redux";
import { getCalendarStateAction } from "../../store/events";

export function Calendar({ events, handler, points }) {
  const [data, setData] = useState("");

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    new ColCalendar({
      id: "#aaa",
      theme: "glass",
      primaryColor: "#2FAC66",
      headerColor: "rgb(0, 0, 0)",
      headerBackgroundColor: "#fff",
      startWeekday: 1,
      borderRadius: "none",
      customWeekdayValues: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
      customMonthValues: [
        "ЯНВАРЬ",
        "ФЕВРАЛЬ",
        "МАРТ",
        "АПРЕЛЬ",
        "МАЙ",
        "ИЮНЬ",
        "ИЮЛЬ",
        "АВГУСТ",
        "СЕНТЯБРЬ",
        "ОКТЯБРЬ",
        "НОЯБРЬ",
        "ДЕКАБРЬ",
      ],
      dropShadow: "none",
      weekdayType: "long-upper",
      monthDisplayType: "long",
      calendarSize: "small",
      layoutModifiers: ["month-left-align"],
      eventsData: points,
      dateChanged: (props) => {
        setData(getDate(props));
        dispatch(getCalendarStateAction(getDate(props)));
        handler(props);
      },
      monthChanged: (currentDate, events) => {},
    });
  }, [events]);

  return <div className="w-full" id="aaa"></div>;
}
