import React from "react";
import { transformColor } from "../../utils/transformField";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Blocks = ({ label, styles, textstyle, color, type }) => {
  return (
    <SkeletonTheme baseColor="#2fac662e" highlightColor="#fffffff14">
      {/*  <div*/}
      {/*      style={{*/}
      {/*          backgroundColor: transformColor(type),*/}
      {/*      }}*/}
      {/*      className={`rounded-[60px] border py-[2px] px-[10px] flex justify-center items-center ${styles}`}*/}
      {/*  >*/}
      {/*      {label ? (*/}
      {/*          <span*/}
      {/*              className={*/}
      {/*                  "text-center leading-[18px] font-normal text-[16px] whitespace-nowrap  text-[#151515] font-rubik " +*/}
      {/*                  textstyle*/}
      {/*              }*/}
      {/*          >*/}
      {/*  {label}*/}
      {/*</span>*/}
      {/*      ) : (*/}
      {/*          <Skeleton width={50} />*/}
      {/*      )}*/}
      {/*  </div>*/}
    </SkeletonTheme>
  );
};
