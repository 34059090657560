import React from "react";
import {
  nameGreenIcon,
  postGreenIcon,
  phoneGreenIcon,
  emailGreenIcon,
  edit,
  close,
} from "../../images";
import { useResize } from "../../hooks/useResize";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeOneTeacher } from "../../store/schools";
import { useRemoveTeacherMutation } from "../../store/RTKApi/schools";
import { toast } from "react-toastify";

const topFields = [
  { title: "ФИО:", name: "name", icon: nameGreenIcon },
  { title: "Должность:", name: "post", icon: postGreenIcon },
  { title: "Телефон:", name: "phone", icon: phoneGreenIcon },
  { title: "Почта:", name: "email", icon: emailGreenIcon },
];

export const StaffItem = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [removeTeacher] = useRemoveTeacherMutation();

  const handleDelete = async () => {
    try {
      await removeTeacher({ id: props.id });
      dispatch(removeOneTeacher(props.id));
      toast.success("Сотрудник удален");
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <>
      <div className=" flex justify-center flex-col sm:flex-row md:flex-col lg:flex-row items-center gap-4 p-4 border border-[#D9D9D9]">
        <div className=" w-full sm:w-1/2 md:w-full lg:w-1/2  flex justify-between flex-col h-full ">
          <div className="flex gap-4 items-center justify-normal mt-2 w-full ">
            <div className="icon flex justify-center items-center rounded-full bg-[#EBEBEB]">
              <img
                className="min-w-[24px] h-6"
                src={topFields[0].icon}
                alt=""
              />
            </div>
            <div className="top-info__content flex flex-row">
              <span className="font-bold">{props.name}</span>
            </div>
          </div>

          <div className="flex mt-4 gap-2 sm:flex-col md:flex-row ">
            <button
              onClick={() =>
                navigate("edit", {
                  state: { id: props.id },
                })
              }
              className="flex gap-1"
            >
              <img src={edit} alt="edit" />
              <span className="text-xs">Редактировать</span>
            </button>
            <button onClick={handleDelete} className="flex gap-1">
              <img src={close} alt="remove" />
              <span className="text-xs">Удалить</span>
            </button>
          </div>
          {/* Раскоментить когда появится функционал */}
        </div>
        <div className="w-full sm:w-1/2 md:w-full lg:w-1/2 ">
          <div className="top-info flex flex-col gap-2  justify-stretch flex-wrap">
            {topFields.slice(1).map(({ title, icon, name }) => (
              <div
                key={title}
                className="flex  gap-4 props[name] items-start h-full max-w-full "
              >
                <div className="icon flex justify-center items-start h-full rounded-full">
                  <img className="w-6 h-6 min-w-[24px]" src={icon} alt="" />
                </div>
                <div className="top-info__content flex flex-row w-full">
                  <span className="text-gray-400">{title}</span>
                  <span className=" ml-2 break-words word text-[16px]  max-w-full font-normal text-text-primary">
                    {props[name] ? props[name] : "не заполнено"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
