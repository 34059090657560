import { useState } from "react";
import styles from "./LoginForm.module.scss";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputField } from "@components/ui";
import { useRestorePasswordMutation } from "store/RTKApi/auth";

export const RestoreForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
  });

  // const [getTokenTrigger] = useGetTokenMutation({});

  const [triggerReset] = useRestorePasswordMutation({});

  const handleChange = (target) => {
    setFormData((prev) => ({ ...prev, [target.name]: target.value.trim() }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    triggerReset({
      email: formData.email,
    })
      .unwrap()
      .then((res) => {
        toast.success(
          "Заявка на восстановление пароля отправлена на электронную почту",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        navigate("/login");
      })
      .catch((e) => {
        toast.error("Ошибка восстановления пароля", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h1 className={styles.title}>Восстановление пароля</h1>

      <InputField
        name={"email"}
        value={formData.email}
        onChange={handleChange}
        placeholder={"Введите почту"}
        styles={" w-full"}
      />
      <CustomButton
        styles={"bg-[#2fac66]"}
        type="submit"
        color="success"
        label={"Отправить"}
      />
    </form>
  );
};
