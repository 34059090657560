import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export const SelectField = ({
  name,
  styles,
  onChange,
  options = [],
  placeholder,
  defaultValue,
  children,
  value,
}) => {
  const handleCange = (e) => {
    onChange({ name, value: e });
  };
  useEffect(() => {
    if (defaultValue) onChange({ name, value: defaultValue });
  }, []);

  return (
    <Listbox value={value} onChange={handleCange} className=" w-full">
      <div className={"relative font-rubik " + styles}>
        <Listbox.Button
          className={
            "  h-full  relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border focus:outline-none  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-none sm:text-sm "
          }
        >
          <span
            className={
              "block truncate leading-[18px] font-normal text-[14px]  text-text-primary font-rubik " +
              (value?.label ? "" : "  text-text-gray") +
              (children ? " ps-6" : "  ps-1")
            }
          >
            {children}
            {value?.label ? value?.label : placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className=" z-50 absolute  mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-4 pr-4 ${
                    active ? "bg-gray-100 " : "text-gray-900"
                  }`
                }
                value={person}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {person.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center  text-green-200"></span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
