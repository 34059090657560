import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rangeFilterChange } from "../../../store/filter/filter.slice";
import { getRangeValueByName } from "../../../store/filter/selectors";
import _ from "lodash";

export const RangeFilter = ({ onChange, name, options }) => {
  const dispatch = useDispatch();
  const value = useSelector(getRangeValueByName(name));
  const sliderData = options.map((option) => option.label).join(",");
  const [data, setData] = useState({
    value1: options.find((option) => option.value === value[0]).label,
    value2: options.find((option) => option.value === value[1]).label,
  });
  useEffect(() => {
    if (value) {
      setData({
        value1: options.find((option) => option.value === value[0]).label,
        value2: options.find((option) => option.value === value[1]).label,
      });
    }
  }, [value, options]);

  const onChangeRange = (evt) => {
    handleChange([
      options.find((option) => option.label === evt.detail.values[0]).value,
      options.find((option) => option.label === evt.detail.values[1]).value,
    ]);
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    slider?.addEventListener("change", onChangeRange);

    return () => {
      slider?.removeEventListener("change", onChangeRange);
    };
  }, []);
  const handleChange = _.debounce((target) => {
    if (target[0] !== target[1]) {
      dispatch(rangeFilterChange({ name, value: target }));
      setData({
        value1: options.find((option) => option.value === target[0]).label,
        value2: options.find((option) => option.value === target[1]).label,
      });
      onChange({ name, value: target });
    } else {
      setData((prev) => prev);
    }
  }, 500);
  return (
    <div className=" font-rubik ">
      <div className=" flex justify-between items-center text-[12px] font-normal leading-5 text-text-gray my-2">
        <span>{options[0].label}</span>
        <span>{options.at(-1).label}</span>
      </div>

      <tc-range-slider
        ref={sliderRef}
        data={sliderData}
        slider-height="8px"
        slider-width="100%"
        theme="circle"
        css-links="tcrs-themes.min.css"
        value1={data.value1}
        value2={data.value2}
        pointer-border="2px solid white"
        pointer-bg="#2FAC66"
        slider-bg-fill="#2FAC66"
        pointer-bg-focus="#1E683C"
        pointer-border-focus="2px solid white"
        pointer-bg-hover="#1E683C"
        pointer-border-hover="2px solid white"
        pointer-shadow-hover="0 0 0px white"
        pointer-shadow-focus="0 0 0px white"
        pointer-shadow="0 0 0px blue"
      ></tc-range-slider>
      <div className=" flex justify-between items-center gap-4 my-4">
        <div className=" flex justify-center items-center h-[50px] border rounded-md w-full p-2 lg:min-w-[100px]">
          <span className=" inline-block  leading-[18px] font-normal text-[14px]  text-text-primary font-rubik text-center lg:whitespace-nowrap">
            {data.value1}
          </span>
        </div>
        {"-"}
        <div className=" flex justify-center items-center h-[50px] border rounded-md w-full p-2 lg:min-w-[100px]">
          <span className=" inline-block  leading-[18px] font-normal text-[14px]  text-text-primary font-rubik text-center lg:whitespace-nowrap">
            {data.value2}
          </span>
        </div>
      </div>
    </div>
  );
};
