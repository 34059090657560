import React, { useEffect, useRef, useState } from "react";
import { close } from "../../../images";
import { useModal } from "../../../hooks/useModal";

export const PhotoUpload = ({ onFormChange, dayImages = [] }) => {
  const [images, setImages] = useState(dayImages);
  const { openModal } = useModal();

  useEffect(() => {
    if (images.length > 0 && onFormChange) onFormChange({ images });
  }, [images, onFormChange]);

  const handleImageChange = (e) => {
    // Получаем файлы из input
    const fileList = Array.from(e.target.files);

    // Превращаем их в массив объектов с URL
    const mappedFiles = fileList.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    // Добавляем их к текущему состоянию images
    setImages((prevImages) => [...prevImages, ...mappedFiles]);
  };

  const handleRemove = (imageToRemove) => {
    // Фильтруем изображение из состояния
    setImages((prevImages) =>
      prevImages.filter((image) => image.preview !== imageToRemove.preview)
    );

    // Очищаем URL, чтобы освободить память
    URL.revokeObjectURL(imageToRemove.preview);
  };

  const fileInputRef = useRef();

  // Функция для клика по скрытому input
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="photo_upload">
      <h2 className="font-medium text-xl">Фото и видео материалы</h2>
      <div className=" border-2  rounded-md border-dashed border-spacing-4 mt-3 py-8 px-7">
        <div className="flex gap-2 flex-wrap">
          {images.map((image, index) => (
            <div key={index} className="relative">
              <img
                className="w-32 h-32 object-fill cursor-pointer"
                src={image.preview || image.media_item}
                alt="Preview"
                width="100"
                onClick={!onFormChange && (() => openModal(images, index))}
              />
              {onFormChange && (
                <img
                  className="cursor-pointer absolute top-3 right-3 brightness-0 invert"
                  onClick={() => handleRemove(image)}
                  src={close}
                  alt="remove"
                />
              )}
            </div>
          ))}
          {onFormChange && (
            <button
              className="w-32 h-32 bg-[#EBEBEB] text-[72px] text-text-gray"
              type="button"
              onClick={handleFileInputClick}
            >
              +
            </button>
          )}
          <input
            ref={fileInputRef}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
      </div>
    </div>
  );
};
