import { downloadIconType } from "utils/downloadIcon";

const docs = [
  {
    title: "Алгоритм многодневное выездное мероприятие (бюджет, доп)",
    file: "/assets/city/1.pdf",
  },
  {
    title: "Алгоритм многодневное выездное мероприятие (внебюджет, доп)",
    file: "/assets/city/2.pdf",
  },
  {
    title: "Алгоритм однодневное выездное мероприятие (бюджет, воспитание)",
    file: "/assets/city/3.pdf",
  },
  {
    title: "Алгоритм однодневное выездное мероприятие (бюджет, доп.)",
    file: "/assets/city/4.pdf",
  },
  {
    title: "Алгоритм однодневное выездное мероприятие (внебюджет, доп)",
    file: "/assets/city/5.pdf",
  },
  {
    title: "Многодневное мероприятие за городом",
    file: "/assets/city/6.pdf",
  },
];

const docs2 = [
  {
    title: "Алгоритм подготовки",
    file: "/assets/natural/1.pdf",
  },
  {
    title: "Инструкция",
    file: "/assets/natural/2.pdf",
  },
  {
    title: "Общие требования к организации мероприятий в природной среде",
    file: "/assets/natural/3.pdf",
  },
];

export const Docs = () => {
  return (
    <section className="support-docs ">
      <div className="text-xl font-semibold">Мероприятия в городской среде</div>
      {docs.map(({ file, title }) => (
        <div className="flex gap-2 mt-2">
          {" "}
          <div className="w-6 h-6 min-w-[24px]">
            <img
              src={downloadIconType(file)}
              alt="Документы"
              className=" w-full h-full"
            />
          </div>
          <a href={file} target="_blank" rel="noopener noreferrer" download>
            {title}
          </a>
        </div>
      ))}

      <div className="text-xl font-semibold mt-5">
        Мероприятия в природной среде
      </div>
      {docs2.map(({ file, title }) => (
        <div className="flex gap-2 mt-2">
          {" "}
          <div className="w-6 h-6 min-w-[24px]">
            <img
              src={downloadIconType(file)}
              alt="Документы"
              className=" w-full h-full"
            />
          </div>
          <a href={file} target="_blank" rel="noopener noreferrer" download>
            {title}
          </a>
        </div>
      ))}
      {/* <MethodicalDocumentsList page={2} /> */}
    </section>
  );
};
