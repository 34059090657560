import React, { useState } from "react";
import {
  calendarGreenIcon,
  classGreenIcon,
  emailGreenIcon,
  nameGreenIcon,
  parentsGreenIcon,
  phoneGreenIcon,
  placeGreenIcon,
} from "../../../images";
import { ParentsBackdrop, WaysList } from "../../../components";
import { SelectField } from "../../../components/ui/form/selectField";
import { InputField } from "../../../components/ui/form/inputField";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const sortOptions = [
  { label: "Название", value: "title" },
  { label: "Продолжительность", value: "duration" },
];

const topFields = [
  { title: "ФИО:", icon: nameGreenIcon },
  { title: "Дата рождения:", icon: calendarGreenIcon },
  { title: "Класс:", icon: classGreenIcon },

  { title: "Телефон:", icon: phoneGreenIcon },
  { title: "Почта:", icon: emailGreenIcon },
  { title: "Адрес:", icon: placeGreenIcon },

  // { title: "Родители:", icon: parentsGreenIcon },
  { title: "Мама:", icon: parentsGreenIcon },
  { title: "Папа:", icon: parentsGreenIcon },
];
export const StudentPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const media =
    "https://imageup.ru/img59/4509582/snimok-ekrana-2023-09-04-v-143551.jpg";
  return (
    <div className=" fle flex-col justify-between items-center relative">
      <div className=" w-full flex flex-col md:flex-row justify-between items-start gap-6">
        <div className=" md:w-3/12">
          <img src={media} alt="Фотография профиля" />
        </div>
        <div className=" w-9/12">
          <div className=" flex items-center gap-2">
            <span className=" text-[32px] text-text-primary leading-[42px]">
              Верст:
            </span>
            <span className=" text-[32px]  leading-[42px] font-bold text-text-primary-green">
              23 000
            </span>
          </div>
          <div className="top-info grid grid-cols-1 md:grid-cols-2  justify-stretch flex-wrap pb-4">
            {topFields.map(({ title, icon }) => (
              <div key={title} className="flex gap-4 items-center mt-5 w-full ">
                {icon ? (
                  <div className="icon flex justify-center items-center w-10 h-10 ">
                    <img className="w-6 h-6" src={icon} alt="" />
                  </div>
                ) : null}
                <div className="top-info__content flex flex-col gap-2 w-full text-[14px] leading-[21px]">
                  <h3 className="text-text-gray  whitespace-nowrap">{title}</h3>
                  <p
                    onClick={() => {
                      if (title === "Мама:" || title === "Папа:") {
                        setIsOpen((prev) => !prev);
                      }
                    }}
                    className={
                      title === "Мама:" || title === "Папа:"
                        ? " underline cursor-pointer"
                        : ""
                    }
                  >
                    Test
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row justify-between items-center mt-20">
        <div className=" mb-4 md:mb-0 w-full">
          <h2 className=" text-text-primary text-[20px] leading-7">
            Пройденные маршруты
          </h2>
        </div>
        <div className=" w-full">
          <form className="flex flex-col  md:flex-row  md:justify-start items-center gap-2  md:w-full mb-6 w-full">
            <div className=" flex  items-center w-full gap-2  justify-between md:justify-start">
              {" "}
              <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                Сортировать по:{" "}
              </span>
              <SelectField
                styles={" max-w-[220px]  "}
                name={"sortParam"}
                options={sortOptions}
                defaultValue={sortOptions[0]}
                onChange={() => {}}
                placeholder={"сортировать по"}
                //   value={data.sortParam}
              />
            </div>
            <InputField
              placeholder="Поиск"
              styles={" w-full !pr-10"}
              //   onChange={handleChange}
              //   value={data.search}
              name="search"
            >
              <div className=" absolute  !text-black  end-2  top-[16px] ">
                <MagnifyingGlassIcon className=" h-6 w-6 " />
              </div>
            </InputField>
          </form>
        </div>
      </div>
      <div className="">
        <div className="flex justify-center flex-col items-center my-6 gap-2 ">
          <WaysList />
        </div>
      </div>
      {isOpen && <ParentsBackdrop setIsOpen={setIsOpen} />}
    </div>
  );
};
