import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  About as about,
  Location,
  Audience,
  Type,
  Nature,
  Distance,
  Age,
  Duration,
  Season,
  Author,
  MapWeb,
  Essintial,
} from "../../../images";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/scss/pagination";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

import {
  useGetRouteByIdQuery,
  useGetRoutesContentItemsQuery,
  useGetRoutesNaturalEnvironmentsByIdQuery,
} from "../../../store/RTKApi/routes";
import { transformDuration } from "../../../utils/transformDuration";
import { transformNaturalEnvoronment } from "../../../utils/transformNaturalEnvironment";
import { transformDistance } from "../../../utils/transformField";
import { getFilterValuesByNameAndArrayIds } from "../../../store/filter";
import { WayObject, WayTags } from "../../../components";
import { useResize } from "../../../hooks/useResize";

export const About = () => {
  const { isScreenMd } = useResize();
  const { id, region, duration, type, length } = useOutletContext();
  const { data: way } = useGetRouteByIdQuery(id);
  const { data: naturalEnvironment } = useGetRoutesNaturalEnvironmentsByIdQuery(
    way?.natural_environment
  );

  const seasons = useSelector(
    getFilterValuesByNameAndArrayIds("season", way?.season)
  );
  const ageCategories = useSelector(
    getFilterValuesByNameAndArrayIds("age_category", way?.age_category)
  );
  const targetAudience = useSelector(
    getFilterValuesByNameAndArrayIds("target_audience", way?.target_audience)
  );

  const topFields = [
    { title: "Регион:", icon: Location, value: region?.title },
    {
      title: "Сезонность:",
      icon: Season,
      value: seasons,
    },
    {
      title: "Продолжительность маршрута:",
      icon: Duration,
      value: transformDuration(duration),
    },
    {
      title: "Протяженность маршрута:",
      icon: Distance,
      value: transformDistance(length),
    },
    { title: "Возраст обучающихся:", icon: Age, value: ageCategories },
    { title: "Целевая аудитория:", icon: Audience, value: targetAudience },
    { title: "Вид маршрута:", icon: Type, value: type?.title },
    {
      title: "Природная среда:",
      icon: Nature,
      value: transformNaturalEnvoronment(naturalEnvironment),
    },
  ];
  const bottomFields = [
    { title: "Автор маршрута:", icon: Author, value: way?.author },
    {
      title: "Объекты на маршруте:",
      icon: MapWeb,
      value: way.attractions?.map((el, idx, arr) => (
        <WayObject key={el} id={el} lastId={arr.at(-1)} />
      )),
    },
    {
      title: "Ключевые направления:",
      icon: Essintial,
      value: way.tags?.map((el) => <WayTags key={el} id={el} />),
    },
  ];
  const { data: medias, isLoading } = useGetRoutesContentItemsQuery({
    to_route: id,
  });
  return (
    <section className="way-about">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-5/12">
          {!isLoading && (
            <Swiper
              slidesPerView={1}
              // freeMode={true}
              autoplay={{ delay: 5000 }}
              pagination={{
                clickable: true,
              }}
              effect={"fade"}
              loop={true}
              spaceBetween={10}
              centeredSlides={true}
              height={isScreenMd ? 450 : 250}
              modules={[Pagination, EffectFade, Autoplay]}
            >
              {medias.map((el) => (
                <SwiperSlide key={el.id}>
                  <div
                    className={
                      "w-full " + (isScreenMd ? "h-[450px]" : "h-[250px]")
                    }
                  >
                    <img
                      className="w-full h-full object-cover"
                      src={el.media_item}
                      alt="img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div className="w-full md:w-7/12">
          <div className="top-info flex flex-col md:flex-row justify-stretch flex-wrap border-b-gray-400 border-b-2 border-dashed pb-4">
            {topFields.map(({ title, icon, value }) =>
              !!value ? (
                <div
                  key={title}
                  className="flex gap-4 items-start mt-5 w-full md:w-2/5"
                >
                  <div className="icon flex justify-center items-center rounded-full min-w-[40px] min-h-[40px]  bg-text-primary-green">
                    <img className="w-6 h-6" src={icon} alt="" />
                  </div>
                  <div className="top-info__content flex flex-col">
                    <h3 className="text-gray-400">{title}</h3>
                    <p>{value}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>
          <div className="bottom-info flex flex-col grow pb-4">
            {bottomFields.map(({ title, icon, value }) =>
              !!value ? (
                <div key={title} className="flex gap-4 items-start  mt-5">
                  <div className="icon flex justify-center items-center rounded-full min-w-[40px] min-h-[40px]  bg-[#fdf0dcc4]">
                    <img className="w-6 h-6" src={icon} alt="" />
                  </div>
                  <div className="bottom-info__content grow flex flex-col">
                    <h3 className="text-gray-400">{title}</h3>
                    <p className=" mt-2 text-ellipsis word ">{value}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
