import React from "react";
import { EventItem } from "./eventItem";

export const EventsViewList = ({ data, club }) => {
  return (
    <div className=" flex md:flex-col gap-[20px] md:gap-0  overflow-x-scroll md:overflow-y-scroll md:w-7/12 rounded-md border px-5 py-6">
      {data?.length ? (
        data.map((item) => <EventItem event={item} key={item.id} club={club} />)
      ) : (
        <h2 className="text-2xl text-gray-400">
          События на текущую дату отсутствуют{" "}
        </h2>
      )}
    </div>
  );
};
