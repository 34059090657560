import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Cookies from "js-cookie";
import { getAccessToken } from "@entities/auth";

export const newsletterApi = createApi({
  reducerPath: "newsletterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/newsletter/",
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=`
      );
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    addSubscription: build.mutation({
      query: (body) => ({
        url: "subscribe/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  //Добавить подписку по почте
  useAddSubscriptionMutation,
} = newsletterApi;
