import { createSlice } from "@reduxjs/toolkit";
import { localStorageService } from "../../service/localstorage.service";

const waySlice = createSlice({
  name: "ways",
  initialState: {
    isLoading: true,
    ways: [],
    medias: [],
    favorites: localStorageService.getFavorites() || [],
  },

  reducers: {
    wayRequested: (state) => {
      state.isLoading = true;
    },
    wayReсieved: (state, action) => {
      const data = action.payload;

      const favoriteUpdate = data.map((el) =>
        state.favorites.includes(el.id) ? { ...el, favorite: true } : el
      );
      state.ways = favoriteUpdate;
      state.isLoading = false;
    },
    favoritesAdded: (state, action) => {
      state.favorites = [...state.favorites, action.payload];
      state.ways = state.ways.map((el) =>
        el.id === action.payload ? { ...el, favorite: true } : el
      );
    },
    favoritesRemoved: (state, action) => {
      state.favorites = state.favorites.filter((f) => f !== action.payload);
      state.ways = state.ways.map((el) =>
        el.id === action.payload ? { ...el, favorite: false } : el
      );
    },
  },
});

const { actions, reducer: wayReducer } = waySlice;

export const { wayRequested, wayReсieved, favoritesAdded, favoritesRemoved } =
  actions;

export default wayReducer;
