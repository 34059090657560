import { Outlet, useParams } from "react-router-dom";
import {
  Slider as MainSlider,
  EventsPanel,
  MultimediaPanel,
  PopularRoutes,
  SearchRouteMain,
  Container,
  NotesPanel,
  Courusel,
} from "../components";
import React from "react";

export const Main = () => {
  const { id } = useParams();

  return id ? (
    <Outlet />
  ) : (
    <>
      <div className="max-w-full mx-auto lg:max-w-[1920px]">
        <MainSlider />

        <Container>
          <SearchRouteMain />
          <Courusel />
          <PopularRoutes />
          <EventsPanel />
          {/* <NotesPanel /> */}
        </Container>

        <MultimediaPanel title={"Фотогалерея"} type={"img"} />
        <MultimediaPanel title={"Видеоматериалы"} type={"video"} />
      </div>
    </>
  );
};

export default Main;
