import React from "react";
import { YaMap } from "../../../components";
import { useOutletContext } from "react-router-dom";
import { TimeLineItem } from "./timeLineItem";
import { MediaModel } from "../../../components/ui/mediaModal";

export const Maps = () => {
  const { points, type } = useOutletContext();
  return (
    <div className=" flex flex-col-reverse md:flex-row justify-between w-full mb-6 gap-6 px-2">
      <div className=" w-full md:w-4/12">
        {points.map((point, index) => (
          <TimeLineItem key={index} index={index} id={point} />
        ))}
      </div>
      <div className="w-full md:w-8/12">
        <YaMap points={points} type={type?.id} />
      </div>
      {/* <MediaModel /> */}
    </div>
  );
};
