import { pdfIcon1 } from "images";

export const DocItem = ({ doc }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={doc.document}
      target="_blank"
      rel="noopener noreferrer"
      download
      className="flex w-[30%] p-4 gap-4 border border-[#D9D9D9] rounded-md"
    >
      <img className="w-10 h-10" src={pdfIcon1} alt="icon" />
      <span>{doc.title}</span>{" "}
      <span className="text-gray-400">({doc.size})</span>
    </a>
  );
};
