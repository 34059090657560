import {styled, Switch} from "@mui/material";

export const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 53,
    height: 30,
    padding: 4,
    '& .MuiSwitch-switchBase': {
        width: 53,
        height: 29,
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',

        '&.Mui-checked': {
            '& + .MuiSwitch-track': {

                backgroundColor: '#7E7E7E',
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: '#2FAC66',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {

        },
        '&.Mui-disabled .MuiSwitch-thumb': {

        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: '22px',
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        backgroundColor: '#7E7E7E',
        width: 29,
        height: 29,
        transform: 'translateX(-10px)',
    },
}));