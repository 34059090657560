import React, { useEffect, useState } from "react";
import { calendarIcon } from "../images";
import { useParams } from "react-router-dom";
import { CustomButton } from "../components/ui";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { Breadcrumbs, Container, DocItem } from "../components";
import {
  useGetRecordsByIdQuery,
  useGetRecordsMediaQuery,
} from "../store/RTKApi/events";
import { useSelector } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const EventPage = () => {
  const { id } = useParams();

  const [transformedData, setTransformedData] = useState([]);

  const {
    data: singleRecordData,
    isLoading: singleRecordLoading,
    isSuccess: singeRecordSuccess,
  } = useGetRecordsByIdQuery(id);

  const types = useSelector((state) => state.event.types);
  // const medias = useSelector((state) => state.event.medias) || [];
  const { data: medias } = useGetRecordsMediaQuery();
  const mediasToRecord = medias.filter((m) => m.to_record == id);

  useEffect(() => {
    if (singeRecordSuccess) {
      const typeIndex = types.findIndex(
        (el) => singleRecordData.type === el.id
      );
      const mediaIndex = medias.findIndex(
        (el) => singleRecordData.type === el.id
      );
      const transformedRecord = {
        ...singleRecordData,
        type: types[typeIndex].title,
        media_item: medias[mediaIndex]?.media_item,
        description: medias[mediaIndex]?.description,
        documents: [
          // { title: "Документ", document: "https://doc.docx", size: "25 кб" },
          // { title: "Документ 2", document: "https://doc1.docx", size: "45 кб" },
        ],
      };
      setTransformedData(transformedRecord);
    }
  }, [singleRecordLoading]);

  const formattedDate = transformedData.to_date
    ? format(transformedData.to_date, "d MMMM yyyy", {
        locale: ru,
      })
    : "";
  console.log(transformedData);
  return (
    <>
      <section className="mt-20   py-8 mb-0 ">
        <Container className="">
          <div className="bg-[#EFF1F0] p-8">
            <Breadcrumbs />
            <div className="flex flex-col h-full gap-6">
              <div className="flex-grow flex flex-col justify-center gap-1">
                <div className=" flex flex-col md:flex-row justify-between items-center">
                  <h2 className=" text-[24px]  text-black md:text-[32px] font-bold leading-[42px] text-center md:text-left">
                    {transformedData?.title}
                  </h2>
                  {/* <CustomButton
                    label={"Оставить заявку"}
                    styles={"  bg-text-primary-green px-4"}
                  /> */}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex justify-start items-center gap-2 text-text-primary text-[14px]">
                  <img src={calendarIcon} alt="Календарь" className=" " />
                  <span className="">{formattedDate}</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className=" mb-0 font-rubik">
        <Container>
          {transformedData.documents?.length > 0 && (
            <div className="documents">
              <h2 className="text-2xl">Документы</h2>

              <div className="flex flex-wrap gap-5 my-5">
                {transformedData.documents.map((doc) => (
                  <DocItem key={doc.document} doc={doc} />
                ))}
              </div>
            </div>
          )}
          <div className=" flex flex-col justify-between gap-6 ck-content">
            <div
              dangerouslySetInnerHTML={{ __html: transformedData?.text }}
              className="event-page font-rubik font-normal text-[16px] text-text-primary leading-[28px] "
            ></div>

            <div className="flex flex-col md:flex-row  items-start gap-6">
              <p className="font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
                {transformedData?.description}
              </p>
            </div>

            {mediasToRecord.length > 0 && (
              <section className="notes relative">
                <div className=" ">
                  <div className=" flex whitespace-nowrap items-center">
                    <span className="text-text-primary text-2xl  font-medium font-rubik ">
                      Фото и видеоматериалы
                    </span>
                    <div className="hidden  md:flex items-center ml-2 pt-1">
                      <button className="swiper-multimedia-button-prev">
                        <ChevronLeftIcon className=" h-6 w-6" />
                      </button>
                      <button className="swiper-multimedia-button-next rotate-180">
                        <ChevronLeftIcon className=" h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>

                {/*  мультимедиа слайдер, не будет работать переделать !
              {mediasToRecord && (
                <MultimediaSlider multyArray={mediasToRecord} />
              )} */}
              </section>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default EventPage;
