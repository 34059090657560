import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Rtk_utils from '../../utils/rtk_utils';


export const geocodeApi = createApi({
   reducerPath: "geocodeApi",
   baseQuery: fetchBaseQuery({
      baseUrl: "https://geocode-maps.yandex.ru/1.x/"
   }),
   endpoints: (build) => ({
      getGeocode: build.query({
         query: (arg) => {
            const queryString = Rtk_utils.ClearQueryString(arg)
            return {
               url: encodeURI(queryString),
               method: 'GET'
            }
         },
         transformResponse: ({ response }) => {
            return response?.GeoObjectCollection?.featureMember[0]?.GeoObject?.Point?.pos?.split(" ").reverse();

         }
      }),

   })
})

export const { useGetGeocodeQuery } = geocodeApi;
