import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./event.sass";
import { BrowserRouter } from "react-router-dom";
import ScrollWrapper from "./hoc/scrollTopWrapper";
import { ThemeProvider, createTheme } from "@mui/material";
import { green } from "@mui/material/colors";
import "./styles/ck-editor.css";
import "./styles/variables.scss";

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}

    <ThemeProvider theme={theme}>
      <ScrollWrapper>
        <App />
      </ScrollWrapper>
    </ThemeProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);
