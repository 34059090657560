import React from "react";

import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { mvcp } from "../../images";
import "./courusel.css";
import { useResize } from "../../hooks/useResize";
import { useGetMainRoutesOriginQuery } from "../../store/RTKApi/routes";
import { NavLink } from "react-router-dom";
import { sortByOrder } from "../../utils/sorting";
import { repeatArray } from "../../utils/repeat";

export const Courusel = () => {
  const { isScreenSm, isScreenLg } = useResize();

  const { data: images } = useGetMainRoutesOriginQuery();

  return (
    images &&
    images.length > 0 && (
      <section className="overflow-hidden  w-full  ">
        <Swiper
          speed={15000}
          spaceBetween={30}
          loop={true}
          centeredSlides={true}
          autoplay={{
            enabled: true,
            delay: 1,
            disableOnInteraction: true,
            reverseDirection: true,
          }}
          slidesPerView={isScreenSm ? (isScreenLg ? 4 : 3) : 2}
          modules={[Autoplay]}
        >
          {repeatArray
            .call(images, 5)

            .map((img, idx) => (
              <SwiperSlide
                key={img + idx}
                className="flex  h-[100px] max-w-fit justify-center  items-center  courusel"
              >
                <NavLink
                  className="h-full flex justify-center w-full "
                  to={"/ways"}
                  state={{ id: img.id }}
                >
                  <img
                    key={img.id}
                    className="object-scale-down h-full w-full"
                    src={img.image}
                    alt=""
                  />
                </NavLink>
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
    )
  );
};
