import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginWhiteIcon, loginIcon, logoutIconWhite } from "../../images";
import { logout } from "../../store/auth/auth.slice";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { getAccessToken } from "@entities/auth";

export const AuthIcon = React.memo(({ setOpen }) => {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  const token = useSelector((state) => state.auth.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    if (token) {
      dispatch(logout());
      if (setOpen) setOpen(false);
      setIsToolTipOpen(false);
      navigate("/");
    }
  };
  return token ? (
    <div
      className="relative flex justify-center items-center gap-2"
      onMouseEnter={() => setIsToolTipOpen(true)}
      onMouseLeave={() => setIsToolTipOpen(false)}
    >
      <Link
        to={"/account"}
        className=" md:hidden text-white w-6 h-6 rounded-full  bg-text-primary-green flex justify-center items-center "
        onClick={() => {
          if (setOpen) setOpen(false);
        }}
      >
        A
      </Link>
      <div
        className=" hidden text-white w-6 h-6 rounded-full  bg-text-primary-green md:flex justify-center items-center cursor-default "
        onClick={() => {
          if (setOpen) setOpen(false);
        }}
      >
        A
      </div>

      <button
        className=" w-6 h-6 flex justify-center items-center pt-1 md:hidden "
        onClick={handleClick}
      >
        <img
          src={logoutIconWhite}
          alt=""
          className=" w-6 h-6  pb-1 text-white"
        />
      </button>

      <div
        className={
          " absolute top-full right-0  bg-inherit pt-[27px] lg:pt-[28px]   z-10 transition-all duration-300 " +
          (isToolTipOpen ? " opacity-1" : "  opacity-0 invisible delay-300 ")
        }
      >
        <div className="  bg-white  w-full border-t-2 p-5 rounded-b-md border-green-500 shadow-md flex flex-col items-start  gap-5 ">
          <NavLink to={"/account"} className={" nav-link"} end>
            Профиль
          </NavLink>
          <button className={" nav-link"} onClick={handleClick}>
            Выход
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="cursor-pointer w-6 h-6 flex justify-center items-center gap-2">
      <Link to="/login">
        {" "}
        <img src={loginIcon} alt="" className=" w-6 h-6   hidden md:block " />
        <img
          src={loginWhiteIcon}
          alt=""
          className=" w-6 h-6  block md:hidden "
        />
      </Link>
    </div>
  );
});
