import { createSelector } from "@reduxjs/toolkit";

export const getTypeEventById = (type) =>
  createSelector(
    (state) => state.event.types,
    (types) =>
      types.find((t) =>
        typeof type === "number" ? t.id === type : t.title === type
      )
  );
