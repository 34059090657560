import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge } from "../ui/badge";
import { transformDuration } from "../../utils/transformDuration";
import { calendarIcon } from "../../images";
import { transformDistance } from "../../utils/transformField";
import { getDate } from "../../utils/transformDate";
import {
  useGetRouteByIdQuery,
  useLazyGetRoutesRegionsByIdQuery,
  useLazyGetRoutesTypesByIdQuery,
  useLazyGetRoutesOriginByIdQuery,
} from "../../store/RTKApi/routes";
import {
  transformOutdoorEventStatus,
  transformOutdoorEventStatusStyle,
} from "../../utils/transformOutdoorEventStatus";

export function OrganizationEventsListItem({ event }) {
  const { data: route } = useGetRouteByIdQuery(event?.route);
  const [getRoutesRegionsById, { data: region }] =
    useLazyGetRoutesRegionsByIdQuery();
  const [getRoutesTypesById, { data: type }] = useLazyGetRoutesTypesByIdQuery();
  const [getRoutesOriginById, { data: origin }] =
    useLazyGetRoutesOriginByIdQuery();

  useEffect(() => {
    if (route) {
      getRoutesRegionsById(route.region);
      getRoutesTypesById(route.type);
      getRoutesOriginById(route.origin);
    }
  }, [route]);

  return (
    <div className=" flex flex-col md:flex-col  justify-center border-b-2 py-4  border-dashed border-[#C4C4C4] md:justify-between  md:items-start h-full group w-full gap-4 md:gap-10 ">
      <div className=" w-full flex flex-col md:flex-row justify-center md:justify-between">
        <Link to={`success/${event.id}`}>
          <h3 className=" leading-[28px] font-normal text-[20px]  text-text-primary font-rubik group-hover:underline group-hover:text-text-primary-green">
            {event?.title +
              ` #${event.id} от ${new Date(event.created_at).toLocaleString(
                "ru-RU"
              )}`}
          </h3>
        </Link>
        <div className=" flex justify-center items-center">
          <Badge
            styles={
              " h-[30px] border-0 bg-text-secondary-green" +
              transformOutdoorEventStatusStyle(event.status)
            }
            textstyle={" text-white text-[12px]"}
            label={transformOutdoorEventStatus(event.status)}
          />
        </div>
      </div>
      <div className="flex flex-col  md:flex-row md:justify-between md:items-end gap-4 w-full">
        <div className=" flex flex-col lg:flex-row lg:justify-center lg:items-center gap-4">
          <div className="flex justify-start items-center gap-4">
            <img src={calendarIcon} alt="Новость" className=" " />
            <span className="">{getDate(event.date_leave_moscow)}</span>
          </div>
          {event?.educational_program?.length > 0 && route && (
            <div className=" text-text-gray text-[14px] leading-[21px]">
              <span>Программа:</span>
              <span className=" ml-2 text-text-primary">
                {event.educational_program?.join(", ")}
              </span>
            </div>
          )}
        </div>
        {route && (
          <div className="flex gap-4   flex-wrap justify-center  md:justify-start lg:flex-nowrap ">
            <Badge
              styles={" h-[30px] border-0 bg-[#EBEBEB]"}
              textstyle={" text-black text-[12px]"}
              label={origin?.title}
            />
            <Badge
              styles={" h-[30px] border-[#C4C4C4] bg-white"}
              textstyle={" text-text-secondary-green"}
              label={region?.title}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDuration(route?.duration)}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformDistance(route?.length)}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={` h-[30px] border-0`}
              label={type?.title}
              type={type?.id}
              textstyle={" text-white"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
