import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  footelMoscowSchoolLogo,
  footerTrevelSchoolLogo,
  okLogo,
  tgLogo,
  vkLogo,
  travelSchoolLogo,
  travelSchoolLogoWhite,
} from "../images";
import "../styles/footer.sass";

import { CustomButton, InputField } from "./ui";
import { Container } from "./container";
import { useAddSubscriptionMutation } from "../store/RTKApi/newsletter";
import { AuthIcon } from "./auth/authIcon";
import { useLogin } from "../hooks/useLogin";
import { useSelector } from "react-redux";

export const Footer = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth);
  const [data, setData] = useState({ email: "" });
  const [isSubscribeOK, setIsSubscribeOk] = useState(null);
  const [isSubscribeError, setIsSuscribeError] = useState(null);
  const [addSubscriptionTrigger, { isSuccess, isError }] =
    useAddSubscriptionMutation();
  const { isOpen: isLoginForm, setIsOpen: setLoginForm } = useLogin();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.email) {
      await addSubscriptionTrigger({ ...data })
        .unwrap()
        .then(() => {
          setIsSubscribeOk(true);
          setData({ email: "" });
        })
        .catch((error) => {
          setIsSuscribeError(
            error?.data?.email[0] ||
              "Введите правильный адрес электронной почты"
          );
        });
    }
  };
  const handleChange = (target) => {
    setData({ ...data, [target.name]: target.value });
  };
  return (
    <footer className=" bg-gradient-to-r from-text-primary-green to-[#0F5E2F]  w-full">
      <Container>
        <div className="text-white text-sm leading-[18px] font-normal font-rubik pt-[30px] mb-0 pb-[10px] gap-4 grid   grid-cols-2 md:grid-cols-4 ">
          <div className="flex flex-col h-full">
            <div className=" flex md:flex-col items-start">
              <Link to={"https://shkolamoskva.ru/"} target="_blank">
                <img
                  src={footelMoscowSchoolLogo}
                  alt="Школа Москва"
                  className=" w-[150px] "
                ></img>
              </Link>
              <Link to={"/"}>
                {" "}
                <img
                  src={travelSchoolLogoWhite}
                  alt="Школа путешествий"
                  className="my-4 w-[150px]"
                ></img>
              </Link>
            </div>

            <address className="footer__links flex  gap-6">
              <Link to="https://vk.com/moscvp" target="_blank">
                <img
                  src={vkLogo}
                  alt="Вконтакте"
                  className="aspect-square"
                ></img>
              </Link>
              <Link to="https://t.me/moscvp" target="_blank">
                <img
                  src={tgLogo}
                  alt="Телеграм"
                  className="aspect-square "
                ></img>
              </Link>
            </address>
          </div>
          <div className=" flex justify-between gap-4 md:gap-10">
            <div className="flex flex-col ">
              <Link to="/events" className=" footer-link">
                События
              </Link>
              <Link to="/support" className=" footer-link">
                Методическая поддержка
              </Link>
              {/* <Link to="/club" className="footer-link footer-sublink">
                  <p className=" whitespace-nowrap">
                    Московский педагогический{" "}
                  </p>
                  <p className=" whitespace-nowrap">клуб путешественников</p>
                </Link>
                <Link to={`/support`} className="footer-link footer-sublink">
                  Конс
                </Link> */}
              <Link to={"/support/docs"} className=" footer-link">
                Документы
              </Link>
              <Link to={"/support/contacts"} className="footer-link ">
                Контакты
              </Link>
              {/* <Link to="/ways" className="footer-link ">
                  Библиотека маршрутов
                </Link> */}
              {/* <Link to="/notes" className="footer-link footer-sublink">
                  Путевые заметки
                </Link> */}
            </div>
          </div>
          <div className=" flex flex-col items-center md:items-start w-full gap-2 col-span-2 md:col-auto ">
            <button
              className=" block mb-5 footer-link !text-sm"
              onClick={() => {
                if (!auth) {
                  navigate("/login");
                } else {
                  navigate("/account");
                }
              }}
            >
              Войти в личный кабинет
            </button>
            <span className=" text-xs font-light text-center">
              2024 © ГБОУ ДПО МЦВП
            </span>

            <span className=" text-xs font-light text-center">
              Политика конфиденциальности
            </span>
          </div>
          <div className=" flex justify-end w-full md:col-auto md:row-auto  col-span-2  row-start-2 row-end-2">
            <div className="   w-full sm:w-1/2 md:w-full lg:max-w-[270px]  sm:flex-col items-center">
              {isSubscribeOK ? (
                <span className=" block mb-4">Спасибо за подписку!</span>
              ) : isSubscribeError ? (
                <span className=" block mb-4">{isSubscribeError}</span>
              ) : (
                <span className=" block mb-4">Подписаться на новости</span>
              )}
              <form onSubmit={handleSubmit}>
                <InputField
                  placeholder={"Ваша почта"}
                  styles={" w-full lg:min-w-[270px] sm:w-full md:w-full"}
                  onChange={handleChange}
                  value={data.email}
                  name="email"
                />
                <CustomButton
                  label={"Подписаться"}
                  styles={
                    " w-full sm:w-full lg:min-w-[270px] bg-black disabled:opacity-60"
                  }
                  disabled={isSubscribeOK}
                />
              </form>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
