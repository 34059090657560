import React, { useState } from "react";
import { CustomButton } from "../ui";
import { useLoginUserMutation } from "../../store/RTKApi/auth";
import Rtk_utils from "../../utils/rtk_utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { authCheck } from "../../store/auth/auth.slice";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const Login = (props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const [loginTrigger, { isSuccess, isLoading: loginLoading }] =
    useLoginUserMutation();

  const handleForm = async (e) => {
    e.preventDefault();
    setFormData({
      email: "",
      password: "",
    });
    await loginTrigger(formData)
      .unwrap()
      .then((data) => {
        Rtk_utils.DeleteCookie("access_token");
        Rtk_utils.DeleteCookie("refresh_token");
        Cookies.set("access_token", data.access);
        Cookies.set("refresh_token", data.refresh);
        dispatch(authCheck(data.access));
        props.setLoginForm(false);
        props.setOpen(false);
        navigate("/account");
      })
      .catch(() =>
        toast.error(`Не верный логин или пароль`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };
  const handleInput = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };
  return (
    <>
      {!props.auth && (
        <form
          ref={ref}
          onSubmit={handleForm}
          className={
            " min-w-full  flex flex-col border-t-2 border-green-500 rounded-bl-[5px] rounded-br-[5px] right-0  gap-[2px] absolute md:min-w-[250px] shadow-md md:right-0 top-full p-[15px] bg-white transition-all duration-300 overflow-hidden " +
            (props.isLoginForm
              ? " opacity-1"
              : "  opacity-0 invisible delay-500 ")
          }
          onMouseEnter={() => props.setLoginForm(true)}
        >
          {loginLoading ? (
            <div className=" flex justify-center items-center">
              <BeatLoader color="#36d7b7" />
            </div>
          ) : (
            <>
              <input
                onChange={handleInput}
                name={"email"}
                value={formData.email}
                className={
                  "h-[50px] rounded-md p-[15px]  border  my-1 leading-[18px] font-normal text-[14px]  text-text-primary font-rubik placeholder:text-text-gray outline-none"
                }
                placeholder={"Логин"}
              />
              <input
                onChange={handleInput}
                name={"password"}
                value={formData.password}
                type={"password"}
                className={
                  "h-[50px] rounded-md p-[15px]  border  my-1 leading-[18px] font-normal text-[14px]  text-text-primary font-rubik placeholder:text-text-gray outline-none"
                }
                placeholder={"Пароль"}
              />
              <CustomButton
                label={"Войти"}
                styles={
                  "!bg-[#2FAC66] text-center rounded-[5px] py-[7px] shadow-none hover:opacity-70 duration-300 ease-in-out"
                }
              />
              {/* <span
                className={
                  "text-start text-[12px] text-[#7E7E7E] cursor-pointer"
                }
              >
                Забыли пароль ?
              </span> */}
            </>
          )}
        </form>
      )}
    </>
  );
};

export default React.forwardRef(Login);
