import React, { useEffect, useState } from "react";
import { CustomButton } from "../../components/ui/form/customButton";
import { SelectField } from "../../components/ui/form/selectField";
import { InputField } from "../../components/ui/form/inputField";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { OrganizationEventsList } from "../../components/account/organizationEventsList";

import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useGetAllOutdoorsEventsQuery } from "../../store/RTKApi/outdoorEvents";

const sortOptions = [
  { label: "Дата создания", value: "created_at" },
  { label: "Дата выезда", value: "date_leave_moscow" },
];

export const OrganizationEvents = () => {
  const { pathname } = useLocation();

  const [sortOption, setsortOption] = useState(sortOptions[0]);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ page: 1, page_size: 4 });

  const { data: events } = useGetAllOutdoorsEventsQuery(data);

  const handlePaginate = (name) => {
    if (name === "page_size")
      setData((prev) => ({ ...prev, [name]: data[name] + 4 }));
  };

  const filtred = search
    ? events?.results.filter((el) =>
        el.title.toLowerCase().includes(search.toLowerCase())
      )
    : events?.results;

  return (
    <>
      {pathname.includes("create") ||
      pathname.includes("success") ||
      pathname.includes("edit") ? (
        <Outlet />
      ) : (
        <div className=" flex flex-col ">
          <div className=" flex flex-col justify-start  gap-2 mb-7">
            <NavLink to={"create"}>
              <CustomButton
                label={"Создать новое мероприятие"}
                styles={
                  " w-full sm:w-full lg:min-w-[170px] bg-text-primary-green"
                }
              />
            </NavLink>
          </div>
          <div className=" mb-4 md:mb-0 w-full">
            <h2 className=" text-text-primary text-[20px] leading-7">
              Список мероприятий
            </h2>
            <div className=" w-full">
              <form className="flex flex-col  lg:flex-row  md:justify-start items-center gap-2  md:w-full w-full">
                <div className=" flex flex-col md:flex-row  justify-center items-center w-full gap-4"></div>

                <div className=" flex flex-col md:flex-row  justify-center items-center w-full gap-4">
                  {" "}
                  <div className=" flex  items-center w-full gap-2  justify-between lg:justify-start">
                    {" "}
                    <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                      Сортировать по:{" "}
                    </span>
                    <SelectField
                      styles={" max-w-[220px]  "}
                      name={"sortParam"}
                      options={sortOptions}
                      onChange={(e) => {
                        setsortOption(e.value);
                      }}
                      placeholder={"сортировать по"}
                      value={sortOption}
                    />
                  </div>
                  <InputField
                    placeholder="Поиск"
                    styles={" w-full !pr-10"}
                    onChange={({ value }) => {
                      setSearch(value);
                    }}
                    value={search}
                    name="search"
                  >
                    <div className=" absolute  !text-black  end-2  top-[16px] ">
                      <MagnifyingGlassIcon className=" h-6 w-6 " />
                    </div>
                  </InputField>
                </div>
              </form>
            </div>
            {filtred && (
              <OrganizationEventsList
                events={filtred}
                sortOption={sortOption}
              />
            )}
            {(filtred?.length !== events?.count ||
              filtred?.length < events?.count) && (
              <CustomButton
                onClick={() => handlePaginate("page_size")}
                label={"Показать еще"}
                styles={
                  " w-full shadow-none border !text-text-gray !border-[#D9D9D9] mb-6"
                }
                textstyle={""}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
