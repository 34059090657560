import React from "react";
import { useGetRoutesTagByIdQuery } from "../../store/RTKApi/routes";
import Skeleton from "react-loading-skeleton";

export const WayTags = ({ id }) => {
  const { data: tag, isFetching } = useGetRoutesTagByIdQuery(id);

  return isFetching ? (
    <Skeleton className="inline-block w-40 mr-1" />
  ) : (
    <span className="inline-block mr-1">{tag.title}</span>
  );
};
