import React, { useState } from "react";
import { WaysFilterItem } from "./waysFilterItem";
import { CustomButton } from "../ui";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWaysFilter,
  getAllWaysFilters,
  testFilterRequest,
} from "../../store/filter";
import { filterTypes } from "../../store/filter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const WaysFilterForm = ({ isMaps }) => {
  const dispatch = useDispatch();

  const { state } = useLocation();

  const locationId = state?.id;

  const [isOpen, setIsOpen] = useState(
    Object.keys(filterTypes).map((key) => false)
  );

  const filterData = useSelector(getAllWaysFilters());

  const handleClearFilter = () => {
    dispatch(clearWaysFilter());
  };
  const handleOpen = (index) => {
    const newIsOpen = [...isOpen].map((item, ind) => {
      if (ind === index) {
        return !item;
      } else {
        return false;
      }
    });
    setIsOpen(newIsOpen);
  };
  useEffect(() => {
    if (locationId) {
      setIsOpen(
        Object.keys(filterTypes).map((key) => (key === "origin" ? true : false))
      );
      handleClearFilter();

      dispatch(
        testFilterRequest({ name: "origin", value: locationId?.toString() })
      );
    }
  }, [locationId]);
  return (
    <>
      <form
        className={
          isMaps
            ? " flex flex-row justify-start gap-8  flex-wrap w-full p-6"
            : " w-full"
        }
        onMouseLeave={() => {
          if (isMaps) handleOpen();
        }}
      >
        {Object.keys(filterTypes).map((key, index) => (
          <WaysFilterItem
            key={key}
            name={key}
            label={filterTypes[key]}
            options={filterData[key]}
            isMaps={isMaps}
            isOpen={isOpen[index]}
            handleOpen={handleOpen}
            index={index}
          />
        ))}
      </form>
      {!isMaps && (
        <CustomButton
          label={"Очистить фильтр"}
          styles={
            " shadow-none border !text-text-gray !border-[#D9D9D9]  px-2 w-full"
          }
          onClick={handleClearFilter}
        />
      )}
    </>
  );
};
