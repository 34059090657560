import {
  Box,
  Button,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";

export const FeedbackForm = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert("Форма успешно отправлена!");
    reset(); // Reset the form after submission
    onClose();
  };
  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-3xl font-rubik font-semibold">Обратная связь</h2>
      <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
        <InputLabel id="message-type-label">Тип сообщения</InputLabel>
        <Select
          labelId="message-type-label"
          label="Тип сообщения"
          id="message-type"
          {...register("messageType", { required: "Выберите тип сообщения" })}
          error={!!errors.messageType}
        >
          <MenuItem value="">Выбрать</MenuItem>
          <MenuItem value="question">Вопрос</MenuItem>
          <MenuItem value="feedback">Отзыв</MenuItem>
          <MenuItem value="complaint">Жалоба</MenuItem>
        </Select>
        {errors.messageType && (
          <Typography color="error" variant="body2">
            {errors.messageType.message}
          </Typography>
        )}
      </FormControl>

      {/* Subject */}
      <TextField
        label="Тема сообщения"
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
        {...register("subject", {
          required: "Введите тему сообщения",
          maxLength: {
            value: 50,
            message: "Тема сообщения не должна превышать 50 символов",
          },
        })}
        error={!!errors.subject}
        helperText={errors.subject?.message}
      />

      {/* Message */}
      <TextField
        label="Сообщение"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        sx={{ mb: 3 }}
        {...register("message", {
          required: "Введите описание",
          minLength: {
            value: 10,
            message: "Сообщение должно содержать не менее 10 символов",
          },
        })}
        error={!!errors.message}
        helperText={errors.message?.message}
      />

      {/* File Attachment */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Прикрепить файл
        </Typography>
        <input
          type="file"
          {...register("file")}
          style={{ display: "block", marginTop: "8px" }}
        />
      </Box>

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        className="!bg-text-primary-green !text-white"
        sx={{ py: 1.5 }}
      >
        Отправить
      </Button>
    </form>
  );
};
