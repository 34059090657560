import React from "react";
import {
  formatDateDifferent,
  getDate,
  transformDate,
} from "../../utils/transformDate";
import { calendarIcon } from "../../images";
import { EyeIcon } from "@heroicons/react/20/solid";

import { Badge, Divider } from "../ui";
import { format_duration } from "../../utils/transformDuration";
import { useResize } from "../../hooks/useResize";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTypeEventById } from "../../store/events/selectors";

export const EventItem = ({ event, club }) => {
  const size = useResize();
  const eventType = useSelector(getTypeEventById(event.type));
  return (
    <div
      className={`flex flex-col justify-between font-rubik gap-3 mb-5  md:w-full`}
    >
      {club ? (
        <>
          <Divider />
          {size.width <= 770 ? (
            <div className={"flex flex-col gap-[10px]"}>
              <div className={"flex flex-col gap-[5px]"}>
                <h2 className={"text-[21px]"}>{event?.title}</h2>
                <div className={"flex gap-[5px] items-center"}>
                  <img
                    src={calendarIcon}
                    alt="Календарь"
                    className="w-[15px] h-[16px]"
                  />
                  <span className=" flex justify-center items-center gap-2">
                    {formatDateDifferent(event?.to_date)}
                  </span>
                </div>
              </div>
              <div className={"flex gap-[10px] items-center"}>
                {/* <EyeIcon className=" w-[20px] h-[20px] fill-[#777777] opacity-50" />{" "}
                <span className={"text-[14px]"}>1000</span> */}
                <span
                  className={
                    "w-fit py-[10px] px-[14px] text-[12px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                  }
                >
                  {event?.location?.title}
                </span>
                <span
                  className={
                    "w-fit py-[10px] px-[14px] text-[12px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                  }
                >
                  {format_duration(event?.duration)}
                </span>
              </div>
              <div className={"mt-[10px]"}>
                <p className={"text-[#7E7E7E]"}>{event?.description}</p>
              </div>
            </div>
          ) : (
            <div className={"flex gap-[40px] font-rubik"}>
              <div className={"flex flex-col w-[80%]"}>
                <div>
                  <h2 className=" font-semibold  text-text-primary leading-[26px] text-[20px]">
                    {event?.title}
                  </h2>
                </div>
                <div className={"mt-[11px]"}>
                  <p className="text-text-gray text-[14px] leading-[21px]">
                    {event?.text || event?.description}
                    {/*<span className=" text-text-primary-green "> ...подробнее</span>*/}
                  </p>
                </div>
              </div>
              <div className={"flex flex-col gap-[22px] w-[20%] text-[14px]"}>
                <div className={""}>
                  {size.width <= 950 ? (
                    <div className="flex flex-col gap-[20px]">
                      {/* <div className={"flex gap-[5px]"}>
                        <EyeIcon className=" w-[20px] h-[20px] fill-[#777777] opacity-50" />{" "}
                        {1000}
                      </div> */}
                      <div className={"flex gap-[10px] items-center"}>
                        <img
                          src={calendarIcon}
                          alt="Календарь"
                          className="w-[15px] h-[16px]"
                        />
                        <span className=" flex justify-center items-center gap-2">
                          {formatDateDifferent(event?.to_date)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={`/events/${event.id}`}
                      className="flex items-center gap-[20px]"
                    >
                      {/* <div className={"flex gap-[5px]"}>
                        <EyeIcon className=" w-[20px] h-[20px] fill-[#777777] opacity-50" />{" "}
                        {1000}
                      </div> */}
                      <div className={"flex gap-[10px] items-center"}>
                        <img
                          src={calendarIcon}
                          alt="Календарь"
                          className="w-[15px] h-[16px]"
                        />
                        <span className=" flex justify-center items-center gap-2">
                          {formatDateDifferent(event?.to_date)}
                        </span>
                      </div>
                    </Link>
                  )}
                </div>
                {size.width <= 950 ? (
                  <div
                    className={
                      "flex flex-col gap-[8px] text-[12px] text-center"
                    }
                  >
                    <span
                      className={
                        "w-full py-[10px] px-[14px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                      }
                    >
                      {event?.location?.title}
                    </span>
                    <span
                      className={
                        "w-full py-[10px] px-[14px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                      }
                    >
                      {format_duration(event?.duration)}
                    </span>
                  </div>
                ) : (
                  <div
                    className={
                      "flex flex-col  gap-[8px] text-[12px] text-center"
                    }
                  >
                    <span
                      className={
                        "w-2/2 py-[10px] px-[14px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                      }
                    >
                      {event?.location?.title}
                    </span>
                    <span
                      className={
                        "w-2/2 py-[10px] px-[14px] bg-white rounded-[60px] border border-[#1E683C] text-[#1E683C]"
                      }
                    >
                      {format_duration(event?.duration)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {size.width <= 950 ? (
            <Link to={`/events/${event.id}`} className="  flex flex-col gap-2">
              <div>
                <h2 className=" font-semibold text-text-primary leading-[26px] text-[20px]">
                  {event?.title}
                </h2>
              </div>
              <div className={"flex flex-col"}>
                <div className={"flex gap-[5px] items-center"}>
                  <img src={calendarIcon} alt="Календарь" className=" " />
                  <span className=" flex justify-center items-cente gap-2">
                    {formatDateDifferent(event?.to_date)}
                  </span>
                </div>
              </div>
              <div className="flex justify-start items-center gap-2">
                {/* <div className="flex justify-center items-center gap-2">
                  <EyeIcon className=" h-6 w-6 fill-[#777777] opacity-50" />{" "}
                  {1000}
                </div> */}
                <Badge
                  styles={" h-[30px] bg-text-primary-green border-0"}
                  label={event?.category?.title || eventType.title}
                  textstyle={" text-white text-[12px]"}
                />
              </div>
            </Link>
          ) : (
            <Link to={`/events/${event.id}`}>
              <div className=" ">
                <h2 className=" font-semibold text-text-primary leading-[26px] text-[20px] first-letter:uppercase">
                  {event?.title}
                </h2>
              </div>
              <div className="flex justify-start items-center gap-2">
                <img src={calendarIcon} alt="Календарь" className=" " />
                <span className=" flex justify-center items-cente gap-2">
                  {formatDateDifferent(event?.to_date)}
                </span>
                {/* <div className="flex justify-center items-center gap-2">
                  <EyeIcon className=" h-6 w-6 fill-[#777777] opacity-50" />{" "}
                  {10001}
                </div> */}
                <Badge
                  styles={" h-[30px] bg-text-primary-green border-0"}
                  label={event?.category?.title || eventType.title}
                  textstyle={" text-white text-[12px]"}
                />
              </div>
            </Link>
          )}
          <div>
            {" "}
            <p
              className="event-content line-clamp-3 text-text-gray  text-[14px] leading-[21px]"
              dangerouslySetInnerHTML={{
                __html: event?.text || event?.description,
              }}
            >
              {/* {event?.text || event?.description} */}
              {/*<span className=" text-text-primary-green "> ...подробнее</span>*/}
            </p>
          </div>
          <div className=" w-full h-[1px] bg-[#D9D9D9] mt-4"></div>
        </>
      )}
    </div>
  );
};
