import { PencilIcon, StarIcon } from "@heroicons/react/20/solid";
import { calendarIcon } from "../../images";
import { Badge } from "../ui";
import { useGetRouteByIdQuery } from "../../store/RTKApi/routes";
import { getDate } from "../../utils/transformDate";
import { getAllNotesFilters } from "../../store/filter";
import { useSelector } from "react-redux";
import { format_duration } from "../../utils/transformDuration";
import { Link } from "react-router-dom";

export const NoteCard = ({ note, onRoute }) => {
  const {
    data: singleRouteData,
    isLoading: singleRouteLoading,
    isSuccess: singleRouteSuccess,
    isError: singleRouteError,
  } = useGetRouteByIdQuery(note?.to_route);
  // const {data: singleSchoolData, isLoading: singleSchoolLoading, isSuccess: singleSchoolSuccess, isError: singleSchoolError} = useGetAllSchoolsByIdQuery(note?.school)

  const regions = useSelector((state) => state.filter.regionsData);
  const filters = useSelector(getAllNotesFilters());
  const { type } = filters;
  let transformedNoteCard = {};
  if (singleRouteSuccess) {
    const typeIndex = type.findIndex((el) => singleRouteData?.type === el.id);
    const regionIndex = regions.findIndex(
      (el) => singleRouteData?.region === el.id
    );
    transformedNoteCard = {
      route: { ...singleRouteData },
      type: type[typeIndex]?.title,
      region: regions[regionIndex]?.title,
      note: note,
    };
  }

  // transformedNoteCard.note.id

  return (
    <>
      {!onRoute ? (
        <div className=" group p-4 w-full border-2 border-dashed border-text-primary-green hover:border-solid font-rubik transition duration-1000">
          <div className="flex justify-between items-start gap-4">
            <Link
              to={"/notes/" + note.id}
              className=" group-hover:text-text-primary-green text-xl transition duration-200"
            >
              Заметка маршрута “{transformedNoteCard?.route?.title}”
            </Link>
            <div className="flex justify-center gap-1 text-black text-[16px] items-center">
              <span>
                {transformedNoteCard?.note?.data?.stars > 5
                  ? 5
                  : transformedNoteCard?.note?.data?.stars}
                /5
              </span>{" "}
              {<StarIcon className=" h-6 w-6 fill-[#E99B26]" />}
            </div>
          </div>
          <div className=" text-[14px] leading-[21px] font-semibold font-rubik my-4">
            <div className=" flex justify-between text-text-gray  ">
              <div className="flex justify-start items-center gap-2">
                <img src={calendarIcon} alt="Новость" className=" " />
                <span className="">{getDate(new Date(Date.now()))}</span>
              </div>
            </div>
            <div className="flex justify-start items-start gap-2 text-text-gray text-[14px] leading-[21px] font-semibold font-rubik mt-1 md:items-center">
              <PencilIcon className=" h-4 w-4 fill-text-gray opacity-80" />
              <span>Автор:</span>
              <span className=" whitespace-normal">
                {transformedNoteCard?.route?.author}
              </span>
            </div>
          </div>
          <div className="flex gap-2 items-center flex-wrap my-4">
            <Badge
              styles={" h-[30px] border-[#C4C4C4] bg-white"}
              textstyle={" text-text-secondary-green"}
              label={transformedNoteCard?.region}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={format_duration(transformedNoteCard?.route?.duration)}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={" h-[30px]  border-[#55BFFA]"}
              label={transformedNoteCard?.route?.length + " км"}
              textstyle={" text-[#55BFFA]"}
            />
            <Badge
              styles={" h-[30px] bg-[#6CAB4E] border-0"}
              label={transformedNoteCard?.type}
              textstyle={" text-white"}
            />
          </div>
          <p className="text-text-gray text-[14px] leading-[21px]">
            {transformedNoteCard?.note?.data.content}
            {/*<span className=" text-text-primary-green "> ...подробнее</span>*/}
          </p>
        </div>
      ) : (
        <div className=" group p-4 w-full border-2 border-dashed border-text-primary-green hover:border-solid font-rubik transition duration-1000">
          <div className="flex justify-between items-start gap-4">
            <Link
              to={"/notes/" + note.id}
              className=" group-hover:text-text-primary-green text-xl transition duration-200"
            >
              Заметка маршрута “{transformedNoteCard?.route?.title}”
            </Link>
            <div className="flex justify-center gap-1 text-black text-[16px] items-center">
              <span>
                {transformedNoteCard?.note?.data?.stars > 5
                  ? 5
                  : transformedNoteCard?.note?.data?.stars}
                /5
              </span>{" "}
              {<StarIcon className=" h-6 w-6 fill-[#E99B26]" />}
            </div>
          </div>
          <p className="text-text-gray text-[14px] leading-[21px]">
            {transformedNoteCard?.note?.data.content}
            {/*<span className=" text-text-primary-green "> ...подробнее</span>*/}
          </p>
          <div className="flex flex-col-reverse items-start md:flex-row md:justify-between justify-between">
            {" "}
            <div className=" text-[14px] leading-[21px] font-semibold font-rubik my-4">
              <div className=" flex justify-between text-text-gray  ">
                <div className="flex justify-start items-center gap-2">
                  <img src={calendarIcon} alt="Новость" className=" " />
                  <span className="">{getDate(new Date(Date.now()))}</span>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center flex-wrap my-4">
              <Badge
                styles={" h-[30px] border-[#C4C4C4] bg-white"}
                textstyle={" text-text-secondary-green"}
                label={transformedNoteCard?.region}
              />
              <Badge
                styles={" h-[30px]  border-[#55BFFA]"}
                label={format_duration(transformedNoteCard?.route?.duration)}
                textstyle={" text-[#55BFFA]"}
              />
              <Badge
                styles={" h-[30px]  border-[#55BFFA]"}
                label={transformedNoteCard?.route?.length + " км"}
                textstyle={" text-[#55BFFA]"}
              />
              <Badge
                styles={" h-[30px] bg-[#6CAB4E] border-0"}
                label={transformedNoteCard?.type}
                textstyle={" text-white"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
