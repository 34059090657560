import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, FreeMode } from "swiper/modules";
import { MultimediaCrop } from "./multimediaCrop";
import { useResize } from "../../hooks/useResize";
import _ from "lodash";

export function MultimediaSlider({ type, data }) {
  const { isScreenMd, isScreen2Xl } = useResize();
  const cropSize = isScreen2Xl ? 16 : isScreenMd ? 12 : 4;

  const slides = _.chunk(data, cropSize);

  return (
    <div className=" my-8  ">
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation]}
        className="mySwiper"
        loop={true}
        spaceBetween={30}
        centeredSlides={true}
        navigation={{
          nextEl: ".swiper-multimedia-button-next",
          prevEl: ".swiper-multimedia-button-prev",
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <MultimediaCrop
              data={data}
              type={type}
              slide={slide}
              page={index}
              size={cropSize}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
