import React from "react";

export const Divider = ({ width = 1, style }) => {
  return (
    <div
      style={style}
      className={`w-full my-5 h-[${width}px] bg-[#DBDBDB]`}
    ></div>
  );
};
