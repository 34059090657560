import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetOutDoorEventsDocsQuery,
  useGetOutDoorEventsFormByIdQuery,
  usePatchStatusOutDoorEventMutation,
} from "../../../store/RTKApi/outdoorEvents";
import { calendarOutdoor } from "../../../images";
import { locationOutdoor } from "../../../images";
import { messageOutdoor } from "../../../images";
import { phoneOutdoor } from "../../../images";
import { pdfRed } from "../../../images";
import { CustomButton, Divider } from "../../ui";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const status = [
  { label: "Планируется", value: "PL" },
  { label: "Подтверждено", value: "CF" },
  { label: "На выезде", value: "AC" },
  { label: "Завершено", value: "FN" },
  { label: "Отменено", value: "CN" },
];

const EventOrganizationSuccessForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isPollingActive, setIsPollingActive] = useState(false);
  const [docsDataState, setDocsDataState] = useState(null);
  const { data: formData, isSuccess: successData } =
    useGetOutDoorEventsFormByIdQuery(id);

  const [patchStatus, { isLoading: statusLoading }] =
    usePatchStatusOutDoorEventMutation();
  const { data: docsData, isSuccess: docsSuccess } =
    useGetOutDoorEventsDocsQuery(formData?.id, {
      pollingInterval: isPollingActive ? 5000 : 0,

      // Отключаем polling, когда isPollingActive === false
    });

  useEffect(() => {
    if (docsSuccess) {
      setIsPollingActive(false); // Останавливаем polling после успешного запроса
    }
  }, [docsSuccess]);

  const [statusState, setStatusState] = useState(status[0]);

  const handleStatus = (e) => {
    setStatusState(e.target.value);
    patchStatus({
      id: formData.id,
      body: {
        status: e.target.value.value,
      },
    })
      .unwrap()
      .then((data) => {
        toast.success("Статус обновлен");

        if (
          data.status !== "CF" &&
          data.status !== "FN" &&
          data.status !== "AC"
        ) {
          setDocsDataState(null); // Сбрасываем данные о документах
        } else {
          setIsPollingActive(true); // Если статус "Подтверждено", запрашиваем заново
        }
      });
  };
  useEffect(() => {
    if (successData) {
      if (formData.status) {
        setStatusState(status.find((el) => el.value === formData.status));
      }
    }
  }, [successData]);

  useEffect(() => {
    setDocsDataState(docsData);
    if (docsData) {
      setIsPollingActive(false);
    }
  }, [docsData]);

  console.log(docsDataState);

  return (
    <div className={"flex flex-col gap-[10px] text-[14px] font-rubik"}>
      {formData && (
        <>
          <div className={"flex flex-col gap-[24px] "}>
            <div className={"flex justify-between items-center"}>
              <h2 className={"text-[24px] "}>
                {formData.route ? (
                  <Link
                    className="text-text-primary-green underline"
                    to={`/ways/${formData.route}`}
                  >
                    {formData.title}
                  </Link>
                ) : (
                  formData.title
                )}
              </h2>
              <div className={"flex gap-[5px]"}>
                {/*<span className={'text-gray-400'}>Статус:</span>*/}
                {/*<span*/}
                {/*    className={''}>{status.filter(stat => stat.label === formData.status)[0].value}*/}
                {/*</span>*/}
                <Select
                  onChange={handleStatus}
                  name="status"
                  value={statusState}
                  sx={{ width: 200, maxWidth: 400, borderRadius: "5px" }}
                  disabled={statusLoading}
                >
                  {status.map((el) => (
                    <MenuItem key={el.value} value={el}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={"flex gap-[66px] items-center"}>
              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}></div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Дата выезда:</span>
                  <span>
                    {new Date(formData.date_leave_moscow).toLocaleDateString()}
                  </span>
                </div>
              </div>

              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}>
                  <img src={calendarOutdoor} />
                </div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Дата возврещния:</span>
                  <span>
                    {new Date(formData.date_return_moscow).toLocaleDateString()}
                  </span>
                </div>
              </div>

              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}>
                  <img src={locationOutdoor} />
                </div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Пункт назначения:</span>
                  <span>{formData.destination_address}</span>
                </div>
              </div>
            </div>
          </div>
          <Divider />

          <div className={"flex gap-[66px] items-center"}>
            <div className={"flex flex-col"}>
              <div className={"flex gap-[11px] items-center"}>
                <span className={"text-gray-400"}>Количество:</span>
              </div>
              <div>
                <span>{formData.students_amount}</span>
              </div>
            </div>

            {/*<div className={'flex flex-col'}>*/}
            {/*    <div className={'flex gap-[11px] items-center'}>*/}
            {/*        <span className={'text-gray-400'}>Группа / класс:</span>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <span>10А класс, 9В класс, группа 34 </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>

          <Divider />
          <div className={"flex flex-col gap-[24px]"}>
            <div className={"flex justify-between items-center"}>
              <span className={"text-gray-400"}>
                Ответсвенные сопровождающих:
              </span>
            </div>
            <div className={"flex gap-[20px]"}>
              {formData.accompany.map((el) => {
                return (
                  <div className={"flex gap-[66px] items-center"}>
                    <div className={"flex flex-col gap-[10px]"}>
                      <h2 className={"text-[16px]"}>{el.full_name}</h2>
                      <div className={"flex gap-[10px]"}>
                        <img src={phoneOutdoor} />
                        <span>{el.contact_number}</span>
                      </div>
                      <div className={"flex gap-[10px]"}>
                        <img src={messageOutdoor} />
                        <span>{el.email}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <Divider />
            <div className={"flex flex-col gap-[24px] "}>
              <div className={"flex items-center gap-[66px]"}>
                <div className={"flex gap-[10px] items-center"}>
                  <span className={"text-gray-400"}>Автобус</span>
                  <span className={""}>
                    {formData.bus_ordered ? "Да" : "Нет"}
                  </span>
                </div>
                <div className={"flex gap-[10px] items-center"}>
                  <span className={"text-gray-400"}>Заказ питания</span>
                  <span className={""}>
                    {formData.food_ordered ? "Да" : "Нет"}
                  </span>
                </div>
              </div>
              <div
                className={
                  "flex mt-[20px] gap-8 mb-[20px] items-center relative"
                }
              >
                <CustomButton
                  styles={
                    "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  }
                  onClick={() => navigate(`../addNote/${formData.id}`)}
                >
                  Написать заметку
                </CustomButton>
                <a
                  className={"w-[100%]  max-w-[181px]"}
                  href={docsDataState?.documents}
                  target={"_self"}
                >
                  <Button
                    type="button"
                    disabled={!docsDataState?.documents}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "items-center",
                      background: "transparent",
                      width: "100%",
                      maxWidth: 181,
                      padding: "14px 10px",
                      boxShadow: "none",
                      border: "1px solid black",
                      borderRadius: "5px",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "transparent", // Цвет фона при наведении
                        color: "black", // Цвет текста при наведении
                      },
                    }}
                    variant="contained"
                  >
                    <img src={pdfRed} />
                    {!docsDataState?.documents ? "Загрузка" : "Скачать"}
                  </Button>
                </a>
                <span
                  className={
                    "text-[20px] text-red-500 absolute bottom-0 translate-y-[25px]"
                  }
                >
                  {!docsDataState?.documents
                    ? "Требуется статус подтверждения!"
                    : null}
                </span>

                <CustomButton
                  styles={
                    "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  }
                  onClick={() => navigate(`../edit/${formData.id}`)}
                  disabled={statusState !== status[0]}
                >
                  Редактировать
                </CustomButton>

                <CustomButton
                  onClick={() => navigate("../")}
                  styles={
                    "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  }
                  type={"button"}
                >
                  {"Отменить"}
                </CustomButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventOrganizationSuccessForm;
