import React, { memo, useState } from "react";
import { CustomButton } from "../ui";
import { paginate } from "../../utils/paginate";
import { useSelector } from "react-redux";
import { isLoadingNotes } from "../../store/notes";

import { Link } from "react-router-dom";
import { EventBlockItem } from "./eventBlockItem";

export const EventsBlockList = memo(({ items }) => {
  const isLoading = useSelector(isLoadingNotes);
  const [page, setPage] = useState(1);

  const sortedItems = [...items].sort(
    (a, b) => new Date(b.to_date) - new Date(a.to_date)
  );

  const onClick = () => {
    setPage((prev) => prev + 1);
  };

  const notesCrop = paginate(sortedItems, page, 6);

  if (!isLoading) return "...loading";
  return sortedItems?.length > 0 ? (
    <>
      <div className={" grid  gap-8 grid-cols-1  md:grid-cols-3"}>
        {notesCrop.map((note) => (
          <Link
            to={`/events/${note.id}`}
            className=" basis-1/3   group"
            key={note.id}
          >
            <EventBlockItem note={note} />
          </Link>
        ))}
      </div>
      {notesCrop.length !== sortedItems.length && (
        <CustomButton
          onClick={onClick}
          label={"Показать еще"}
          styles={
            " w-full shadow-none border !text-text-gray !border-[#D9D9D9]"
          }
          textstyle={""}
        />
      )}
    </>
  ) : (
    "Нет событий"
  );
});
