import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CustomButton } from "../../ui";

export const DaysPopup = React.memo(
  ({ count, handleAdd, onClose, hasDays }) => {
    const [value, setValue] = useState(1);
    const days = useMemo(() => {
      return Array.from({ length: count }, (_, i) => i + 1);
    }, [count]);

    useEffect(() => {
      setValue(days.filter((day) => !hasDays.includes(day))[0]);
    }, [hasDays]);
    return (
      <div className="fixed z-10 top-1/2 left-1/2 p-5 bg-white shadow-md border rounded-md -translate-y-1/2 -translate-x-1/2">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Выберите день</InputLabel>
          <Select
            className="min-w-[265px]"
            value={value}
            onChange={({ target }) => setValue(Number(target.value))}
            label="Выберите день"
            defaultValue={1}
          >
            {days
              .filter((el) => !hasDays.includes(el))
              .map((el, idx) => (
                <MenuItem value={el}>{el}</MenuItem>
              ))}
          </Select>

          <CustomButton
            type="button"
            styles={
              " w-full max-w-[265px] min-w-[50px] bg-text-primary-green hover:bg-text-secondary-green hover:!text-white mt-3"
            }
            onClick={() => {
              handleAdd(value);
              setValue(
                (prev) =>
                  hasDays.sort().filter((_) => !hasDays.includes(prev))[0]
              );
            }}
          >
            {" "}
            Выбарть
          </CustomButton>
          <CustomButton
            type="button"
            styles={
              " w-full max-w-[265px] min-w-[50px] bg-[#EBEBEB] !text-[#151515] hover:bg-text-secondary-green hover:!text-white"
            }
            onClick={onClose}
          >
            {" "}
            Отмена
          </CustomButton>
        </FormControl>
      </div>
    );
  }
);
