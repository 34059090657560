import { useLockBodyScroll, useLi } from "@uidotdev/usehooks";
import { forwardRef, useEffect } from "react";

export const Modal = forwardRef(({ onClose, children }, ref) => {
  useLockBodyScroll();

  // const handleClickOutside = (e) => {
  //   // Close modal when clicking on the background (outside the modal content)
  //   if (ref.current && !ref.current.contains(e.target)) {
  //     onClose();
  //   }
  // };

  useEffect(() => {
    const handleTapEsc = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleTapEsc);

    return () => document.removeEventListener("keydown", handleTapEsc);
  }, []);

  return (
    <div className="fixed top-0 left-0 bg-black z-50 py-28 bg-opacity-60 w-screen h-screen flex justify-center items-stretch overflow-y-scroll">
      <div
        ref={ref}
        className="bg-white  shadow-md p-6 relative w-[90%] md:w-[66%] rounded-md h-fit"
      >
        {children}
        <button
          onClick={onClose}
          className="absolute right-3 top-3 leading-none text-2xl"
        >
          ×
        </button>
      </div>
    </div>
  );
});
