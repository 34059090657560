import React, { useContext, createContext, useState, useEffect } from "react";

export const ModalContext = createContext();

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal должен использоваться внутри ModalProvider");
  }
  return context;
}

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMedia, setModalMedia] = useState("");
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [index, setIndex] = useState(null);

  // Функция для открытия модального окна
  const openModal = (data, index, type) => {
    setIsModalOpen(true);

    setData(data);
    setIndex(index);
    setType(type);
    if (type !== "video") {
      setModalMedia(data[index]?.media_item);
    } else {
      setModalMedia(data[index]);
    }
  };

  useEffect(() => {
    if (index !== null) {
      if (type === "img") {
        setModalMedia(data[index]?.media_item);
      } else {
        setModalMedia(data[index]);
      }
    }
  }, [index, type]);

  // Функция для закрытия модального окна
  const closeModal = () => {
    setIsModalOpen(false);
    setModalMedia("");
    setData([]);
    setIndex(null);
    // Сбросить страницу на начальное значение
  };

  // Обработка нажатия клавиш
  const handleTap = (e) => {
    if (e.key === "ArrowRight") {
      // Проверка, является ли текущий элемент последним в массиве

      // Просто увеличиваем индекс
      setIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === "ArrowLeft") {
      setIndex((prevIndex) =>
        prevIndex !== 0 ? prevIndex - 1 : data.length - 1
      );
    }
  };

  // Запрос данных при изменении страницы

  // Обновление данных при получении новых и сброс индекса

  // Добавление и удаление обработчика событий клавиатуры
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isModalOpen) {
        // Проверяем, открыто ли модальное окно перед вызовом handleTap
        handleTap(e);
      }
    };

    // Теперь подписываемся на событие независимо от состояния `data` или `index`
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        modalMedia,
        openModal,
        closeModal,
        type,
        handleTap,
        data, // Предполагается, что это ваш метод для управления слайдером
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
