import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useResize } from "../../hooks/useResize";

import { useModal } from "../../hooks/useModal";
import { IconButton } from "@mui/material";
import { Forward } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  maxWidth: "50vw",
  maxHeight: "80vh",

  bgcolor: "background.paper",
  borderRadius: "5px",
  overflow: "hidden",
  boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.15)",
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",

  bgcolor: "background.paper",
  borderRadius: "5px",
  overflow: "hidden",
  boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.15)",
};

const hostings = {
  1: "vk",
  2: "rutube",
};

export function MediaModel() {
  const { isScreenMd, isScreen2Xl, isScreenLg, isScreenSm } = useResize();
  const { isModalOpen, modalMedia, closeModal, handleTap, type } = useModal();

  const dynamicStyle = React.useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: isScreenMd ? "100%" : "90vw",
      height: isScreen2Xl
        ? "80vh"
        : isScreenLg
        ? "70vh"
        : isScreenMd
        ? "60vh"
        : "50vh",
      maxWidth: "50vw",
      maxHeight: "80vh",
      bgcolor: "background.paper",
      borderRadius: "5px",
      overflow: "hidden",
      boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.15)",
    }),
    [isScreenMd, isScreen2Xl, isScreenLg, isScreenSm]
  );
  const mediaUrl =
    modalMedia.hosting === 1
      ? modalMedia?.url?.split("-")[1].split("_")
      : modalMedia?.url?.split("video/").pop().split("/")[0];

  return (
    <div className={`relative ${isModalOpen ? "block" : "hidden"}`}>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={isScreenMd ? style : style2}>
          {type === "img" ? (
            <>
              {" "}
              <img
                draggable={false}
                className="w-full h-full object-fill"
                src={modalMedia}
                alt="media_item"
              />
              <div
                className={
                  "flex justify-between absolute bottom-2 right-2 left-2"
                }
              >
                <IconButton
                  aria-label="left"
                  onClick={() => handleTap({ key: "ArrowLeft" })}
                  className="absolute left-0 p-2 rounded-full !bg-gray-400 !bg-opacity-80 rotate-180 z-[2000] "
                >
                  <Forward />
                </IconButton>

                <IconButton
                  aria-label="right"
                  onClick={() => handleTap({ key: "ArrowRight" })}
                  className="absolute right-0 p-2 rounded-full !bg-gray-400 !bg-opacity-80 z-[2000]"
                >
                  <Forward />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              {mediaUrl &&
                (hostings[modalMedia.hosting] === "rutube" ? (
                  <iframe
                    className="w-full h-full"
                    src={`https://rutube.ru/play/embed/${mediaUrl}`}
                    frameBorder="0"
                    allow="clipboard-write; autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                ) : (
                  <iframe
                    src={`https://vk.com/video_ext.php?oid=-${mediaUrl[0]}&id=${mediaUrl[1]}&hash=d7c57c64c989f7ab`}
                    frameborder="0"
                    className="w-full h-full"
                    allowfullscreen="1"
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                  ></iframe>
                ))}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
