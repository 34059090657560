import React, { useEffect } from "react";
import { CustomButton, InputField, SelectField } from "../ui";
import { findIcon, filterIcon, mapLink, placeMarkerIcon } from "../../images";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export const SearchForm = ({
  setSearchData,
  searchData,
  setIsOpenFilter,
  all,
  count,
}) => {
  const handleChange = (target) => {
    setSearchData((prev) => ({ ...prev, [target.name]: target.value }));
  };
  const mainSearch = useLocation().state;
  const storeRegionsData = useSelector((state) => state.filter.regionsData);
  const regionsSelect = storeRegionsData.map((element) => {
    return {
      label: element.title,
      value: element.id,
    };
  });

  const regions = mainSearch?.regions?.value;
  const search = mainSearch?.search;

  useEffect(() => {
    setSearchData((prev) => ({
      ...prev,
      regions,
      search,
    }));
  }, [regions, search]);
  return (
    <div className=" flex flex-col">
      <div className="">
        <div className=" w-full flex justify-between md:hidden gap-4 my-4">
          <CustomButton
            styles={
              " w-full flex justify-between items-center md:w-[50px] min-w-[50px] border border-text-primary-green"
            }
            onClick={() => setIsOpenFilter((prev) => !prev)}
          >
            <div className=" flex justify-center items-center p-2 gap-4">
              <img src={filterIcon} alt="Фильтр" />
              <span className=" font-rubik text-[14px] text-text-primary leading-5 font-medium">
                Фильтр
              </span>
            </div>
            <div className=" flex justify-center items-center p-2">
              <ChevronDownIcon className=" w-4 h-5 fill-black" />
            </div>
          </CustomButton>
          <CustomButton styles={" w-[50px] min-w-[50px] bg-text-primary-green"}>
            <div className=" flex justify-center items-center">
              <img src={findIcon} alt="Поиск" />
            </div>
          </CustomButton>
        </div>
        <form className="   md:flex justify-start items-center gap-2 ">
          <div className=" flex flex-col md:flex-row w-full gap-4 mb-4 ">
            <SelectField
              styles={" min-w-[220px] h-[50px] my-1"}
              name={"regions"}
              onChange={handleChange}
              options={regionsSelect}
              placeholder={"Город"}
              value={searchData.regions}
            >
              <img
                src={placeMarkerIcon}
                alt="Город"
                className=" inline-block absolute top-3 left-2"
              />
            </SelectField>
            <InputField
              placeholder="Поиск маршрута"
              styles={" w-full"}
              onChange={handleChange}
              value={searchData.search}
              name="search"
            >
              <div className=" absolute  !text-black  end-2  top-[16px] ">
                <MagnifyingGlassIcon className=" h-6 w-6 " />
              </div>
            </InputField>
          </div>
        </form>
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className=" flex  justify-between md:justify-start w-full  items-center gap-12">
            <div className=" flex justify-center items-center gap-2">
              <span className="text-[#878787] text-sm leading-[24px] font-normal">
                Показано
              </span>
              <span className="text-black text-sm leading-[24px] font-normal">
                {all}/{count}
              </span>
            </div>
            <Link to="/map" className=" flex justify-center items-center gap-2">
              <img src={mapLink} alt="Город" className=" " />{" "}
              <span className="text-[#168743] text-sm leading-[24px] font-normal whitespace-nowrap">
                На карте
              </span>
            </Link>
          </div>

          {/* <div className=" flex justify-around md:justify-center w-full gap-2 items-center">
                        <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                          Сортировать по:{" "}
                        </span>
                        <SelectField
                          styles={"  md:min-w-[220px] "}
                          name={"sortParam"}
                          options={sortOptions}
                          defaultValue={sortOptions[0]}
                          onChange={handleChange}
                          placeholder={"сортировать по"}
                          value={data.sortParam}
                        />
                      </div> */}
        </div>
      </div>
    </div>
  );
};
