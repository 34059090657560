import {
  changedWaysFilter,
  clearedWaysFilter,
  filterCategories,
  filterOutDoor,
  filterRecieved,
  filterRegionAction,
  filterReqested,
  filterRequest,
  filterSearchAction,
  getRegionsData,
} from "./filter.slice";
import { filterService } from "../../service";

export const changeWaysFilter = (target) => (dispatch) => {
  dispatch(changedWaysFilter(target));
};

export const getOutDoorFilter = (target) => (dispatch) => {
  dispatch(filterOutDoor(target));
};

export const testFilterRequest = (target) => (dispatch) => {
  dispatch(filterRequest(target));
};

export const setRegionsData = (regions) => (dispatch) => {
  dispatch(getRegionsData(regions));
};

export const getFilterRegionAction = (id) => (dispatch) => {
  dispatch(filterRegionAction(id));
};

export const getSearchString = (string) => (dispatch) => {
  dispatch(filterSearchAction(string));
};

export const clearWaysFilter = () => (dispatch) => {
  dispatch(clearedWaysFilter());
};

export const filterCategoriesAction = (data) => (dispatch) => {
  dispatch(filterCategories(data));
};

export const fetchFiltredData = () => async (dispatch) => {
  dispatch(filterReqested());
  const requests = [
    { season: filterService.getSeasons },
    { target_audience: filterService.getAudience },
    //06.12 Правки
    // { age_category: filterService.getAges },
    { type: filterService.getTypes },
    //06.12 Правки
    // { tags: filterService.getTags },
    { natural_environment: filterService.getNaturals },
    { origin: filterService.getOrigin },
    { longest_distance: filterService.getLongestDistance },
    { longest_duration: filterService.getLongestDuration },
  ];

  try {
    const filtredData = {};
    await Promise.allSettled(
      requests.map(async (req) => {
        const [key, func] = Object.entries(req)[0];
        const value = await func();
        filtredData[key] = value;
      })
    );

    dispatch(filterRecieved({ ...filtredData }));
  } catch (error) {
    throw new Error(error.message);
  }
};
