import { createSlice } from "@reduxjs/toolkit";

import { deleteTokens } from "@entities/auth";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: "",
  },

  reducers: {
    authCheck: (state, action) => {
      state.auth = action.payload;
    },
    logout: (state, action) => {
      state.auth = "";
      deleteTokens();
    },
  },
});

const { actions, reducer: authReducer } = authSlice;

export const { authCheck, logout } = actions;

export default authReducer;
