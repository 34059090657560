import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFiltredData,
  filterCategoriesAction,
  getFilterIsLoading,
  setRegionsData,
} from "../store/filter";
import HashLoader from "react-spinners/HashLoader";
import { useGetRoutesRegionsQuery } from "../store/RTKApi/routes";
import { getAllMediasAction, getAllTypesAction } from "../store/events";
import {
  useGetRecordsMediaQuery,
  useGetRecordsTypesQuery,
} from "../store/RTKApi/events";
import { useGetContentQuery, useGetSliderQuery } from "../store/RTKApi/medias";
import { useGetClubEventsCategoriesQuery } from "../store/RTKApi/club";
import { authCheck } from "../store/auth/auth.slice";
import {
  useGetAllGroupsQuery,
  useGetAllSchoolsQuery,
  useGetAllStudentQuery,
  useGetAllTeachersQuery,
} from "../store/RTKApi/schools";
import {
  setGroups,
  setSchool,
  setStudents,
  setTeachers,
} from "../store/schools";
import { getAccessToken } from "@entities/auth";
import { setError } from "store/schools/schools.slice";

const AppLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const credentials = useSelector((state) => state.auth.auth);
  const isAuthenticated = !!credentials;

  const dispatch = useDispatch();

  const { isSuccess: contentMainLoading } = useGetContentQuery({});
  const { isSuccess: sliderLoading } = useGetSliderQuery({});
  const filterLoading = useSelector(getFilterIsLoading());

  const { data: regionData, isSuccess: regionsLoading } =
    useGetRoutesRegionsQuery();

  const { data: categoriesData, isSuccess: categoriesLoading } =
    useGetClubEventsCategoriesQuery();

  const {
    data: schoolData,
    error: schoolError,

    isSuccess: schoolSuccess,
  } = useGetAllSchoolsQuery({}, { skip: !isAuthenticated });

  const {
    data: teachersData,
    isSuccess: teacherSuccess,
    error: teachersError,
  } = useGetAllTeachersQuery({}, { skip: !isAuthenticated });

  const {
    data: groupsData,
    error: groupsError,
    isSuccess: groupsSuccess,
  } = useGetAllGroupsQuery({}, { skip: !isAuthenticated });

  const {
    data: studentsData,
    error: studentsError,
    isSuccess: studentsSuccess,
    isFetching: studentsFetching,
  } = useGetAllStudentQuery(
    { page: 1, page_size: 1000 },
    { skip: !isAuthenticated }
  );

  const { data: typesData, isSuccess: typesLoading } =
    useGetRecordsTypesQuery();

  const { data: evtMediaData, isSuccess: evtMediaLoading } =
    useGetRecordsMediaQuery();

  useEffect(() => {
    dispatch(fetchFiltredData());
  }, [dispatch]);

  useEffect(() => {
    if (regionData) {
      dispatch(setRegionsData(regionData));
    }
  }, [dispatch, regionData]);

  useEffect(() => {
    dispatch(authCheck(getAccessToken()));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && schoolSuccess && schoolData) {
      dispatch(setSchool(schoolData));
    } else if (schoolError) {
      dispatch(setError({ name: "school", value: schoolError }));
    }
  }, [dispatch, isAuthenticated, schoolSuccess, schoolData, schoolError]);

  useEffect(() => {
    if (isAuthenticated && groupsSuccess && groupsData) {
      dispatch(setGroups(groupsData));
    } else if (groupsError) {
      dispatch(setError({ name: "groups", value: groupsError }));
    }
  }, [dispatch, isAuthenticated, groupsSuccess, groupsData, groupsError]);

  useEffect(() => {
    if (isAuthenticated && teacherSuccess && teachersData) {
      dispatch(setTeachers(teachersData));
    } else if (teachersError) {
      dispatch(setError({ name: "teachers", value: teachersError }));
    }
  }, [dispatch, isAuthenticated, teacherSuccess, teachersData, teachersError]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (studentsSuccess || !studentsFetching) &&
      studentsData
    ) {
      dispatch(setStudents(studentsData));
    } else if (studentsError) {
      dispatch(setError({ name: "students", value: studentsError }));
    }
  }, [
    dispatch,
    isAuthenticated,
    studentsSuccess,
    studentsFetching,
    studentsData,
    studentsError,
  ]);

  useEffect(() => {
    if (typesData) {
      dispatch(getAllTypesAction(typesData));
    }
    if (evtMediaData) {
      dispatch(getAllMediasAction(evtMediaData));
    }
    if (categoriesData) {
      dispatch(filterCategoriesAction(categoriesData));
    }
  }, [dispatch, typesData, evtMediaData, categoriesData]);

  useEffect(() => {
    if (
      typesLoading &&
      evtMediaLoading &&
      regionsLoading &&
      contentMainLoading &&
      categoriesLoading &&
      !filterLoading &&
      sliderLoading
    ) {
      setLoading(false);
    }
  }, [
    typesLoading,
    evtMediaLoading,
    regionsLoading,
    contentMainLoading,
    categoriesLoading,
    filterLoading,
    sliderLoading,
  ]);

  return loading ? (
    <div className="flex h-screen items-center justify-center">
      <HashLoader color="#7fcfa5" size={100} />
    </div>
  ) : (
    children
  );
};

export default AppLoader;
