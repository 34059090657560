import { toast } from "react-toastify";
import { deleteTokens } from "../config/authUtils";

// Определим функцию для обработки ошибок
export const handleAuthError = (error) => {
  deleteTokens();
  if (!error) {
    toast.error("Произошла неизвестная ошибка", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return;
  }

  // Проверяем, что это ошибка с кодом статуса от API
  if ("status" in error) {
    const status = error.status;

    switch (status) {
      case 400:
        toast.error(
          "Некорректные данные. Проверьте введенные имя пользователя и пароль.",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        break;
      case 401:
        toast.error("Неверные учетные данные. Проверьте логин и пароль.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        break;
      case 500:
        toast.error("Серверная ошибка. Попробуйте позже.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        break;
      default:
        toast.error(`Ошибка: ${status}. Попробуйте позже.`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        break;
    }
  } else {
    toast.error(
      "Не удалось подключиться к серверу. Проверьте интернет-соединение.",
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  }
};
