import {getAllMedias, getAllRecords, getAllTypes, getCalendarState} from "./events.slice";


export const getAllRecordsAction = (data) => (dispatch) => {
    dispatch(getAllRecords(data))
}

export const getAllTypesAction = (data) => (dispatch) => {
    dispatch(getAllTypes(data))
}

export const getAllMediasAction = (data) => (dispatch) => {
    dispatch(getAllMedias(data))
}

export const getCalendarStateAction = (data) => (dispatch) => {
    dispatch(getCalendarState(data))
}

