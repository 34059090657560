export const transformColor = (type) => {
  switch (type) {
    case 1:
      return "#2FAC66";
    case 2:
      return "#2FAC00";
    case 3:
      return "#E44B26";
    case 4:
      return "#14D1A4";
    case 5:
      return "#FF5733"; // Добавляем новый цвет для типа 5
    case 6:
      return "#7856E5"; // Добавляем новый цвет для типа 6
    case 7:
      return "#0099FF"; // Добавляем новый цвет для типа 7
    default:
      return "type";
  }
};

export const transformDuration = (distance) => {
  const lastOne = Number(distance.toString().slice(-1));
  if (distance > 4 && distance < 15) {
    return distance + " часов";
  }
  if (lastOne === 1) return distance + " час";
  if ([2, 3, 4].indexOf(lastOne) >= 0) return distance + " часа";
  return distance + " часа";
};

export const transformDistance = (duration) => {
  return duration ? duration + " км" : duration;
};

export const transformLocation = (location) => {
  switch (location) {
    case "Moscow":
      return "Москва";
    case "SPB":
      return "Санкт-Петербург";
    default:
      return location;
  }
};

export const transformOrganization = (org) => {
  switch (org) {
    case "MDUC":
      return "МДЮЦ ЭКТ";
    case "RGO":
      return "РГО";
    default:
      return org;
  }
};

export const transformPath = (...params) => params.join("/") + "/";
