const transformHours = (distance) => {
  const lastOne = Number(distance.toString().slice(-1));
  if (distance <= 0) return "";
  if (distance > 4 && distance <= 20) {
    return distance + " часов";
  }
  if (lastOne === 1) return distance + " час";
  if ([2, 3, 4].indexOf(lastOne) >= 0) return distance + " часа";
  return distance + " часа";
};

const transformDays = (distance) => {
  const lastOne = Number(distance.toString().slice(-1));
  if (distance <= 0) return "";
  if (distance >= 5 && distance <= 20) {
    return distance + " дней";
  }
  if (lastOne === 1) return distance + " день";
  if ([2, 3, 4].indexOf(lastOne) >= 0) return distance + " дня";
  return distance + " дня";
};

export const transformDuration = (seconds) => {
  seconds = Number(seconds);

  const oneHourSeconds = 3600;

  if (seconds < oneHourSeconds) {
    return "менее часа";
  } else {
    const days = Math.floor(seconds / (24 * oneHourSeconds));
    const remainingSeconds = seconds % (24 * oneHourSeconds);
    const hours = Math.floor(remainingSeconds / oneHourSeconds);

    if (days === 0) {
      return transformHours(hours);
    } else {
      return transformDays(days) + (hours ? " " + transformHours(hours) : "");
    }
  }
};

export function format_duration(timestamp) {
  const seconds = Math.floor(timestamp % 60);
  const minutes = Math.floor((timestamp / 60) % 60);
  const hours = Math.floor((timestamp / 3600) % 24);
  const days = Math.floor(timestamp / 86400);

  if (days > 0) {
    const formatted_days = `${days} ${get_plural(days, "день", "дня", "дней")}`;
    const formatted_hours = `${hours} ${get_plural(
      hours,
      "час",
      "часа",
      "часов"
    )}`;
    return `${formatted_days}${formatted_hours}`;
  } else if (hours > 0) {
    return `1 час`;
  } else {
    return `менее часа`;
  }
}

function get_plural(number, one, few, many) {
  if (number % 10 === 1 && number % 100 !== 11) {
    return one;
  } else if (
    [2, 3, 4].includes(number % 10) &&
    ![12, 13, 14].includes(number % 100)
  ) {
    return few;
  } else {
    return many;
  }
}

export function convertFormat(string) {
  if (!string) {
    return "Некорректный формат";
  }

  let [number, time] = string.split(" ");

  let timeFormat;
  if (time) {
    timeFormat = "";
  } else {
    timeFormat = convertTimeString(number);
    return `${timeFormat}`;
  }

  if (number % 7 === 0) {
    let weeks = number / 7;
    if (weeks === 1) {
      return `${weeks} неделю`;
    } else if (weeks >= 2 && weeks <= 4) {
      return `${weeks} недели`;
    } else {
      return `${weeks} недель`;
    }
  } else {
    return `${number} часов`;
  }
}

function convertTimeString(timeString) {
  let [hours, minutes, seconds] = timeString.split(":");
  if (hours.startsWith("0")) {
    hours = hours.substring(1);
  }

  return `${hours} час`;
}

export function secToDays(min) {
  return min && Math.ceil(min / 60 / 60 / 24);
}
