import {
  addGroupsData,
  addOneStudent,
  addOneTeacher,
  addSchoolData,
  addStudentsData,
  addTeachersData,
  removedTeacher,
  schoolsCredentials,
  updatedTeacher,
  setError,
} from "./schools.slice";

export const setCredentials = (data) => (dispatch) => {
  dispatch(schoolsCredentials(data));
};

export const setErrorAccount = (data) => (dispatch) => {
  dispatch(setError(data));
};

export const setSchool = (data) => (dispatch) => {
  dispatch(addSchoolData(data));
};

export const setTeachers = (data) => (dispatch) => {
  dispatch(addTeachersData(data));
};

export const setOneTeacher = (datas) => async (dispatch) => {
  dispatch(addOneTeacher(datas));
};
export const removeOneTeacher = (id) => async (dispatch) => {
  dispatch(removedTeacher(id));
};
export const updateTeacher = (id, datas) => async (dispatch) => {
  dispatch(updatedTeacher({ id, teacher: datas }));
};
export const setOneStudent = (datas) => async (dispatch) => {
  dispatch(addOneStudent(datas));
};

export const setStudents = (data) => (dispatch) => {
  dispatch(addStudentsData(data));
};

export const setGroups = (data) => (dispatch) => {
  dispatch(addGroupsData(data));
};
