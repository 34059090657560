import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAllNotesFilters, notesFilterTypes } from "../../store/filter";
import { NotesFilterItem } from "./notesFilterItem";

export const NotesFilterForm = ({}) => {
  // const dispatch = useDispatch();
  const filterData = useSelector(getAllNotesFilters());
  const [isOpen, setIsOpen] = useState(
    Object.keys(filterData)?.map((key) => false)
  );

  const handleChange = (name, target) => {
    //  dispatch(changeFilter({ [name]: target }));
  };
  // const handleClearFilter = () => {
  //   dispatch(clearWaysFilter());
  // };

  //   useEffect(() => {
  //     onFilter(filterData);
  //   }, [filterData]);
  const handleOpen = (index) => {
    const newIsOpen = [...isOpen].map((item, ind) => {
      if (ind === index) {
        return !item;
      } else {
        return false;
      }
    });
    setIsOpen(newIsOpen);
  };
  return (
    <>
      <form
        className=" flex "
        onMouseLeave={() => {
          handleOpen();
        }}
      >
        <div className=" flex flex-col md:flex-row justify-center items-start gap-8">
          {Object.keys(notesFilterTypes).map((key, index) => (
            <NotesFilterItem
              key={key}
              name={key}
              label={notesFilterTypes[key]}
              options={filterData[key]}
              isOpen={isOpen[index]}
              handleOpen={handleOpen}
              index={index}
            />
          ))}
        </div>
      </form>
    </>
  );
};
