import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CustomButton, CustomCheckbox } from "../../ui";
import { useEffect, useRef, useState } from "react";
import { CustomSwitch } from "../../ui/mui/switch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetRoutesNodesByIdQuery,
  useGetRoutesNodesQuery,
  useLazyGetRouteByIdQuery,
} from "../../../store/RTKApi/routes";
import { useSelector } from "react-redux";
import { getAdvisors } from "../../../store/schools/selectors";

import {
  useAddNewOutDoorEventMutation,
  useGetOutDoorEventsFormByIdQuery,
  usePatchOutDoorEventMutation,
} from "../../../store/RTKApi/outdoorEvents";

import { Search } from "@mui/icons-material";
import { validateForm } from "./validate";
import { toast } from "react-toastify";

const initialFormState = {
  route: null,
  title: "",
  destination_address: "",
  educational_program: ["SPO", "SOO", "OOO", "NOO"],
  leader: null,
  accompany: [],
  date_return_moscow: dayjs(),
  date_leave_moscow: dayjs(),
  bus_ordered: false,
  food_ordered: false,
  students_amount: "",
};

const EventsOrganizationForm = () => {
  const [routeSwitch, setRouteSwitch] = useState(false);
  const { id: paramsId } = useParams();
  const { state, pathname } = useLocation();
  const id = state?.id;

  const duration = useRef();

  const navigate = useNavigate();

  const advisors = useSelector(getAdvisors());
  const hasEditSegment = pathname.includes("/edit");

  const [routeTrigger, { data: routeData, isSuccess: routeSuccess }] =
    useLazyGetRouteByIdQuery();

  const { data: editData, isSuccess: editSuccess } =
    useGetOutDoorEventsFormByIdQuery(id || paramsId, {
      skip: !hasEditSegment,
    });

  //TEST CONSTRUCTION

  //получаем точки маршрута
  const { data: allPointsData, isSuccess: allPointsDataSuccess } =
    useGetRoutesNodesQuery(
      { id: routeData?.points.join(",") },
      {
        skip: !routeSuccess,
      }
    );

  const [outDoorMutation] = useAddNewOutDoorEventMutation();
  const [patchEvent] = usePatchOutDoorEventMutation();

  const [formData, setFormData] = useState(initialFormState);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!routeSwitch) {
      setFormData(initialFormState);
    }
  }, [routeSwitch]);

  useEffect(() => {
    if (id || editData?.route) {
      routeTrigger(id || editData.route)
        .unwrap()
        .then((routeData) =>
          setFormData((prev) => ({
            ...prev,
            title: routeData.title,
            route: id || editData?.route,
            date_return_moscow: prev.date_leave_moscow.add(
              routeData.duration,
              "second"
            ),
          }))
        );
      setRouteSwitch(true);
    }
  }, [routeTrigger, editData]);

  useEffect(() => {
    if (allPointsDataSuccess && allPointsData) {
      setFormData({
        ...formData,
        points: allPointsData.map((point) => {
          const name = !!point.name ? point.name : point.address;
          return {
            label: name,
            value: true,
          };
        }),
        destination_address:
          allPointsData.at(-1).name || allPointsData.at(-1).address,
      });
    }
  }, [allPointsDataSuccess, allPointsData]);

  useEffect(() => {
    if (editSuccess) {
      const { accompany, leader, date_leave_moscow, date_return_moscow } =
        editData;
      setFormData({
        ...formData,
        ...editData,
        accompany: accompany.map((i) => {
          return {
            label: i.full_name,
            value: i.id,
          };
        }),
        leader: {
          label: leader?.full_name,
          value: leader?.id,
        },
        date_leave_moscow: dayjs(date_leave_moscow),
        date_return_moscow: dayjs(date_return_moscow),
        points:
          !!editData?.points && editData.route
            ? editData?.points.map((i) => {
                return {
                  value: true,
                  label: i,
                };
              })
            : [],
      });
    }
  }, [editSuccess]);

  const handleForm = async (e) => {
    e.preventDefault();
    const [errors, isValid] = validateForm(formData);
    if (!isValid) {
      setErrors(errors);
      return;
    }

    setErrors({});
    const sendData = {
      ...formData,

      leader: formData?.leader?.value,
      accompany: formData.accompany.map((i) => i.value),
      destination_address: formData.destination_address,
      points: !!formData?.points
        ? formData?.points.filter((i) => i.value !== false).map((v) => v.label)
        : [],

      date_leave_moscow: formData.date_leave_moscow.$d,
      date_return_moscow: formData.date_return_moscow.$d,
    };
    debugger;
    if (hasEditSegment) {
      await patchEvent({ id: id || paramsId, body: sendData })
        .unwrap()
        .then((res) => {
          toast.success("Выездное мероприятие обновлено");
          navigate(`/account/events/success/${res.id}`);
        });
    } else {
      await outDoorMutation(sendData)
        .unwrap()
        .then((res) => {
          toast.success("Выездное мероприятие создано");
          navigate(`/account/events/success/${res.id}`);
        });
    }
  };

  const handleFormChanger = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (routeSuccess && routeSwitch) {
      setFormData((prev) => ({
        ...prev,
        date_return_moscow: prev.date_leave_moscow.add(
          routeData?.duration || duration.current,
          "second"
        ),
      }));
    }
  }, [formData.date_leave_moscow, routeSuccess, editSuccess, routeSwitch]);
  console.log(errors);
  return (
    <>
      <form
        onSubmit={handleForm}
        className={
          "!font-rubik !text-[9px] flex flex-col gap-8 mb-[40px] max-w-screen-lg"
        }
      >
        <div className={"flex gap-4 items-center"}>
          {!hasEditSegment && (
            <FormGroup row>
              <FormControlLabel
                className={" !text-[20px] gap-4"}
                control={
                  <CustomSwitch
                    name={"routeSwitch"}
                    checked={routeSwitch}
                    onChange={() => setRouteSwitch(!routeSwitch)}
                  />
                }
                label="Выбрать готовый маршрут"
                labelPlacement="start"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px", // Размер шрифта метки
                  },
                }}
              />
            </FormGroup>
          )}

          <TextField
            onChange={(e) =>
              handleFormChanger({ name: "title", value: e.target.value })
            }
            value={formData.title}
            sx={{
              width: "100%",
              flexGrow: 1,
              borderRadius: "5px",
            }}
            disabled={routeSwitch}
            variant="outlined"
            label="Название маршрута"
            error={errors.title}
          />

          {routeSwitch && !hasEditSegment && (
            <CustomButton
              type={"button"}
              styles={
                "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap h-[56px] w-[56px]  my-0"
              }
              onClick={() => navigate("/ways")}
            >
              <Search />
            </CustomButton>
          )}
        </div>
        <div className={"flex gap-[35px] items-start justify-between"}>
          <TextField
            error={!!errors.destination_address}
            helperText={errors.destination_address}
            disabled={routeSwitch}
            value={formData?.destination_address}
            onChange={(e) =>
              handleFormChanger({
                name: "destination_address",
                value: e.target.value,
              })
            }
            sx={{
              width: "100%",
              maxWidth: 570,
              borderRadius: "5px",
            }}
            variant="outlined"
            label={formData?.destination_address ? "" : "Адрес назначения"}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <DatePicker
                // defaultValue={`${new Date()}`}
                value={formData?.date_leave_moscow}
                onChange={(e) =>
                  handleFormChanger({
                    name: "date_leave_moscow",
                    value: e,
                  })
                }
                label={"Дата выезда"}
                format="DD.MM.YYYY"
                sx={{
                  maxWidth: 170,
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData?.date_leave_moscow}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {errors.date_leave_moscow && (
                <p className="text-red-600 mt-1 text-xs">
                  {" "}
                  {errors.date_leave_moscow}
                </p>
              )}
            </div>

            <DatePicker
              // defaultValue={`${new Date()}`}
              value={formData.date_return_moscow}
              onChange={(e) =>
                handleFormChanger({
                  name: "date_return_moscow",
                  value: e,
                })
              }
              disabled={(routeSuccess || hasEditSegment) && routeSwitch}
              label={"Дата возвращения"}
              format="DD.MM.YYYY"
              sx={{
                maxWidth: 170,
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={formData?.date_return_moscow || dayjs()}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className={"flex gap-8"}>
          <TextField
            error={!!errors.students_amount}
            helperText={errors.students_amount}
            value={formData?.students_amount}
            onChange={(e) =>
              handleFormChanger({
                name: "students_amount",
                value: e.target.value,
              })
            }
            sx={{
              width: "100%",
              maxWidth: 570,
              borderRadius: "5px",
            }}
            variant="outlined"
            label="Количество учеников"
          />
        </div>
        {!!formData?.points && (
          <div className={"flex flex-col gap-[18px]"}>
            {formData?.points?.map((p, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value="end"
                  sx={{
                    marginLeft: "10px",
                    display: "flex",
                    gap: "10px",
                    "& .MuiTypography-root": {
                      color: "black", // Задает цвет текста
                      fontWeight: "bold", // Задает жирность текста
                    },
                  }}
                  control={
                    <CustomCheckbox
                      checked={p.value}
                      disabled={routeSwitch}
                      onChange={(e) =>
                        handleFormChanger({
                          name: "points",
                          value: p,
                          event: e,
                        })
                      }
                    />
                  }
                  label={p.label}
                  labelPlacement="end"
                />
              );
            })}
          </div>
        )}
        <div className={"flex gap-8"}>
          <Autocomplete
            onChange={(_, value) =>
              handleFormChanger({ name: "leader", value: value })
            }
            value={formData.leader}
            options={advisors}
            sx={{ width: "100%", maxWidth: 370, borderRadius: "5px" }}
            renderInput={(params) => (
              <TextField
                error={!!errors.leader}
                helperText={errors.leader}
                {...params}
                label="Руководитель группы:"
              />
            )}
          />
          <Autocomplete
            multiple
            onChange={(_, value) =>
              handleFormChanger({ name: "accompany", value: value })
            }
            value={formData.accompany}
            options={
              advisors.length === 0
                ? []
                : advisors.filter(
                    (option) =>
                      !formData?.accompany.some(
                        (selected) => selected?.value === option?.value
                      )
                  )
            }
            sx={{ width: "100%", maxWidth: 370, borderRadius: "5px" }}
            renderInput={(params) => (
              <TextField
                error={!!errors.accompany}
                helperText={errors.accompany}
                {...params}
                label="Ответственный сопровождающих:"
              />
            )}
          />
          {/*<Autocomplete*/}
          {/*    multiple*/}
          {/*    onChange={(_, value) =>*/}
          {/*        handleFormChanger({name: "educational_program", value: value})*/}
          {/*    }*/}
          {/*    value={formData.educational_program}*/}
          {/*    options={*/}
          {/*        edu === 0*/}
          {/*            ? []*/}
          {/*            : edu.filter(*/}
          {/*                (option) =>*/}
          {/*                    !formData?.educational_program.some(*/}
          {/*                        (selected) => selected?.value === option?.value*/}
          {/*                    )*/}
          {/*            )*/}
          {/*    }*/}
          {/*    sx={{width: "100%", maxWidth: 570, borderRadius: "5px"}}*/}
          {/*    renderInput={(params) => (*/}
          {/*        <TextField*/}
          {/*            error={!!errors.educational_program}*/}
          {/*            helperText={errors.educational_program}*/}
          {/*            {...params}*/}
          {/*            label="Образовательная программа:"*/}
          {/*        />*/}
          {/*    )}*/}
          {/*/>*/}
        </div>
        <div className={"flex gap-[70px] items-center"}>
          <div className={"flex gap-[10px] items-center text-[14px]"}>
            <label className={"text-[#7E7E7E]"}>Автобусный: </label>
            <FormControlLabel
              sx={{
                margin: 0,
              }}
              label="Да"
              labelPlacement="end" // Помещает метку слева от чекбокса
              control={
                <CustomCheckbox
                  checked={formData.bus_ordered === true}
                  onChange={() =>
                    handleFormChanger({
                      name: "bus_ordered",
                      value: true,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              sx={{
                margin: 0,
              }}
              label="Нет"
              labelPlacement="end" // Помещает метку слева от чекбокса
              control={
                <CustomCheckbox
                  checked={formData.bus_ordered === false}
                  onChange={() =>
                    handleFormChanger({
                      name: "bus_ordered",
                      value: false,
                    })
                  }
                />
              }
            />
          </div>
          <div className={"flex gap-[10px] items-center text-[14px]"}>
            <label className={"text-[#7E7E7E]"}>Заказ питания: </label>
            <FormControlLabel
              sx={{
                margin: 0,
              }}
              label="Да"
              labelPlacement="end" // Помещает метку слева от чекбокса
              control={
                <CustomCheckbox
                  checked={formData.food_ordered === true}
                  onChange={() =>
                    handleFormChanger({
                      name: "food_ordered",
                      value: true,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              sx={{
                margin: 0,
              }}
              label="Нет"
              labelPlacement="end" // Помещает метку слева от чекбокса
              control={
                <CustomCheckbox
                  checked={formData.food_ordered === false}
                  onChange={() =>
                    handleFormChanger({
                      name: "food_ordered",
                      value: false,
                    })
                  }
                />
              }
            />
          </div>
        </div>
        <div className="flex gap-8">
          <CustomButton
            styles={
              "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
            }
            type={"submit"}
          >
            {hasEditSegment
              ? "Подтвердить изменения"
              : "Подтвердить мероприятие"}
          </CustomButton>
          <CustomButton
            onClick={() => navigate("../")}
            styles={
              "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
            }
            type={"button"}
          >
            {"Отменить"}
          </CustomButton>
        </div>
      </form>
    </>
  );
};

export default EventsOrganizationForm;
