import React from "react";
import {
  parentsGreenIcon,
  phoneGreenIcon,
  emailGreenIcon,
  calendarGreenIcon,
  placeGreenIcon,
} from "../../images";
import { XMarkIcon } from "@heroicons/react/20/solid";

const topFields = [
  { title: "ФИО:", icon: null },
  { title: "Дата рождения:", icon: calendarGreenIcon },
  { title: "Телефон:", icon: phoneGreenIcon },
  { title: "Почта:", icon: emailGreenIcon },
  { title: "Адрес:", icon: placeGreenIcon },
];

export const ParentsBackdrop = ({ setIsOpen }) => {
  return (
    <div className=" fixed top-0 left-0 bg-white bg-opacity-90 w-screen h-screen flex justify-center items-center">
      <div className=" bg-white shadow-md p-6 relative">
        <div
          className=" absolute top-5 right-5 cursor-pointer "
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <XMarkIcon className=" w-6 h-6 " />
        </div>
        <div className="icon flex justify-start items-center rounded-full gap-2 w-full">
          <img className="w-6 h-6" src={parentsGreenIcon} alt="" />
          <h2 className=" text-[18px] leading-[27px] text-text-primary">
            Родители
          </h2>
        </div>

        <div className=" flex flex-col md:flex-row justify-center  w-full gap-4">
          <div className=" w-full p-4">
            <h3 className=" text-text-gray text-[14px] leading-[21px]">
              Мама:
            </h3>
            <div className="top-info flex flex-col  justify-stretch flex-wrap pb-4 w-full">
              {topFields.map(({ title, icon }) => (
                <div
                  key={title}
                  className="flex gap-4 items-center mt-2 w-full "
                >
                  {icon && (
                    <div className="icon flex justify-center items-center rounded-full w-6 h-6">
                      <img className="w-6 h-6" src={icon} alt="" />
                    </div>
                  )}
                  <div className="top-info__content flex flex-row">
                    {/* <span className="text-gray-400">{title}</span> */}
                    <span className=" ml-2 text-[16px] leading-7 font-normal text-text-primary">
                      Test
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className=" text-text-gray text-[14px] leading-[21px]">
              <span>
                Согласии на обработку персональных данных родителя получено:
              </span>
              <span className=" ml-2 text-text-primary">да</span>
            </div>
          </div>
          <div className=" w-full p-4">
            <h3 className=" text-text-gray text-[14px] leading-[21px]">
              Папа:
            </h3>
            <div className="top-info flex flex-col  justify-stretch flex-wrap pb-4 w-full">
              {topFields.map(({ title, icon }) => (
                <div
                  key={title}
                  className="flex gap-4 items-center mt-2 w-full "
                >
                  {icon && (
                    <div className="icon flex justify-center items-center rounded-full w-6 h-6">
                      <img className="w-6 h-6" src={icon} alt="" />
                    </div>
                  )}
                  <div className="top-info__content flex flex-row">
                    {/* <span className="text-gray-400">{title}</span> */}
                    <span className=" ml-2 text-[16px] leading-7 font-normal text-text-primary">
                      Test
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className=" text-text-gray text-[14px] leading-[21px]">
              <span>
                Согласии на обработку персональных данных родителя получено:
              </span>
              <span className=" ml-2 text-text-primary">да</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
