import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../components/ui";
import { paginate } from "../../../utils/paginate";
import { useOutletContext } from "react-router-dom";
import { useGetRoutesContentItemsQuery } from "../../../store/RTKApi/routes";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { MultimediaCrop } from "../../../components";
export const Media = () => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Возможные опции
    });

    // Очистка при размонтировании компонента
    return () => Fancybox.destroy();
  }, []);

  const pageSize = 16;

  const { id } = useOutletContext();

  const onClick = () => {
    setPage((prev) => prev + 1);
  };

  const { data: medias, isLoading } = useGetRoutesContentItemsQuery({
    to_route: id,
  });

  const mediaCrop = paginate(medias, page, pageSize);

  return (
    <>
      {" "}
      <section className="min-h-[40vh]">
        {!isLoading &&
          (mediaCrop.length ? (
            // <div className=" grid grid-cols-2 md:grid-cols-4 justify-between  gap-4 items-stretch mb-5">
            //   {mediaCrop.map(({ id, media_item }, key, arr) => (
            //     <a
            //       data-fancybox
            //       data-src={media_item}
            //       // className=" !aspect-square block w-full h-full"
            //       onMouseEnter={() => handleEnter(key)}
            //       onMouseLeave={handleLeave}
            //       // onClick={() => openModal(data, page * size + key, type)}
            //       className="absolute inset-0 duration-300 hover:backdrop-blur-sm
            //     flex items-center justify-center  "
            //     >
            //       {key === hoveredIndex && (
            //         <img src={searchPlus} alt="key" className="w-20 invert" />
            //       )}
            //     </a>
            //   ))}
            // </div>
            <MultimediaCrop type={"img"} slide={mediaCrop} />
          ) : (
            <h2 className="text-2xl">Медиа контент отсутствует</h2>
          ))}

        {!isLoading && mediaCrop.length < medias.length && (
          <CustomButton
            onClick={onClick}
            label={"Показать еще"}
            styles={
              " w-[160px] mx-auto shadow-none border !text-text-gray !border-[#D9D9D9]"
            }
            textstyle={""}
          />
        )}
      </section>
    </>
  );
};
