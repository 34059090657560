import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import {
  organizationIcon,
  Location,
  programmIcon,
  codeEKISIcon,
  Author,
  MapWeb,
  directorIcon,
  advisorIcon,
  schoolPng,
} from "../../images";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/scss/pagination";
import { transformLocation } from "../../utils/transformField";
import { getSchoolInfo } from "../../store/schools/selectors";

const topFields = [
  { title: "Название организации:", path: "title", icon: organizationIcon },
  { title: "Код ЕКИС:", path: "ekis_code", icon: codeEKISIcon },
  { title: "Адрес:", path: "buildings", icon: Location },
  { title: "Реализуемые программы:", path: "programms", icon: programmIcon },
];

const bottomFields = [
  { title: "Руководитель:", path: "director", icon: directorIcon },
  { title: "Советник:", path: "adviser", icon: advisorIcon },
];

export const Organization = () => {
  const school = useSelector(getSchoolInfo());

  const medias = [schoolPng];

  return (
    <section className="account-organization">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-5/12 ">
          <Swiper
            slidesPerView={1}
            // freeMode={true}
            autoplay={{ delay: 5000 }}
            pagination={{
              clickable: true,
            }}
            effect={"fade"}
            loop={true}
            spaceBetween={10}
            centeredSlides={true}
            height={400}
            modules={[Pagination, EffectFade, Autoplay]}
          >
            {medias.map((el, index) => (
              <SwiperSlide key={index}>
                <div className="w-[350px] h-[350px] ">
                  <img
                    className="w-full h-full object-fill"
                    src={el}
                    alt="img"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="w-full md:w-7/12">
          <div className="top-info flex flex-col md:flex-row justify-stretch flex-wrap border-b-gray-400 border-b-2 border-dashed pb-4">
            {topFields.map(({ title, icon, path }) => (
              <div
                key={title}
                className="flex gap-4 items-center mt-5 w-full md:w-2/5"
              >
                <div className="icon flex justify-center items-center rounded-full min-w-[40px] min-h-[40px]  bg-text-primary-green">
                  <img className="w-6 h-6" src={icon} alt="" />
                </div>
                <div className="top-info__content flex flex-col">
                  <h3 className="text-gray-400">{title}</h3>
                  <p>
                    {path && school[path]
                      ? Array.isArray(school[path])
                        ? school[path].map((el) => (
                            <span className="block">{el}</span>
                          ))
                        : school[path]
                      : "Не назначено"}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="top-info flex flex-col md:flex-row pb-4">
            {bottomFields.map(({ title, icon, path }) => (
              <div
                key={title}
                className="flex gap-4 items-center mt-5 w-full md:w-2/5"
              >
                <div className="icon flex justify-center items-center rounded-full min-w-[40px] min-h-[40px]  bg-[#fdf0dcc4]">
                  <img className="w-6 h-6" src={icon} alt="" />
                </div>
                <div className="top-info__content flex flex-col">
                  <h3 className="text-gray-400">{title}</h3>
                  <p>
                    {path && school[path]
                      ? school[path].full_name
                      : "Не назначено"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
