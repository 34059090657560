import { wayReducer } from "./way";
import { authReducer } from "./auth";
import { filterReducer } from "./filter";
import { eventsReducer } from "./events";
import { notesReducer } from "./notes";
import {schoolsReducer} from './schools';
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { routesApi } from "./RTKApi/routes";
import { eventsApi } from "./RTKApi/events";
import { newsletterApi } from "./RTKApi/newsletter";
import { clubApi } from "./RTKApi/club";
import { notesApi } from "./RTKApi/notes";
import { schoolsApi } from "./RTKApi/schools";
import { authApi } from "./RTKApi/auth";
import { geocodeApi } from "./RTKApi/geocoder";
import cookieMiddleware from "./middleware/cookie";
import { outDoorEventsApi } from "./RTKApi/outdoorEvents";
import { contentApi } from "./RTKApi/medias";

const rootReducer = combineReducers({
  auth: authReducer,
  ways: wayReducer,
  filter: filterReducer,
  notes: notesReducer,
  event: eventsReducer,
  schools: schoolsReducer,
  [routesApi.reducerPath]: routesApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [newsletterApi.reducerPath]: newsletterApi.reducer,
  [clubApi.reducerPath]: clubApi.reducer,
  [notesApi.reducerPath]: notesApi.reducer,
  [schoolsApi.reducerPath]: schoolsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [geocodeApi.reducerPath]: geocodeApi.reducer,
  [outDoorEventsApi.reducerPath]: outDoorEventsApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
});

export function createStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        routesApi.middleware,
        eventsApi.middleware,
        newsletterApi.middleware,
        clubApi.middleware,
        notesApi.middleware,
        schoolsApi.middleware,
        authApi.middleware,
        geocodeApi.middleware,
        outDoorEventsApi.middleware,
        contentApi.middleware,
        cookieMiddleware
      ),
  });
}
